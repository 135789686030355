import React from 'react';
import PropTypes from 'prop-types';
import FormControl from "@material-ui/core/FormControl";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";

const stringUtils = require("underscore.string");

const INITIAL_VALUE = '';

const style = {
  marginLeft: '80px',
  width: '250px'
}
class GroupSwitcher extends React.Component {

    constructor(props) {
        super(props);
        this._handleChange = this._handleChange.bind(this);
        this.state = {
          selectedValue: this.props.selectedItem || INITIAL_VALUE,
          items: this.props.items || [],
          error: this.props.error,
          drawerOpen: this.props.drawerOpen || false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.error !== nextProps.error) {
          this.setState({ error: nextProps.error });
        }
        if (this.props.items !== nextProps.items) {
          this.setState({ items: nextProps.items });
        }
        if (this.props.selectedItem !== nextProps.selectedItem) {
          this.setState({ selectedValue: nextProps.selectedItem });
        }
        if (this.props.drawerOpen !== nextProps.drawerOpen) {
          this.setState({ drawerOpen: nextProps.drawerOpen });
        }
    }

    _handleChange(e, value) {
        const selectedValue = value._id;
        this.setState({
          selectedValue
        });
        if (this.props.onChange) {
          this.props.onChange(selectedValue);
        }
    }

    _getInitialGroupValue() {
      let result = {name: ''};
      if (!stringUtils.isBlank(this.props.selectedItem) && this.state.items.length > 0) {
        result = this.state.items.find((opt) => {
          return opt._id === this.props.selectedItem
        });
      }
      return result;
    }

    render() {
        return (
          <FormControl style={this.state.drawerOpen ? {width: '250px'} : style }>

            <Autocomplete
            {...{
              options: this.props.items.length > 0 ? this.props.items : [],
              getOptionLabel: (option) => option.name,
            }}
            id="group-switcher"
            blurOnSelect
            defaultValue={ this.props.selectedItem || INITIAL_VALUE }
            onChange={ this._handleChange }
            name="groupSwitcher"
            renderInput={(params) => <TextField {...params} placeholder="Select a Group" error={this.props.error} />}
            value={this._getInitialGroupValue()}
          />

          </FormControl>
        );
      }
}

GroupSwitcher.propTypes = {
    items: PropTypes.array.isRequired,
    selectedItem: PropTypes.string,
    drawerOpen: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func
  };

export default GroupSwitcher;