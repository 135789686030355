import React from "react";
import _ from 'lodash';
import '../../styles/rides-list-action-bar.scss';
import {RIDE_STATUS, RIDE_DATE_VALUES, ALL, TODAY, CUSTOM_DATE} from '../../constants/Constants';
import PropTypes from 'prop-types';
import { SEARCH_ICON } from '../../icons/rides-list-action-bar/svg';
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";

import {
  getRidesListDateRange,
  setRidesListDateRange,
  setRidesListBeginTime,
  setRidesListEndTime,
  getRidesListBeginTime,
  getRidesListEndTime,
  removeRidesListBeginTime,
  removeRidesListEndTime
} from "../../utils/localStorageUtil";
import DateRangeForm from "../forms/DateRangeForm";
import Popup from "../modals/Popup";
import {combineDateAndTime, formatDateForCurrentUser} from "../../utils/dateUtil";
import Typography from "@material-ui/core/Typography";

class RidesListActionBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dateRange: getRidesListDateRange(TODAY),
      beginTime: getRidesListBeginTime(null),
      endTime: getRidesListEndTime(null),
      openCustomDateRangeDialog: false,
    };

    this.strings = {
      CUSTOM_DATE_DIALOG_TITLE: "Select custom date range"
    };

    this._onClick = this._onClick.bind(this);
    this._onEnter = this._onEnter.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this._onCustomDateRangeClose = this._onCustomDateRangeClose.bind(this);
    this._onCustomDateRangeSubmit = this._onCustomDateRangeSubmit.bind(this);
  }

  _onClick() {
    const value = document.getElementById('rides-list-typed-value').value ? document.getElementById('rides-list-typed-value').value : '';
    this.props.onSearchChange(value);
  }
  _onEnter(event) {
    if(event.keyCode === 13){
      const value = event.target.value ? event.target.value : '';
      this.props.onSearchChange(value);
    }
  }
  _onDateChange(event) {
    const dateRange = event.target.value;
    const newState = _.assign({}, this.state);

    newState.dateRange = dateRange;
    newState.openCustomDateRangeDialog = dateRange === CUSTOM_DATE;

    if (dateRange !== CUSTOM_DATE) {
      removeRidesListBeginTime();
      removeRidesListEndTime();

      newState.beginTime = null;
      newState.endTime = null;

      setRidesListDateRange(dateRange);
    }

    this.setState(newState);
    if (dateRange !== CUSTOM_DATE) {
      this.props.onDateChange(event);
    }
  }

  _onCustomDateRangeClose() {
    this.setState({ openCustomDateRangeDialog: false });
  }

  _onCustomDateRangeSubmit(data) {
    const payload = {
      dateRange: CUSTOM_DATE,
      beginTime: combineDateAndTime(data.beginDate, data.beginTime),
      endTime: combineDateAndTime(data.endDate, data.endTime)
    };


    this.setState({
      openCustomDateRangeDialog: false,
      beginTime: payload.beginTime,
      endTime: payload.endTime
    });

    setRidesListBeginTime(payload.beginTime);
    setRidesListEndTime(payload.beginTime);

    this.props.onDateChange(payload);
  }

  render() {
    let mainComponent;
    if (this.props.showStatusDropDown) {
      mainComponent = <div className="rides-list-action-bar-parent">
        <div className="rides-list-action-bar-parent-form w-clearfix w-form">
            <div className="rides-list-search-container w-clearfix">
              <input type="text" onKeyDown={this._onEnter} className="rides-list-typed-value w-input" name="rides-list-typed-value" placeholder="Search rides" id="rides-list-typed-value" />
                <IconButton color="primary" className="search-button" aria-label="search" component="span" onClick={ this._onClick }>
                  <SvgIcon className="search-icon">
                    <path d={ SEARCH_ICON } transform="translate(-324 -213) translate(312 213) translate(11) rotate(-45 13 13)"/>
                  </SvgIcon>
                </IconButton>
            </div>
            <div className="rides-list-status-container">
              <div className="w-dropdown-toggle rides-list-search-bar-dropdown-toggle-status">
                <select name="rides-list-status" id="rides-list-status" defaultValue={ ALL } onChange={ this.props.onStatusChange } style={{width:'100%'}}>
                  { RIDE_STATUS.map((e)=> {
                    return(<option key={ e } value={e}>{e}</option>)
                  })
                  }
                </select>
              </div>
            </div>
            <div className="rides-list-date-container">
              <div className="w-dropdown-toggle rides-list-search-bar-dropdown-toggle-date">
                <select name="rides-list-date" id="rides-list-date" defaultValue={ this.state.dateRange } onChange={ this._onDateChange }>
                  { RIDE_DATE_VALUES.map((e)=> {
                    return(<option key={ e } value={e} selected={e === this.state.dateRange}>{e}</option>)
                  })
                  }
                </select>
              </div>
            </div>
            {/*<button type="button" className="rides-list-submit-btn w-button" onClick={ this.props.onDownloadCSV }>Download CSV </button>*/}
        </div>
        <div>
          { (this.state.dateRange === CUSTOM_DATE && this.state.beginTime && this.state.endTime) &&
            <Typography display="inline" color="textSecondary">
            { `RIDES FROM ${formatDateForCurrentUser(this.state.endTime).toUpperCase()} TO ${formatDateForCurrentUser(this.state.beginTime).toUpperCase()}` }
            </Typography>
          }
        </div>
      </div>;
    }
    else {
      mainComponent = <div className="rides-list-action-bar-parent">
        <div className="rides-list-action-bar-parent-form w-clearfix w-form">
            <div className="rides-list-search-container w-clearfix">
              <input type="text" onKeyDown={this._onEnter} className="rides-list-typed-value w-input" name="rides-list-typed-value" placeholder="Search rides" id="rides-list-typed-value" />
              <IconButton color="primary" className="search-button" aria-label="search" component="span" onClick={ this._onClick }>
                <SvgIcon className="search-icon">
                  <path d={ SEARCH_ICON } transform="translate(-324 -213) translate(312 213) translate(11) rotate(-45 13 13)"/>
                </SvgIcon>
              </IconButton>
            </div>
            <div className="rides-list-date-container-alone">
              <div className="w-dropdown-toggle rides-list-search-bar-dropdown-toggle-date">
                <select name="rides-list-date" id="rides-list-date" defaultValue={ this.state.dateRange } onChange={ this._onDateChange }>
                  { RIDE_DATE_VALUES.map((e)=> {
                    return(<option key={ e } value={e} selected={e === this.state.dateRange}>{e}</option>)
                  })
                  }
                </select>
              </div>
            </div>
            {/*<button type="button" className="w-button rides-list-submit-btn" onClick={ this.props.onDownloadCSV }>Download CSV </button>*/}
        </div>
      </div>;
    }

    return(
      <div>
        { mainComponent }
        <Popup
          openDialog={this.state.openCustomDateRangeDialog}
          title={this.strings.CUSTOM_DATE_DIALOG_TITLE}
          onCloseProfile={this._onCustomDateRangeClose}
        >
          <DateRangeForm
            beginTime={this.state.beginTime}
            endTime={this.state.endTime}
            onClose={this._onCustomDateRangeClose}
            onSubmit={data => this._onCustomDateRangeSubmit(data)}
          >
          </DateRangeForm>
        </Popup>
      </div>);
  }
}

RidesListActionBar.propTypes = {
  showStatusDropDown: PropTypes.bool.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onDownloadCSV: PropTypes.func.isRequired
};

export default RidesListActionBar;
