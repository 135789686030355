import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {formatNumberInput} from "../../utils/rideUtil";

const useStyles = () => ({
  root: {
    flexGrow: 1,
  },
  button: {
    borderRadius: 0,
    textTransform: 'none'
  }
});

const expMonths = [{"value":"01","label":"01"},{"value":"02","label":"02"},{"value":"03","label":"03"},{"value":"04","label":"04"},{"value":"05","label":"05"},{"value":"06","label":"06"},{"value":"07","label":"07"},{"value":"08","label":"08"},{"value":"09","label":"09"},{"value":"10","label":"10"},{"value":"11","label":"11"},{"value":"12","label":"12"}];

const expYears = [{"value":"2020","label":"2020"},{"value":"2021","label":"2021"},{"value":"2022","label":"2022"},{"value":"2023","label":"2023"},{"value":"2024","label":"2024"},{"value":"2025","label":"2025"},{"value":"2026","label":"2026"},{"value":"2027","label":"2027"},{"value":"2028","label":"2028"},{"value":"2029","label":"2029"},{"value":"2030","label":"2030"}];

class CardDetailsForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cardholderName: '',
      cardNumber: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      formErrors: {},
      tempYearList: []
    };
    this._handleChange = this._handleChange.bind(this);
    this._onChange = this._onChange.bind(this);
  }
  
  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      if (this.props.errors !== newProps.errors) {
        this.setState({ formErrors: newProps.errors });
      }
    }
  }

  _handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    }, this._onChange);
  }
   
  dynamicYear(e) {
    const { value } = e.target;    
    const d = new Date();
    let month = d.getMonth();
    if (month >= Number(value)) {
      let y = expYears.filter(e =>{
        return e.value >= new Date().getFullYear()+1;
      })
      this.setState({tempYearList:y})
    } else {
      let y = expYears.filter(e =>{
        return e.value >= new Date().getFullYear();
      })
      this.setState({tempYearList:y})
    }
  }

  _onChange() {
    this.props.onChange(this.state.cardholderName, this.state.cardNumber, this.state.expMonth,this.state.expYear,this.state.cvc);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField id="cardholder-name"
                       name="cardholderName"
                       label="Cardholder's name"
                       value={ this.state.cardholderName }
                       onChange={ this._handleChange }
                       error={ !!this.state.formErrors.cardholderName }
                       helperText={ this.state.formErrors.cardholderName }
                       fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField id="card-number"
                       name="cardNumber"
                       label="Card number"
                       value={ this.state.cardNumber }
                       type="number"
                       onKeyDown={ formatNumberInput }
                       onChange={ this._handleChange }
                       error={ !!this.state.formErrors.cardNumber }
                       helperText={ this.state.formErrors.cardNumber }
                       fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField id="exp-month"
                       name="expMonth"
                       select
                       label="Expiration month"
                       value={ this.state.expMonth }
                       onChange={e => { this.dynamicYear(e); this._handleChange(e) } }
                       error={ !!this.state.formErrors.expMonth }
                       helperText={ this.state.formErrors.expMonth }
                       fullWidth>
              {expMonths.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField id="exp-year"
                       name="expYear"
                       select
                       label="Expiration year"
                       value={ this.state.expYear }
                       onChange={ this._handleChange }
                       error={ !!this.state.formErrors.expYear }
                       helperText={ this.state.formErrors.expYear }
                       fullWidth>
              {this.state.tempYearList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField id="cvc"
                       name="cvc"
                       type="password"
                       label="CVC"
                       onKeyDown={ formatNumberInput }
                       value={ this.state.cvc }
                       onChange={ this._handleChange }
                       error={ !!this.state.formErrors.cvc }
                       helperText={ this.state.formErrors.cvc }
                       fullWidth />
          </Grid>
        </Grid>
      </div>
    );
  }
}

CardDetailsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  classes: PropTypes.object
};

export default withStyles(useStyles)(CardDetailsForm);
