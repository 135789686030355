import React from 'react';
import '../../styles/rides-list.scss';
import RidesListItem from "./RidesListItem";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  BOOKED,
  COMPLETED,
  CANCELLED,
  NO_RIDES_FOUND,
  IN_PROGRESS,
  NOTIFICATION_TYPE_ERROR,
  DEFAULT_AUTO_DISMISS_DURATION
} from "../../constants/Constants";
import Container from "@material-ui/core/Container";
import {KaptynDataService} from "../../dataservices/KaptynDataService";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class RidesList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rides: this.props.rides,
      groupRides: this.props.groupRides,
      booked:[],
      completed:[],
      cancelled:[],
      inProgress:[],
      deleteConfirmOpen: false,
      rideIdToCancel: null,
      reservationNumber: null,
      tenantGroupId: this.props.tenantGroupId
    };
    this._groupRides = this._groupRides.bind(this);
    this.onCancelRide = this.onCancelRide.bind(this);
    this._renderConfirmDialog = this._renderConfirmDialog.bind(this);
    this._handleDeleteCancelClick = this._handleDeleteCancelClick.bind(this);
    this._handleDeleteConfirmClick = this._handleDeleteConfirmClick.bind(this);
  }

  componentDidMount() {
    this._groupRides();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({ rides: nextProps.rides, groupRides: nextProps.groupRides, tenantGroupId: nextProps.tenantGroupId  }, this._groupRides);
    }
  }

  _groupRides() {
    if (this.state.rides.length > 0 && this.state.groupRides) {
        const booked = [];
        const completed = [];
        const cancelled = [];
        const inProgress = [];
        for (let i = 0; i < this.state.rides.length; i++) {
          const ride = this.state.rides[i];
          switch(ride.rideStatus) {
            case 'scheduled':
              booked.push(ride);
              break;
            case 'completed':
              completed.push(ride);
              break;
            case 'canceled':
              cancelled.push(ride);
              break;
            case 'in_progress':
            case 'arrived':
            case 'en_route':
              inProgress.push(ride);
              break;
            default:
              // Intentionally do nothing.
              break;
          }
        }
        this.setState({ booked, completed, cancelled, inProgress });
    }
  }

  _handleDeleteCancelClick() {
    this.setState({deleteConfirmOpen:false});
  }

  _handleDeleteConfirmClick() {
    if (this.state.rideIdToCancel) {
      this.props.setIsLoading({ isLoading: true });
      const dataService = new KaptynDataService();
      // eslint-disable-next-line no-unused-vars
      dataService.cancelRide(this.state.rideIdToCancel, this.state.tenantGroupId).then((response)=>{
        this.props.setIsLoading({ isLoading: false });
        this.props.onUpdateRides();
        this.setState({deleteConfirmOpen:false});
      }).catch((err)=>{
        this.props.setIsLoading({ isLoading: false });
        const globalNotification = {
          open: true,
          type: NOTIFICATION_TYPE_ERROR,
          content: err.message,
          autoDismissDuration: DEFAULT_AUTO_DISMISS_DURATION
        };
        this.props.setGlobalNotification({ globalNotification });
      });
    }
  }

  _renderConfirmDialog() {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={ this.state.deleteConfirmOpen }
      >
        <DialogTitle id="confirmation-dialog-title">Cancel Ride</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to cancel the ride { this.state.reservationNumber }?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={ this._handleDeleteCancelClick } variant="outlined" className="rides-list-canceled-popup-button">
            Cancel
          </Button>
          <Button onClick={ this._handleDeleteConfirmClick } variant="contained" color="secondary" className="rides-list-canceled-popup-button" disableElevation>
            Yes, cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  onCancelRide(rideIdToCancel, reservationNumber) {
    this.setState({deleteConfirmOpen:true, rideIdToCancel, reservationNumber});
  }

  renderRiesListItem(e) {
    return (
      <RidesListItem reservationNumber={ e.reservationNumber }
                     passengerName={ e.passengerName }
                     passengers={e.passengers}
                     passengerPhone={ e.passengerPhone }
                     driverName={ e.driverName }
                     driverPhone={ e.driverPhone }
                     rideId={ e.rideId }
                     serviceLevelName={ e.serviceLevelName }
                     vehicleColor={ e.vehicleColor }
                     vehicleMake={ e.vehicleMake }
                     vehicleModel={ e.vehicleModel }
                     vehicleNumber={ e.vehicleNumber }
                     rideStatus={ e.rideStatus }
                     fromAddress={ e.fromAddress }
                     toAddress={ e.toAddress }
                     scheduledTime={ e.scheduledTime }
                     onCancelRide={ this.onCancelRide  }
                     transactionStatus={ e.transaction_status  }
                     transactionBalance={ e.balance  }
                     history={ this.props.history } />
    );
  }

  render() {
    if (this.state.rides && this.state.rides.length === 0) {
    return(
      <Grid container className="rides-list-no-padding-and-margin">
        <Grid item xs={12}>
            <Typography className="rides-list-no-rides-found-message" variant="h4" align="center" color="textSecondary">{ NO_RIDES_FOUND }</Typography>
        </Grid>
      </Grid>
      )
    }
    else if (this.state.rides && this.state.rides.length > 0 && this.state.groupRides) {
      return(<div className="root list-scroll">
        <Grid container className="rides-list-no-padding-and-margin">
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" className="rides-list-label">
              { this.state.booked.length > 0 ? BOOKED : '' }
            </Typography>
          </Grid>
          {
            this.state.booked.length > 0 ? this.state.booked.map((e) => {
              return (
                <Container maxWidth="xl" key={ e.rideId } className="rides-list-no-padding-and-margin">
                  <Grid item xs={12} className="rides-list-item">
                    { this.renderRiesListItem(e) }
                  </Grid>
                </Container>)}) : ''
          }
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" className="rides-list-label">
              { this.state.inProgress.length > 0 ? IN_PROGRESS : '' }
            </Typography>
          </Grid>
          {
            this.state.inProgress.length > 0 ? this.state.inProgress.map((e) => {
              return (
                <Container maxWidth="xl" key={ e.rideId } className="rides-list-no-padding-and-margin">
                  <Grid item xs={12} className="rides-list-item">
                    { this.renderRiesListItem(e) }
                  </Grid>
                </Container>)}) : ''
          }
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" className="rides-list-label">
              { this.state.completed.length > 0 ? COMPLETED : '' }
            </Typography>
          </Grid>
          {
            this.state.completed.length > 0 ? this.state.completed.map((e) => {
              return (
                <Container maxWidth="xl" key={ e.rideId } className="rides-list-no-padding-and-margin">
                  <Grid item xs={12} className="rides-list-item">
                    { this.renderRiesListItem(e) }
                  </Grid>
                </Container>)}):''
          }
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" className="rides-list-label">
              { this.state.cancelled.length > 0 ? CANCELLED : '' }
            </Typography>
          </Grid>
          {
            this.state.cancelled && this.state.cancelled.length > 0 ? this.state.cancelled.map((e) => {
              return (
                <Container maxWidth="xl" key={ e.rideId } className="rides-list-no-padding-and-margin">
                  <Grid item xs={12} className="rides-list-item">
                    { this.renderRiesListItem(e) }
                  </Grid>
                </Container>)}):''
          }
        </Grid>
        { this._renderConfirmDialog() }
      </div>);
    } else if (this.state.rides && this.state.rides.length > 0 && !this.state.groupRides){
      return(<div className="root list-scroll">
        <Grid container className="rides-list-no-padding-and-margin">
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" className="rides-list-date">
              { this.props.date ? this.props.date : '' }
            </Typography>
          </Grid>
          {
            this.state.rides && this.state.rides.length > 0 ? this.state.rides.map((e) => {
              return (
                <Container maxWidth="xl" key={ e.rideId } className="rides-list-no-padding-and-margin">
                  <Grid item xs={12} className="rides-list-item">
                    { this.renderRiesListItem(e) }
                  </Grid>
                </Container>)}) : ''
          }
        </Grid>
        { this._renderConfirmDialog() }
      </div>);
    }
  }
}

RidesList.propTypes = {
  history: PropTypes.object,
  rides: PropTypes.array.isRequired,
  groupRides: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  onUpdateRides: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setGlobalNotification: PropTypes.func.isRequired,
  tenantGroupId: PropTypes.string
};

export default RidesList;
