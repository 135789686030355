import Grid from "@material-ui/core/Grid";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import {
  getAirportDateAndTimeFormat,
  getDayOfMonth,
  getFlightDateFormatted,
  getMonthOfYear,
  getYear
} from "../../utils/dateUtil";
import {firstLetterToUpperCase} from "../../utils/stringUtil";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LoopIcon from "@material-ui/icons/Loop";
import * as constants from "../../constants/Constants";
import {KaptynDataService} from "../../dataservices/KaptynDataService";
import PropTypes from "prop-types";
const stringUtils = require("underscore.string");

class AirportDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flight: {
        airline: props.airline ? props.airline : '',
        number: props.flightNumber ? props.flightNumber : '',
        carrier: props.carrier ? props.carrier : '',
        day: '',
        direction: 'arr',
        month: null,
        year: null,
        flightDate:'',
        departureAirport:'',
        arrivalAirport:'',
        status: null,
        statusColor: constants.PRIMARY_WHITE,
        departureTime: '',
        departureDelay:'',
        arrivalTime:'',
        arrivalDelay:'',
        show: false,
      },
      updatedTime:'',
      airlinesList: [{name:'Loading...'}],
      rideDate: this.props.rideDate,
      formHasErrors: false,
      formErrors: {},
    };
    this.dataService = new KaptynDataService();
    this._getInitialAirlineValue = this._getInitialAirlineValue.bind(this);
    this._handleAirlineChange = this._handleAirlineChange.bind(this);
    this._handleFlightNumberChange = this._handleFlightNumberChange.bind(this);
    this._handleSearchFlightClick = this._handleSearchFlightClick.bind(this);
    this._handleFlightDataChange = this._handleFlightDataChange.bind(this);
    this._renderFlightFound = this._renderFlightFound.bind(this);
    this._validateForm = this._validateForm.bind(this);
  }

  componentDidMount() {
    this.props.setIsLoading({ isLoading: true });
    this.dataService.getAirlines().then((result) => {
      const airlinesList = result.sort((a, b) => a.name.localeCompare(b.name));
      this.setState({airlinesList});
      this.props.setIsLoading({ isLoading: false });
    }).catch((err)=> {
      this.props.setIsLoading({ isLoading: false });
      this.props.onError(err.message ? err.message : err.toString());
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      const newState = {};
      if (this.props.errors !== newProps.errors) {
        newState.formErrors = newProps.errors;
        this.setState({ formErrors: newProps.errors });
      }
      if (this.props.rideDate !== newProps.rideDate) {
        newState.rideDate = newProps.rideDate;
        this.setState({rideDate: newProps.rideDate});
      }
    }
  }

  _getInitialAirlineValue() {
    let result = {name: ''};
    if (!stringUtils.isBlank(this.props.carrier) && this.state.airlinesList.length > 1) {
      result = this.state.airlinesList.find((opt) => {
        return opt.iata === this.props.carrier || opt.icao === this.props.carrier
      });
    }
    return result;
  }

  _handleAirlineChange(e, value) {
    let airline = '';
    let carrier = '';
    if (value) {
      airline = value ? value.name : '';
      carrier = value ? (value.iata || value.icao) : '';
    }
    this.setState({
      flight: {
        ...this.state.flight,
        airline,
        carrier,
        status: null
      }
    }, this._handleFlightDataChange);
  }

  _handleFlightNumberChange(e) {
    const { value } = e.target;
    return this.setState({
      flight: {
        ...this.state.flight,
        status: null,
        number: value
      }
    }, this._handleFlightDataChange);
  }

  _handleSearchFlightClick() {
    if (!this.state.formHasErrors) {
      this.props.setIsLoading({ isLoading: true });
      const flight = {
        carrier: this.state.flight.carrier,
        day: getDayOfMonth(this.state.rideDate),
        direction: this.state.flight.direction,
        flight: this.state.flight.number,
        month: getMonthOfYear(this.state.rideDate),
        year: getYear(this.state.rideDate)
      };

      this.dataService.getFlightStatus(flight).then((response) => {
        this.setState({
          flight:{
            ...this.state.flight,
            flightDate: getFlightDateFormatted(this.state.rideDate),
            departureAirport: response.departureAirport ? response.departureAirport : undefined,
            arrivalAirport: response.arrivalAirport ? response.arrivalAirport :  undefined,
            status: response.status ? firstLetterToUpperCase(response.status) : undefined,
            statusColor: (response.status) ? (response.status === constants.FLIGHT_STATUS_CANCELED ? constants.PRIMARY_RED : response.status === constants.FLIGHT_STATUS_SCHEDULED ? constants.PRIMARY_GREEN : response.status === constants.FLIGHT_STATUS_DELAYED ? constants.PRIMARY_ORANGE : response.status === constants.FLIGHT_STATUS_REROUTED ? constants.PRIMARY_RED : response.status === constants.FLIGHT_STATUS_ERROR ? constants.PRIMARY_RED : response.status === constants.FLIGHT_STATUS_LANDED ? constants.PRIMARY_GREEN : constants.PRIMARY_WHITE) : constants.PRIMARY_WHITE,
            departureTime: response.departureTime ? getAirportDateAndTimeFormat(response.departureTime) : undefined,
            departureDelay: response.departureDelay ? response.departureDelay : undefined,
            arrivalTime: response.arrivalTime ? getAirportDateAndTimeFormat(response.arrivalTime) : undefined,
            arrivalDelay: response.arrivalDelay ? response.arrivalDelay :  undefined,
            show: true
          },
          updatedTime: getAirportDateAndTimeFormat()
        }, this._handleFlightDataChange);
        this.props.setIsLoading({ isLoading: false })
      }).catch((err)=> {
        this.props.setIsLoading({ isLoading: false });
        this.props.onError(err.message ? err.message : err.toString());
      })
    }
  }

  _handleFlightDataChange() {
    if (this.state.flight.status === 'Error') {
      const formErrors = {
        ...this.state.formErrors,
        flightNumber: constants.FORM_ERROR_FLIGHT_NOT_FOUND
      };
      this.setState({formErrors});
    }
    this.props.onChange({
      flightNumber: this.state.flight.number,
      carrier: this.state.flight.carrier,
      airline: this.state.flight.airline,
      flightStatus: this.state.flight.status,
      departureAirport: this.state.flight.departureAirport,
      arrivalAirport: this.state.flight.arrivalAirport,
    });
  }

  _renderFlightFound() {
    return (<Grid container>
      <Grid item xs={12}>
        <Card style={{borderRadius:0, border: "none", boxShadow: "none"}}>
          <CardContent>
            <Typography color="textSecondary">
              Updated: {this.state.updatedTime} <label htmlFor="icon-button-file">
              <IconButton color="default" onClick={this._validateForm} style={{color:constants.PRIMARY_BLACK}} aria-label="upload picture" component="span">
                <LoopIcon />
              </IconButton>
            </label>
            </Typography>
            <Typography color="textSecondary">
              {this.state.flight.flightDate}
            </Typography>
            <Typography>
              {this.state.flight.departureAirport} to {this.state.flight.arrivalAirport} ({this.state.flight.status})
            </Typography>
            <Typography color="textSecondary">
              Departure: {this.state.flight.departureTime}<br/>
              Arrival: {this.state.flight.arrivalTime}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>);
  }

  _validateForm() {
    let formHasErrors = false;
    let formErrors = {};

    if (stringUtils.isBlank(this.state.flight.number)) {
      formHasErrors = true;
      formErrors.flightNumber = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    if (stringUtils.isBlank(this.state.flight.carrier)) {
      formHasErrors = true;
      formErrors.airline = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    this.setState({ formHasErrors, formErrors }, this._handleSearchFlightClick);
  }

  render() {
    let flightInfoGrid = null;
    if (!stringUtils.isBlank(this.state.flight.status) && this.state.flight.status !== 'Error') {
      flightInfoGrid = this._renderFlightFound();
    } /*else if (this.state.flight.status === 'Error') {
      flightInfoGrid = this._renderFlightNotFound();
    }*/

    return(<div>
      <Grid container direction='row' alignItems="stretch" spacing={3}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            {...{
              options: this.state.airlinesList.length > 0 ? this.state.airlinesList : [],
              getOptionLabel: (option) => option.name,
            }}
            id="airline-selector"
            blurOnSelect
            onChange={ this._handleAirlineChange }
            name="airline"
            renderInput={(params) => <TextField {...params} label="Airline" placeholder="Choose airline" error={!!this.state.formErrors.airline} helperText={this.state.formErrors.airline}/>}
            value={this._getInitialAirlineValue()}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField id="flight-number"
                     name="flightNumber"
                     type="number"
                     label="Flight Number"
                     value={ this.state.flight.number }
                     onChange={ this._handleFlightNumberChange }
                     error={!!this.state.formErrors.flightNumber}
                     helperText={this.state.formErrors.flightNumber}
                     fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <IconButton color="default" onClick={this._validateForm} title={'Find flight'} style={{color:'#000', backgroundColor:'#dedede'}} aria-label="upload picture" component="span">
            <SearchIcon />
          </IconButton>
        </Grid>
      </Grid>
      {flightInfoGrid}
    </div>)
  }
}

AirportDetails.propTypes = {
  rideDate: PropTypes.string.isRequired,
  airline: PropTypes.string,
  carrier: PropTypes.string,
  flightNumber: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setIsLoading: PropTypes.func.isRequired,
};

export default AirportDetails;
