import React from 'react'
import {Grid} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
const _ = require('lodash');

import "../../styles/date-range-form.scss";
import {
  getCurrentDateAndTime,
  getDayEnd,
  getDayStart,
  splitDateAndTime
} from "../../utils/dateUtil";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 4 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    spacing: 8,
  },
  inactive_tabStyle:{
    color: 'black',
    fontSize:11,
    backgroundColor: "#C0C0C0",
    textTransform: 'none',
  },
  active_tabStyle:{
    fontSize:11,
    color: 'white',
    backgroundColor: '#3d3d3d',
    textTransform: 'none',
  },
  inkBar: {
    backgroundColor: 'white',
    boxShadow: 'none',
    border: 'none',
    shadows: ["none"],
    elevation: 0
  },
  dense: {
    marginTop: 16,
  },
  button: {
    margin: 3,
    textTransform: 'none',
  },
  input: {
    display: 'none',
  },
});

class DateRangeForm extends React.Component {

  constructor(props) {
    super(props);
    this.initialDateAndTime = getCurrentDateAndTime();
    let beginDateAndTime = {};
    let endDateAndTime = {};

    if (!_.isNil(props.beginTime)) {
      beginDateAndTime = splitDateAndTime(props.beginTime);
    }

    if (!_.isNil(props.endTime)) {
      endDateAndTime = splitDateAndTime(props.endTime);
    }

    this.state = {
      dateDetails: {
        beginDate: beginDateAndTime.date || this.initialDateAndTime.date,
        endDate: endDateAndTime.date || this.initialDateAndTime.date,
        beginTime: beginDateAndTime.time || getDayStart(),
        endTime: endDateAndTime.time || getDayEnd(),
      },
      formErrors: {}
    };

    this.strings = {
      SUBMIT_BUTTON: 'Apply',
      CANCEL_BUTTON: 'Cancel',
      BEGIN_DATE_LABEL: 'From Date',
      BEGIN_TIME_LABEL: 'From Time',
      END_DATE_LABEL: 'To Date',
      END_TIME_LABEL: 'To Time'
    };

    this._handleDateRangeUpdateSubmit = this._handleDateRangeUpdateSubmit.bind(this);
    this._handleDateRangeChange = this._handleDateRangeChange.bind(this);
  }

  componentDidMount() {

  }

  _handleDateRangeChange(event) {
    const { name, value } = event.target;
    const currentDateDetails = this.state.dateDetails;
    const dateDetails = {
      ...currentDateDetails,
      [name]: value
    };
    this.setState({
      dateDetails
    });
  }

  _handleDateRangeUpdateSubmit() {
    if (!this.state.formHasErrors) {
     this.props.onSubmit({
       beginDate: this.state.dateDetails.beginDate,
       beginTime: this.state.dateDetails.beginTime,
       endDate: this.state.dateDetails.endDate,
       endTime: this.state.dateDetails.endTime
     });
    }
  }

  _renderForm() {
    return (
      <div>
        <TabContainer>
          <FormControl onSubmit={this._handleDateRangeUpdateSubmit}>
            <Grid container spacing={5}>
              <Grid item xs={12} lg={6}>
                <TextField
                  id="beginDate"
                  name="beginDate"
                  type="date"
                  label={ this.strings.BEGIN_DATE_LABEL }
                  value={ this.state.dateDetails.beginDate }
                  onChange={ this._handleDateRangeChange }
                  error={ !!this.state.formErrors.firstName }
                  helperText={ this.state.formErrors.firstName }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField id="beginTime"
                           name="beginTime"
                           type="time"
                           label={this.strings.BEGIN_TIME_LABEL}
                           value={ this.state.dateDetails.beginTime }
                           onChange={ this._handleDateRangeChange }
                           error={ !!this.state.formErrors.countryCode }
                           helperText={ this.state.formErrors.countryCode }
                           fullWidth>
                </TextField>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  id="endDate"
                  name="endDate"
                  type="date"
                  label={ this.strings.END_DATE_LABEL }
                  value={ this.state.dateDetails.endDate }
                  onChange={ this._handleDateRangeChange }
                  error={ !!this.state.formErrors.endDate }
                  helperText={ this.state.formErrors.endDate }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField id="endTime"
                           name="endTime"
                           label= { this.strings.END_TIME_LABEL }
                           type="time"
                           value={ this.state.dateDetails.endTime }
                           onChange={ this._handleDateRangeChange }
                           error={ !!this.state.formErrors.endTime }
                           helperText={ this.state.formErrors.endTime }
                           fullWidth/>
              </Grid>
              <br/><br/><br/>
              <Grid item xs={12} lg={12}>
                <div>
                  <div>
                    <Button variant="outlined"
                            className="edit-user-modal-cancel-button"
                            size={"large"}
                            onClick={this.props.onClose}>
                      { this.strings.CANCEL_BUTTON }
                    </Button>
                    <Button variant="contained"
                            color="primary"
                            className="edit-user-modal-submit-button"
                            size={"large"}
                            type="submit"
                            onClick={this._handleDateRangeUpdateSubmit}>
                      { this.strings.SUBMIT_BUTTON }
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </FormControl>
        </TabContainer>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div>
          { this._renderForm() }
        </div>
      </div>
    )
  }
}

DateRangeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object,
  onClose: PropTypes.func,
  beginTime: PropTypes.object,
  endTime: PropTypes.object,
};

export default withStyles(useStyles)(DateRangeForm);
