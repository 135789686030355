import React from "react";
import PropTypes from "prop-types";
import ConciergeContainer from "../../containers/ConciergeContainer";
import { Button, Checkbox, FormControlLabel, Grid, Paper, Typography, Link } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as constants from "../../constants/Constants";
import { arePasswordsEqual, isGreaterOrEqualThanMinLength , countryCodes } from "../../utils/inputUtil";
const emailValidator = require("email-validator");
const AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter;
import { KaptynDataService } from "../../dataservices/KaptynDataService";
import {hasActiveSession} from "../../utils/cookieUtil";
import FormHelperText from "@material-ui/core/FormHelperText";
import '../../styles/signup-page.scss';
import Notification from "../lower-level/Notification";
import MenuItem from "@material-ui/core/MenuItem";
import ConciergeLogo from '../../images/logos/logo-kaptyn-concierge-2-x.png';

const stringUtils = require("underscore.string");

export class DisplaySignupPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName:'',
      lastName:'',
      phoneNumber:'',
      countryCode:'',
      email: '',
      password: '',
      confirmPassword:'',
      kaptynPolicy: false,
      isLoading: "determinate",
      formHasErrors: false,
      formErrors: {},
      notification: {
        open: this.props.globalNotification.open,
        type: this.props.globalNotification.type,
        content: this.props.globalNotification.content,
        autoDismissDuration: this.props.globalNotification.autoDismissDuration
      }
    };

    this._handleSignup = this._handleSignup.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleKaptynPolicyCheckboxChange = this._handleKaptynPolicyCheckboxChange.bind(this);
    this._handlePhoneChange = this._handlePhoneChange.bind(this);
    this._formatPassengerPhone = this._formatPassengerPhone.bind(this);
    this._handleSuccess = this._handleSuccess.bind(this);
    this._handleError = this._handleError.bind(this);
    this._redirectoToLogin = this._redirectoToLogin.bind(this);
  }

  componentDidMount() {
    if (hasActiveSession()) {
      this.props.history.push('/');
    }
  }

  _handleSignup() {
    if (!this.state.formHasErrors) {
      this.setState({ isLoading: "indeterminate" });
      const dataService = new KaptynDataService();

      // eslint-disable-next-line no-unused-vars
      dataService.signUp({ firstName: this.state.firstName, lastName: this.state.lastName, phone: this._formatPassengerPhone(), email: this.state.email, password: this.state.password }).then((results)=> {
        this._handleSuccess('Account succesfully created');
        this._redirectoToLogin();
      }).catch((err)=> {
        this.setState({ isLoading: "determinate" });
        this._handleError(err.message ? err.message : err.toString());
      });
    }
  }

  _handleError(errorMessage) {
    const globalNotification = {
      open: true,
      type: constants.NOTIFICATION_TYPE_ERROR,
      content: errorMessage,
      autoDismissDuration: constants.DEFAULT_AUTO_DISMISS_DURATION
    };
    this.props.setGlobalNotification({ globalNotification });
  }

  _handleSuccess(successMessage) {
    const globalNotification = {
      open: true,
      type: constants.NOTIFICATION_TYPE_SUCCESS,
      content: successMessage,
      autoDismissDuration: constants.DEFAULT_AUTO_DISMISS_DURATION
    };
    this.props.setGlobalNotification({ globalNotification });
  }

  _redirectoToLogin() {
    this.props.history.push('/login');
  }

  _validateForm() {
    let formHasErrors = false;
    let formErrors = {};
    if (stringUtils.isBlank(this.state.firstName)) {
      formHasErrors = true;
      formErrors.firstName = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    if (stringUtils.isBlank(this.state.lastName)) {
      formHasErrors = true;
      formErrors.lastName = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    if (stringUtils.isBlank(this.state.countryCode)) {
      formHasErrors = true;
      formErrors.countryCode = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    if (stringUtils.isBlank(this.state.phoneNumber)) {
      formHasErrors = true;
      formErrors.phoneNumber = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    if (stringUtils.isBlank(this.state.email)) {
      formHasErrors = true;
      formErrors.email = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    if (!stringUtils.isBlank(this.state.email) && !emailValidator.validate(this.state.email)) {
      formHasErrors = true;
      formErrors.email = constants.FORM_ERROR_INVALID_EMAIL;
    }

    if (stringUtils.isBlank(this.state.password)) {
      formHasErrors = true;
      formErrors.password = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    if (!stringUtils.isBlank(this.state.password) && !isGreaterOrEqualThanMinLength(this.state.password, 8)) {
      formHasErrors = true;
      formErrors.password = constants.FORM_ERROR_PASSWORD_MUST_BE_GREATER;
    }

    if (stringUtils.isBlank(this.state.confirmPassword)) {
      formHasErrors = true;
      formErrors.confirmPassword = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    if (!arePasswordsEqual(this.state.password, this.state.confirmPassword)) {
      formHasErrors = true;
      formErrors.confirmPassword = constants.FORM_ERROR_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH;
    }

    if (!this.state.kaptynPolicy) {
      formHasErrors = true;
      formErrors.kaptynPolicy = "You must accept the Privacy Policy";
    }

    this.setState({ formHasErrors, formErrors }, this._handleSignup);
  }

  _handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name] : value });
  }

  _handleKaptynPolicyCheckboxChange(event) {
    this.setState({ kaptynPolicy: event.target.checked });
  }

  _formatPassengerPhone() {
    const filteredCountryCodes = countryCodes.filter((cc) => { return cc.value === this.state.countryCode });
    const countryCode = filteredCountryCodes[0].label;
    const phoneNumber = this.state.phoneNumber.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
    return `${countryCode}${phoneNumber}`;
  }

  _handlePhoneChange(e) {
    const { name, value } = e.target;
    const formatter = new AsYouTypeFormatter(this.state.countryCode);
    let newValue = '';
    for (let i = 0; i < value.length; i++) {
      if (/\d+/.test(value.charAt(i))) {
        newValue = formatter.inputDigit(value.charAt(i));
      }
    }
    this.setState({ [name] : newValue });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.globalNotification !== nextProps.globalNotification) {
      const notification = {
        open: nextProps.globalNotification.open,
        type: nextProps.globalNotification.type,
        content: nextProps.globalNotification.content,
        autoDismissDuration: nextProps.globalNotification.autoDismissDuration
      };
      this.setState({ notification });
    }
  }

  render() {
    return(<div>
      <div className="concierge-logo">
        <img src={ ConciergeLogo } />
      </div>
      <Notification open={ this.state.notification.open }
                    type={ this.state.notification.type }
                    content={ this.state.notification.content }
                    autoDismissDuration={ this.state.notification.autoDismissDuration }
                    setGlobalNotification={ this.props.setGlobalNotification } />
      <Grid container spacing={0} className="signup-form">
        <Grid item xs={12} sm={12} className="signup-form-child-grid"
        >
          <Paper
            variant="elevation"
            elevation={1}
            className="login-background signup-form-background"
          >
            <Grid item xs={12} >
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <Typography display="inline" className="signup-form-already-have-account-text">Already have an account? </Typography>
                <Typography display="inline" className="signup-form-log-in-link" onClick={ this._redirectoToLogin }>Log in</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h1" variant="h4" className="signup-form-title">
                Create an account
              </Typography>
            </Grid>
            <Grid item>
              <form noValidate>
                <Grid container direction="row" spacing={1} className="signup-form-first-row">
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      fullWidth
                      value={this.state.firstName}
                      onChange={ this._handleChange }
                      error={ !!this.state.formErrors.firstName }
                      helperText={ this.state.formErrors.firstName }
                      className="signup-form-input"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      type="text"
                      placeholder="Last Name"
                      fullWidth
                      name="lastName"
                      value={this.state.lastName}
                      onChange={ this._handleChange }
                      error={ !!this.state.formErrors.lastName }
                      className="signup-form-input"
                      helperText={ this.state.formErrors.lastName }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField id="country-code"
                               name="countryCode"
                               select
                               label="Country Code"
                               value={ this.state.countryCode }
                               onChange={ this._handleChange }
                               error={ !!this.state.formErrors.countryCode }
                               helperText={ this.state.formErrors.countryCode }
                               className="signup-form-input signup-form-country-code"
                               fullWidth>
                      {countryCodes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      type="text"
                      placeholder="Phone number"
                      fullWidth
                      name="phoneNumber"
                      value={this.state.phoneNumber}
                      onChange={ this._handlePhoneChange }
                      error={ !!this.state.formErrors.phoneNumber }
                      className="signup-form-input"
                      helperText={ this.state.formErrors.phoneNumber }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      placeholder="Email"
                      fullWidth
                      name="email"
                      value={this.state.email}
                      onChange={ this._handleChange }
                      className="signup-form-input"
                      error={ !!this.state.formErrors.email }
                      helperText={ this.state.formErrors.email }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="password"
                      placeholder="Password"
                      fullWidth
                      name="password"
                      value={this.state.password}
                      onChange={ this._handleChange }
                      className="signup-form-input"
                      error={ !!this.state.formErrors.password }
                      helperText={ this.state.formErrors.password }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="password"
                      placeholder="Confirm Password"
                      fullWidth
                      name="confirmPassword"
                      value={this.state.confirmPassword}
                      onChange={ this._handleChange }
                      className="signup-form-input"
                      error={ !!this.state.formErrors.confirmPassword }
                      helperText={ this.state.formErrors.confirmPassword }
                    />
                  </Grid>
                  <Grid className="signup-form-extra-buttons">
                    <Grid item xs={12} sm={12}  style={{padding:0,margin:0}} className="signup-form-checkbox-container">
                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={ this._handleKaptynPolicyCheckboxChange }
                                            name="kaptynPolicy"
                                            color="primary"
                                            checked={this.state.rememberMe}
                                            className="signup-form-policy-checkbox"
                                          />
                                        }
                                        label=""
                      />
                      <div className="signup-form-policy-container"><span>I agree to Kaptyn&apos;s </span><span>
                        <Link href="https://www.kaptyn.com/" target="_blank" rel="noopener" className="signup-form-underlined-privacy">Privacy Policy</Link>.</span>
                      </div>
                      <FormHelperText className="signup-form-policy-checkbox-error-message" error>{ this.state.formErrors.kaptynPolicy ? this.state.formErrors.kaptynPolicy : ''  }</FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="text"
                      color="primary"
                      className="signup-form-submit-button"
                      component="button"
                      size="large"
                      onClick={ this._validateForm }
                    >
                      Create account<CircularProgress
                      variant={this.state.isLoading}
                      size={20}
                      thickness={3}
                      className="login-form-circular-progress"
                    />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <div className="version-number-container">
        <Typography component="div" className="version-number-text">Version {process.env.VERSION}</Typography>
      </div>
    </div>);
  }
}

DisplaySignupPage.propTypes = {
  history: PropTypes.object,
  globalNotification: PropTypes.object.isRequired,
  setGlobalNotification: PropTypes.func.isRequired
};

const SignUpPage = ConciergeContainer()(DisplaySignupPage);

export default SignUpPage;
