import React from 'react';
import {
  ADD_USER_BTN_ADD,
  ADD_USER_BTN_CANCEL,
  ADMINISTRATOR_ROLE_LABEL,
  ADMINISTRATOR_ROLE_VALUE,
  CONCIERGE_ROLE_LABEL,
  CONCIERGE_ROLE_VALUE,
  DEFAULT_AUTO_DISMISS_DURATION,
  FORM_ERROR_INVALID_EMAIL,
  FORM_ERROR_REQUIRED_FIELD,
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
  USER_EMAIL
} from "../../constants/Constants";
import "../../styles/add-user-modal.scss";
import * as stringUtils from "underscore.string";
import {KaptynDataService} from "../../dataservices/KaptynDataService";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";

const emailValidator = require("email-validator");

class AddUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      role:'',
      open: props.open,
      formHasErrors: false,
      formErrors: {},
      close: props.handleCloseAddUserModal
    };
    this._handleCloseAddUserModal = this._handleCloseAddUserModal.bind(this);
    this._handleOnChangeValue = this._handleOnChangeValue.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._handleAddUser = this._handleAddUser.bind(this);
    this._addUser = this._addUser.bind(this);
    this._resetForm = this._resetForm.bind(this);
  }

  _handleCloseAddUserModal() {
    this.state.close();
    this._resetForm();
  }

  _validateForm() {
    let formHasErrors = false;
    let formErrors = {};

    if (stringUtils.isBlank(this.state.email)) {
      formHasErrors = true;
      formErrors.email = FORM_ERROR_REQUIRED_FIELD;
    }
    if (!emailValidator.validate(this.state.email)) {
      formHasErrors = true;
      formErrors.email = FORM_ERROR_INVALID_EMAIL;
    }
    if (stringUtils.isBlank(this.state.role)) {
      formHasErrors = true;
      formErrors.role = FORM_ERROR_REQUIRED_FIELD;
    }
    this.setState({ formHasErrors, formErrors }, this._handleAddUser);
  }

  _handleOnChangeValue(e) {
    const { name, value } = e.target;
    this.setState({ [name] : value });
  }

  _addUser(params) {
    this.props.setIsLoading({ isLoading:true });
    const dataService = new KaptynDataService();

    dataService.addUserToWhiteList(params).then((response)=> {
      this.props.setIsLoading({ isLoading:false });
      const globalNotification = {
        open: true,
        type: NOTIFICATION_TYPE_SUCCESS,
        content: response.message,
        autoDismissDuration: DEFAULT_AUTO_DISMISS_DURATION
      };
      this.props.setGlobalNotification({ globalNotification });
      this._handleCloseAddUserModal();
      this._resetForm();
    }).catch((err)=> {
      this.props.setIsLoading({ isLoading:false });
      const globalNotification = {
        open: true,
        type: NOTIFICATION_TYPE_ERROR,
        content: err.message,
        autoDismissDuration: DEFAULT_AUTO_DISMISS_DURATION
      };
      this.props.setGlobalNotification({ globalNotification });
    });

  }

  render() {
    return(
      <div>
        <Dialog
          aria-labelledby="add-user-modal-title"
          aria-describedby="alert-dialog-description"
          open={ this.state.open }
          onClose={ this._handleCloseAddUserModal }
          id="add-user-popup"
          maxWidth="xs"
        >
          <DialogTitle className="add-user-modal-title" id="add-user-modal-title">{ ADD_USER_BTN_ADD }
            <IconButton aria-label="close" className="add-user-modal-close-button" onClick={ this._handleCloseAddUserModal }>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="add-user-modal-content">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField id="email"
                           name="email"
                           className="add-user-modal-email"
                           label={ USER_EMAIL }
                           value={ this.state.email }
                           onChange={ this._handleOnChangeValue }
                           error={ !!this.state.formErrors.email }
                           helperText={ this.state.formErrors.email }
                           fullWidth />
              </Grid>

              <Grid item xs={12}>
                <FormControl className="add-user-modal-role-form-control" error={ !!this.state.formErrors.role }>
                  <InputLabel id="add-user-modal-label">Role</InputLabel>
                  <Select
                    labelId="add-user-modal-label"
                    id="role"
                    name="role"
                    value={ this.state.role }
                    className="add-user-modal-role"
                    onChange={ this._handleOnChangeValue }
                  >
                    <MenuItem value={ ADMINISTRATOR_ROLE_VALUE }>{ ADMINISTRATOR_ROLE_LABEL }</MenuItem>
                    <MenuItem value={ CONCIERGE_ROLE_VALUE }>{ CONCIERGE_ROLE_LABEL }</MenuItem>
                  </Select>
                  <FormHelperText error>{ this.state.formErrors.role ? this.state.formErrors.role : ''  }</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className="add-user-modal-actions">
              <Button onClick={this._handleCloseAddUserModal} className="add-user-modal-cancel-button">
                { ADD_USER_BTN_CANCEL }
              </Button>
              <Button onClick={ this._validateForm } className="add-user-modal-submit-button">
                { ADD_USER_BTN_ADD }
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>);
  }

  _resetForm() {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role:'',
      open: false,
      formHasErrors: false,
      formErrors: {}
    })
  }

  _handleAddUser() {
    if (!this.state.formHasErrors) {
      this._addUser({
        email: this.state.email,
        companyId: this.props.companyId,
        role: this.state.role === '1'
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props !== nextProps) {
      this.setState({open:nextProps.open});
    }
  }
}

AddUserForm.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  setGlobalNotification: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseAddUserModal: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired
};

export default AddUserForm;
