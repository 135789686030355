import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import '../../styles/users-list-item.scss';
import {EMAIL, ROLE} from '../../constants/Constants';
import Container from "@material-ui/core/Container";
import EditUserForm from "../forms/EditUserForm";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
});

class UsersListItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open:false,
      selectedUser: {
        id: props.userId ? props.userId : '',
        firstName: props.userFirstName ? props.userFirstName : '',
        lastName: props.userLastName ? props.userLastName : '',
        email: props.userEmail ? props.userEmail : '',
        role: props.userRole === 'Administrator' ? '1' : props.userRole === 'Concierge' ? '2' : ''
      }
    }
    this._handleClick = this._handleClick.bind(this);
    this._handleOpenEditUserModal = this._handleOpenEditUserModal.bind(this);
    this._handleCloseEditUserModal = this._handleCloseEditUserModal.bind(this);
  }

  _handleOpenEditUserModal() {
    this.setState({ open:true });
  }

  _handleCloseEditUserModal() {
    this.setState({ open:false });
  }

  _handleClick() {
    this._handleOpenEditUserModal();
  }

  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth="xl" className="users-list-item-container">
        <div className={classes.root}>
          <Grid item xs={12} spacing={0} container direction="column">
            <Grid item xs={12} container align="left" alignItems="center" >
              <Grid item xs={12} sm={12} md={4} lg={4} align="left">
                  <Typography variant="subtitle1" gutterBottom className='users-list-item-name users-list-item-margin-input' >{ this.props.userFirstName + ' ' + this.props.userLastName }</Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={5} align="left">
                  <Typography gutterBottom variant="subtitle2" className="users-list-item-label users-list-item-margin-input">
                    { EMAIL }
                  </Typography>
                  <Typography className="users-list-item-email-address users-list-item-margin-input">
                    { this.props.userEmail }
                  </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={2} lg={2} align="left">
                  <Typography gutterBottom variant="subtitle2" className="users-list-item-label users-list-item-margin-input">
                    { ROLE }
                  </Typography>
                  <Typography gutterBottom variant="body2" className="users-list-item-role users-list-item-margin-input">
                    { this.props.userRole }
                  </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={1} align="right" className="users-list-item-edit-button-container">
                <Button xs={12} variant="outlined" onClick={ this._handleClick } className="users-list-item-edit-button">Edit</Button>
              </Grid>
            </Grid>
          </Grid>
          <EditUserForm companyId={ this.props.companyId } open={ this.state.open }  user={ this.state.selectedUser } handleCloseEditUserModal={ this._handleCloseEditUserModal } setIsLoading={ this.props.setIsLoading } setGlobalNotification={ this.props.setGlobalNotification } refreshUsers={ this.props.refreshUsers }/>
        </div>
      </Container>
    );
  }
}

UsersListItem.propTypes = {
  companyId: PropTypes.string.isRequired,
  classes: PropTypes.object,
  userId: PropTypes.string.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setGlobalNotification: PropTypes.func.isRequired,
  refreshUsers: PropTypes.func.isRequired
};

export default withStyles(useStyles)(UsersListItem);
