export function setRidesListDateRange(value) {
  setItem('ridesListDateRange', value);
}

export function setRidesListBeginTime(value) {
  setItem('ridesListBeginTime', value);
}

export function setRidesListEndTime(value) {
  setItem('ridesListEndTime', value);
}

export function getRidesListBeginTime(defaultValue) {
  return getItem('ridesListBeginTime', defaultValue);
}

export function getRidesListEndTime(defaultValue) {
  return getItem('ridesListEndTime', defaultValue);
}

export function removeRidesListEndTime() {
  removeItem('ridesListEndTime');
}

export function removeRidesListBeginTime() {
  removeItem('ridesListBeginTime');
}

export function getRidesListDateRange(defaultValue) {
  return getItem('ridesListDateRange', defaultValue);
}

function setItem(key, value) {
  localStorage.setItem(key, value);
}

function removeItem(key) {
  localStorage.removeItem(key);
}

function getItem(key, defaultValue) {
  let result = defaultValue;
  if ('undefined' !== typeof localStorage) {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      result = storedValue;
    }
  }
  return result;
}
