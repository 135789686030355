import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
    color: '#fff'
  },
});

class ProgressSpinner extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <Backdrop className={ classes.backdrop } open={ this.props.open }>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

ProgressSpinner.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object
};

export default withStyles(useStyles)(ProgressSpinner);
