import { AbstractDataService } from "./AbstractDataService";
import {combineDateAndTime, formatDate} from '../utils/dateUtil';
import { getToken } from "../utils/cookieUtil";
import endpoints from '../constants/EndpointsProvider';
import {REPORT_RIDE_SUMMARY, RIDE_PROGRESS_BAR_CANCELLED_STATUS} from "../constants/Constants";
import _ from 'lodash';
import {storeRegistry} from '../store/storeRegistry';
import {getServiceLevelExtraInput} from "../utils/serviceLevelUtil";
import {getDeviceInfo} from "../utils/deviceUtils";

export class KaptynDataService extends AbstractDataService {

  constructor() {
    super();
  }

  get apiRoot() {
    // TODO: add webpack.config for testing purposes.
    let apiRoot;
    if (process.env.KAPTYN_API_BASE_URL) {
      apiRoot = process.env.KAPTYN_API_BASE_URL;
    } else {
      apiRoot = 'http://test.api.local.evertransit.com:3000';
    }
    return apiRoot;
  }

  signUp(payload){
    const pathObj = {
      urlTemplate: endpoints.API.SIGNUP,
      queryParams: { web: 1 }
    };
    return this.post(pathObj, payload);
  }

  login(email, password) {
    const data = {
      email,
      password,
      device: getDeviceInfo()
    };

    const pathObj = {
      urlTemplate: endpoints.API.LOGIN,
      routeParams: null,
      queryParams: {web: 1},
    };

    return this.put(pathObj, data);
  }

  forgotPassword(email, redirectURL) {
    const data = {
      email,
      redirectURL,
    };

    const pathObj = {
      urlTemplate: endpoints.API.FORGOT_PASSWORD,
      routeParams: null,
      queryParams: {web: 1},
    };

    return this.put(pathObj, data);
  }

  /**
   *
   * @param payload {{
   *   token,
   *   refreshToken,
   *   groupId,
   * }}
   * @returns {Promise<* | void>}
   */
  refreshSession(payload) {
    const data = {
      group: payload.group,
      refreshToken: payload.refreshToken,
      token: payload.token
    };
    return this.post(endpoints.API.REFRESH_SESSION, data);
  }

  logout() {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.LOGOUT,
      queryParams: { web: 1 }
    };

    return this.put(pathObj, null, headers);
  }

  /**
   *
   * @param payload {{
   *   currentPassword,
   *   newPassword,
   *   newPasswordConfirm
   * }}
   * @param token
   * @returns {Promise<* | void>}
   */
  changePassword(payload, token) {
    const headers = {
      'Authorization': `Bearer ${ token }`
    };

    const data = {
      current_password: payload.currentPassword,
      new_password: payload.newPassword,
      groupId: payload.groupId
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.CHANGE_PASSWORD,
    };

    return this.put(pathObj, data, headers);
  }

  getServiceLevels(fromAddress, fromCoordinates, toAddress, toCoordinates, pickUpDate, pickUpTime, duration, airportEnabledOnly, numberOfPassengers, amountOfLuggage, market, selectedServiceLevel, isPickupDropoffDateTimeChange) {
    const headers = {
      'Authorization': `Bearer ${getToken()}`
    };

    const conciergeState = storeRegistry.getStore().getState().concierge;

    const queryParams = {};
    if (market) {
      queryParams.market = market;
    }

    const pathObj = {
      urlTemplate: endpoints.API.CORPORATE.SERVICE_LEVELS,
      routeParams: {
        accountId: conciergeState.conciergeGroup.corporateAccountId,
        groupId: this._getAffiliateGroupId(conciergeState.conciergeGroup.conciergeGroupId, conciergeState)
      },
      queryParams
    };

    return this.get(pathObj, null, headers).then((serviceLevels) => {
      // Filter by hourly.
      if (duration) {
        serviceLevels.list = serviceLevels.list.filter(function (serviceLevel) {
          return !!serviceLevel.pricing.hourlyQuote;
        });
      }
      // Filter by airport.
      if (airportEnabledOnly) {
        serviceLevels.list = serviceLevels.list.filter(function (serviceLevel) {
          const inputField = getServiceLevelExtraInput(serviceLevel, 5);
          return inputField && inputField.inputType === 5;
        });
      }
      // Filter by numberOfPassengers.
      if (numberOfPassengers) {
        serviceLevels.list = serviceLevels.list.filter(function (serviceLevel) {
          const inputField = getServiceLevelExtraInput(serviceLevel, 6);
          return inputField && inputField.maxValue >= (numberOfPassengers ? Number(numberOfPassengers) : 1);
        });
      }
      // Filter by amountOfLuggage.
      // if (amountOfLuggage && Number(amountOfLuggage) > 0) {
      //   serviceLevels = serviceLevels.list.filter(function (serviceLevel) {
      //         const inputField = getServiceLevelExtraInput(serviceLevel, 1, "Bags");
      //         return inputField && inputField.maxValue >= amountOfLuggage;
      //     });
      // }
      if (serviceLevels && serviceLevels.list && serviceLevels.list.length > 0) {
        const serviceLevel = _.first(serviceLevels.list);
        return this.getServiceLevelAvailability(serviceLevel._id, pickUpDate, pickUpTime, fromCoordinates, toCoordinates, duration, null, serviceLevel.group).then((result) => {
          const availabilityList = result.availability && result.availability.availableVehicleClasses ? result.availability.availableVehicleClasses : [];
          const promises = serviceLevels.list.map((serviceLevel) => {
            return this.getQuote(fromAddress, fromCoordinates, toAddress, toCoordinates, pickUpDate, pickUpTime, duration, numberOfPassengers, amountOfLuggage, serviceLevel._id, serviceLevel.group).then((quote) => {
              serviceLevel.rate = quote.total ? quote.total / 100 : null;
              serviceLevel.duration = quote.duration;
              if(!isPickupDropoffDateTimeChange) {
                return this.getVehicleAvailability(serviceLevel._id, pickUpDate, pickUpTime, fromCoordinates, toCoordinates, duration, quote._id, availabilityList, serviceLevel.group).then((result) => {
                  if (result.availableVehiclesPresent) {
                    return serviceLevel;
                  }
                }).catch(err => err);
              } else {
                if(serviceLevel._id === selectedServiceLevel) {
                  return serviceLevel;
                }
              }
            });
          });
          return Promise.all(promises).then(serviceLevels => serviceLevels.filter(serviceLevel => !_.isNil(serviceLevel)));
        }).catch(err => {
          console.error(err)
        });
      }
    });
  }

  getQuote(fromAddress, fromCoordinates, toAddress, toCoordinates, pickUpDate, pickUpTime, duration, numberOfPassengers, amountOfLuggage, serviceLevel, group, accountRebate) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    let startTime = formatDate(combineDateAndTime(pickUpDate, pickUpTime));

    const params = {
      web: 1,
      from: fromAddress,
      startLoc: fromCoordinates,
      to: toAddress,
      destLoc: toCoordinates,
      startTime,
      passengers: numberOfPassengers,
      luggage: amountOfLuggage,
      serviceLevel,
      group,
      accountRebate
    };

    if (duration) {
      params.estimatedTime = parseInt(duration) * 3600;
    }

    const pathObj = {
      urlTemplate: endpoints.API.RIDES.QUOTE,
      queryParams: params,
    };

    return this.get(pathObj, null, headers);
  }

  /**
   * Get available vehicles for a service level based on the time when the ride is scheduled.
   * @param serviceLevelId
   * @param {String} pickUpDate
   * @param {String} pickUpTime
   * @param {Array} fromCoordinates
   * @param {Array} toCoordinates
   * @param {Number} duration
   * @param {String} quote
   * @param groupId
   * @returns {Promise}
   */
  getServiceLevelAvailability(serviceLevelId, pickUpDate, pickUpTime, fromCoordinates, toCoordinates, duration, quote, groupId) {
    const token = getToken();
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${ token }`;
    }
    let startTime = formatDate(combineDateAndTime(pickUpDate, pickUpTime));
    const body = {
      scheduledTime: startTime,
      quote,
      startLoc: fromCoordinates,
      destLoc: toCoordinates,
      vehicles: true,
      groupId
    };
    if (duration) {
      body.estimatedTime = parseInt(duration) * 3600;
    }
    const pathObj = {
      urlTemplate: endpoints.API.SERVICE_LEVEL_AVAILABILITY,
      routeParams: {serviceLevelId}
    };
    return this.post(pathObj, body, headers);
  }

  /**
   * Get available vehicles for a service level based on the time when the ride is scheduled.
   * @param serviceLevelId
   * @param {String} pickUpDate
   * @param {String} pickUpTime
   * @param {Array} fromCoordinates
   * @param {Array} toCoordinates
   * @param {Number} duration
   * @param {String} quote
   * @param serviceLevels
   * @param groupId
   * @returns {Promise}
   */
  getVehicleAvailability(serviceLevelId, pickUpDate, pickUpTime, fromCoordinates, toCoordinates, duration, quote, serviceLevels, groupId) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    let startTime = formatDate(combineDateAndTime(pickUpDate, pickUpTime));
    const body = {
      scheduledTime: startTime,
      quote,
      startLoc: fromCoordinates,
      destLoc: toCoordinates,
      serviceLevels,
      groupId
    };
    if (duration) {
      body.estimatedTime = parseInt(duration) * 3600;
    }
    const pathObj = {
      urlTemplate: endpoints.API.VEHICLE_AVAILABILITY,
      routeParams: {serviceLevelId}
    };
    return this.post(pathObj, body, headers);
  }

  _getAffiliateGroupId(conciergeGroupId, conciergeState) {
    const filteredAffiliates = conciergeState.availableAffiliates.filter((affiliate) => { return affiliate.group === conciergeGroupId });
    return filteredAffiliates.length > 0 ? filteredAffiliates[0].affiliate.group._id : null;
  }

  getAffiliates() {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.AFFILIATE.BASE,
      queryParams: {web: 1}
    };

    return this.get(pathObj, null, headers);
  }

  getConciergeAccount(accountId) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.CORPORATE.CONCIERGE,
      routeParams: {accountId: accountId},
    };

    return this.get(pathObj, {web: 1}, headers);
  }

  getConciergeGroup(groupId) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.CORPORATE.GROUP_CONCIERGE,
      routeParams: {groupId},
      queryParams: {web: 1}
    };

    return this.get(pathObj, null, headers);
  }

  /**
   *
   * @param accountId {string}
   * @param groupId {string}
   */
  getAccountServiceLevels(accountId, groupId) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.CORPORATE.SERVICE_LEVELS,
      routeParams: {
        accountId: accountId,
        groupId: groupId
      },
    };

    return this.get(pathObj, {web: 1}, headers);
  }

  /**
   *
   * @param affiliateId
   */
  listAffiliateServiceLevels(affiliateId) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.AFFILIATE.SERVICE_LEVELS,
      routeParams: {
        affiliateId: affiliateId
      },
    };

    return this.get(pathObj, {web: 1}, headers);
  }

  /**
   * @param payload.fromAddress
   * @param  payload.fromCoordinates
   * @param  payload.toAddress
   * @param  payload.toCoordinates
   * @param  payload.numberOfPassengers
   * @param  payload.pickUpDate
   * @param  payload.pickUpTime
   * @param  payload.serviceLevel
   * @param  payload.group
   * @returns {Promise<* | void>}
   */
  // getQuote(payload) {
  //   let startTime = formatDate(combineDateAndTime(payload.pickUpDate, payload.pickUpTime));
  //
  //   const params = {
  //     web: 1,
  //     from: payload.fromAddress,
  //     startLoc: payload.fromCoordinates,
  //     to: payload.toAddress,
  //     destLoc: payload.toCoordinates,
  //     passengers: payload.numberOfPassengers,
  //     startTime,
  //     serviceLevel: payload.serviceLevel,
  //     group: payload.group,
  //   };
  //
  //   return this.get('/ride/quote', params);
  // }

  getPaymentCards(userId, groupId){
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.CREDIT_CARDS,
      routeParams: {userId: userId},
    };

    if (groupId) {
      pathObj.queryParams = {g: groupId}
    }

    return this.get(pathObj,null, headers);
  }

  createPaymentCard(userId, groupId, token) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.CREDIT_CARDS,
      routeParams: {userId: userId},
    };

    return this.post(pathObj, { token, group: groupId }, headers);
  }

  deletePaymentCard(userId, cardId) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.CREDIT_CARDS,
      routeParams: {
        userId: userId,
        cardId: cardId,
      },
    };

    return this.del(pathObj, null, headers);
  }

  setPrimaryCard(userId, cardId) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.PRIMARY_CARD,
      routeParams: {
        userId: userId,
        cardId: cardId,
      },
    };

    return this.put(pathObj, null, headers);
  }

  /**
   *
   * @param params {{
   *
   * }}
   * @returns {Promise<* | void>}
   */
  createGuestUser(params) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.CREATE_GUEST,
    };

    return this.post(pathObj, params, headers);
  }

  /**
   *
   * @param params {{
   *
   * }}
   * @returns {Promise<* | void>}
   */
  updateGuestUser(userId, groupId, params) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.UPDATE_GUEST,
      routeParams: {
        groupId: groupId,
        userId: userId,
      },
    };

    return this.put(pathObj, params, headers);
  }

  /**
   *
   * @param payload {{
   *   firstName,
   *   lastName,
   *   email,
   *   phoneNumber
   *   externalId
   * }}
   */
  createCompanyUser(payload) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const data = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phone: payload.phoneNumber,
      externalId: payload.externalId
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.BASE,
    };

    return this.post(pathObj, data, headers);
  }

  /**
   *
   * @param params {{
   *   email,
   *   isAdmin,
   *   groupId
   * }}
   */
  addUserToWhiteList(params) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const data = {
      email: params.email,
      isAdmin: params.role,
      conciergeGroupId: params.companyId
    };
    const pathObj = {
      urlTemplate: endpoints.API.GROUPS.WHITELIST,
      routeParams: {
        groupId: params.companyId
      }
    };

    return this.post(pathObj, data, headers);
  }

  /**
   *
   * @param payload {{
   *   firstName,
   *   lastName,
   *   id,
   *   groupId
   * }}
   */

  updateCompanyUser(payload) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.UPDATE,
      routeParams: {userId: payload.id, groupId: payload.companyId}
    };

    return this.put(pathObj, payload, headers);
  }

  deleteCompanyUser(userId) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.BASE,
      routeParams: {userId: userId}
    };

    return this.del(pathObj, null, headers);
  }

  /**
   * @param payload.group
   * @param payload.startTime
   * @param payload.riders
   * @param payload.passengers
   * @param payload.serviceLevel
   * @param payload.bookingAgent
   * @param payload.customerNotes
   * @param payload.isFarmOut
   * @param payload.paymentMethod
   * @param [payload.affiliateOffers] [Object]
   * @param payload.affiliateOffers.affiliate
   * @param payload.affiliateOffers.affiliateGroup
   * @param payload.affiliateOffers.affiliateType
   * @param payload.affiliateOffers.serviceLevel
   * @param [payload.affiliateOffers.markup]
   * @returns {Promise<* | void>}
   */
  createRide(payload) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const data = {
      startTime: payload.startTime,
      rider: payload.riders[0].rider,
      riders: payload.riders,
      passengers: payload.passengers,
      serviceLevel: payload.serviceLevel,
      bookingAgent: payload.bookingAgent,
      customerNotes: payload.customerNotes,
      isFarmOut: payload.isFarmOut,
      paymentMethod: payload.paymentMethod,
      estimatedTime: payload.estimatedTime,
      couponCode: payload.couponCode,
      group: payload.group,
    };

    if (payload.postingNumber) {
      data.postingNumber = payload.postingNumber;
    }

    if (payload.propertyCode) {
      data.propertyCode = payload.propertyCode;
    }

    if (payload.hotelGuestName) {
      data.hotelGuestName = payload.hotelGuestName;
    }

    if (payload.roomNumber) {
      data.roomNumber = payload.roomNumber;
    }

    if (payload.extras) {
      data.extras = payload.extras;
    }

    if (payload.extrasParsed) {
      data.extrasParsed = payload.extrasParsed;
    }

    const pathObj = {
      urlTemplate: endpoints.API.RIDES.CREATE,
      queryParams: {web: 1},
    };

    return this.post(pathObj, data, headers);
  }

  /**
   *
   * @param [payload.scheduledTime],
   * @param [payload.riders],
   * @param [payload.passengers],
   * @param [payload.serviceLevel],
   * @param [payload.bookingAgent],
   * @param [payload.customerNotes],
   * @param [payload.status]
   * }
   */
  updateRide(rideId, payload) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const data = {
      scheduledTime: payload.scheduledTime,
      rider: payload.riders[0].rider,
      riders: payload.riders,
      passengers: payload.passengers,
      serviceLevel: payload.serviceLevel,
      bookingAgent: payload.bookingAgent,
      customerNotes: payload.customerNotes,
      isFarmOut: payload.isFarmOut,
      estimatedTime: payload.estimatedTime,
      couponCode: payload.couponCode,
      group: payload.group,
    };

    const transaction = {
      payment_method: payload.paymentMethod,
      cme: {
        confirmation: null,
        postingNumber: null,
        propertyCode: null,
        guestName: null,
        roomNumber: null
      }
    };

    if (payload.paymentMethod === 6) {
      transaction.cme.confirmation = null;
      transaction.cme.postingNumber = payload.postingNumber;
      transaction.cme.propertyCode = payload.propertyCode;
      transaction.cme.guestName = payload.hotelGuestName;
      transaction.cme.roomNumber = payload.roomNumber;
    }

    data.transaction = transaction;

    if (payload.extras) {
      data.extras = payload.extras;
    }

    if (payload.extrasParsed) {
      data.extrasParsed = payload.extrasParsed;
    }

    const pathObj = {
      urlTemplate: endpoints.API.RIDES.UPDATE,
      queryParams: { web: 1 },
      routeParams: { rideId: rideId },
    };

    return this.put(pathObj, data, headers);
  }

  /**
   *
   * @param options {{
   *   status: [string],
   *   populate [string],
   *   perPage,
   *   scheduledTime,
   *   startTime,
   *   startId
   *   sortOrder
   * }}
   * @returns {Promise<* | void>}
   */
  listRides(options) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.RIDES.LISTVIEW,
      queryParams: {
        populate: options.populate,
        rideStatus: options.status,
        perPage: options.perPage,
        scheduledTime: options.scheduledTime,
        startTime: options.startTime,
        startId: options.startId,
        sortOrder: options.sortOrder,
        searchQuery: options.searchQuery,
        beginTime: options.beginTime,
        endTime: options.endTime,
        isConciergePortal: 1
      }
    };

    return this.get(pathObj, null, headers);
  }

  /**
   *
   * @param rideId
   * @param group
   * @returns {Promise<* | void>}
   */
  cancelRide(rideId, group) {
      const headers = {
        'Authorization': `Bearer ${ getToken() }`
      };

      const data = {
        status: RIDE_PROGRESS_BAR_CANCELLED_STATUS,
        group
      };

      const pathObj = {
        urlTemplate: endpoints.API.RIDES.UPDATE,
        queryParams: {web: 1},
        routeParams: {rideId: rideId},
      };

      return this.put(pathObj, data, headers);
  }

  /**
   *
   * @param rideId
   * @param options {{
   *   populate
   * }}
   * @returns {Promise<* | void>}
   */
  getRideDetails(rideId, options) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.RIDES.SINGLE,
      routeParams: {rideId: rideId},
      queryParams: {
        populate: options.populate,
        web: 1
      },
    };

    return this.get(pathObj, null, headers);
  }

  /**
   *
   * @param rideId
   * @param payload.dropoff_time
   * @param payload.pickup_time
   * @param [payload.bcc],
   * @param [payload.bookingConfirmed]
   * @returns {Promise<* | void>}
   */
  sendEmail(rideId, payload) {
    payload = _.defaults(payload,{
      bcc: null,
      bookingConfirmed: false
    });
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };
    const data = {
      rideId,
      bcc: payload.bcc,
      bookingConfirmed: payload.bookingConfirmed,
      pickup_time: payload.pickup_time,
      dropoff_time: payload.dropoff_time,
    };

    const pathObj = {
      urlTemplate: endpoints.API.RIDES.RECEIPT,
      routeParams: {rideId: rideId}
    };

    return this.post(pathObj, data, headers);
  }

  /**
   * @description it retrieves the administrators and dispatchers for a group
   * @param groupId
   */
  getCompanyUsers(groupId) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };
    const pathObj = {
      urlTemplate: endpoints.API.GROUPS.USERS,
      routeParams: { groupId }
    };

    return this.get(pathObj, null, headers);
  }

  getUserDetails(userId) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.PROFILE,
      routeParams: {userId}
    };

    return this.get(pathObj, null, headers);
  }

  updateUserDetails(userId, payload) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.PROFILE,
      routeParams: {userId: userId}
    };

    const data = _.assign({}, payload);

    return this.put(pathObj, data, headers);
  }

  getCurrentUserDetails() {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.PROFILE,
    };

    return this.get(pathObj, null, headers);
  }

  updateCurrentUserDetails(payload) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const data = _.assign({}, payload);

    const pathObj = {
      urlTemplate: endpoints.API.USER.PROFILE,
    };

    return this.put(pathObj, data, headers);
  }

  /**
   *
   * @param groupId
   * @returns {Promise<* | void>}
   */
  getGroupDetails(groupId, tempToken) {
    const headers = {
      'Authorization': `Bearer ${ getToken() || tempToken }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.GROUPS.BASE,
      routeParams: { groupId },
    };
    return this.get(pathObj, null, headers);
  }

  /**
   *
   * @param groupId
   * @param payload {{
   *   companyName,
   *   timeZone,
   * }}
   */
  updateCurrentGroupDetails(payload) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const data = {
      name: payload.companyName,
      settings: {
        timezone: payload.timeZone,
      },
      groupId: payload.groupId
    };

    const pathObj = {
      urlTemplate: endpoints.API.GROUPS.DETAILS,
    };

    return this.put(pathObj, data, headers);
  }

  getGroups() {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.GROUPS.BASE,
    };

    return this.get(pathObj, null, headers);
  }

  getMarkets(location) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const conciergeState = storeRegistry.getStore().getState().concierge;

    const pathObj = {
      urlTemplate: endpoints.API.MARKETS.FOR_LOCATION,
      queryParams: {
        group: this._getAffiliateGroupId(conciergeState.conciergeGroup.conciergeGroupId, conciergeState),
        location
      },
    };

    return this.get(pathObj, null, headers);
  }

  /**
   *
   * @param [payload.startDate]
   * @param [payload.endDate]
   * @param [payload.emails]
   * @param [options.populate]
   * @returns {Promise<* | void>}
   */
  getCompanyRidesAsCSV(payload, options) {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const data = {
      // TODO: have discussion to see if changed to REPORT_CONCIERGE_RIDES
      reportType: REPORT_RIDE_SUMMARY,
      query: {
        start: payload.startDate,
        end: payload.endDate,
      },
      emails: payload.emails
    };

    const pathObj = {
      urlTemplate: endpoints.API.REPORTING,
      queryParams: {
        populate: options.populate,
      },
    };

    return this.post(pathObj, data, headers);
  }

  getUserGroups(tempToken) {
    const headers = {
      'Authorization': `Bearer ${ getToken() || tempToken }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.USER.GROUPS,
    };

    const data = {
      perPage: 500
    };

    return this.get(pathObj, data, headers);
  }

  getAirlines() {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.FLIGHT.AIRLINES,
    };

    return this.get(pathObj, null, headers);
  }

  getFlightStatus(flight){
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    const pathObj = {
      urlTemplate: endpoints.API.FLIGHT.STATUS,
      queryParams: flight
    };

    return this.get(pathObj, null, headers);
  }
}
