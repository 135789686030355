import React from 'react';
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import '../../styles/not-found-page.scss';

const NotFoundPage = () => {
  return (
    <div style={{flexGrow: 1}}>
      <Grid container>
        <Grid item xs={12} className="page-not-found-header">
          <Typography variant="h4">Page not found</Typography>
        </Grid>
        <Grid item xs={12} className="paragraph">
          <Typography variant="subtitle1">Maybe the page you are looking for has been removed, or you typed in the wrong URL.</Typography>
        </Grid>
        <Grid item xs={12} className="paragraph">
          <p><Link to="/rides/all"> View all rides </Link> | <Link to="/rides/new"> Book a ride </Link></p>
        </Grid>
      </Grid>
      <div className="version-number-container">
        <Typography component="div" className="version-number-text">Version {process.env.VERSION}</Typography>
      </div>
    </div>
  );
};

export default NotFoundPage;
