import { connect } from 'react-redux';
import {
  setCurrentUser,
  setCurrentGroupId,
  setCurrentGroupLogo,
  setAvailableGroups,
  setConciergeGroup,
  setCorporateAccount,
  setAvailableAffiliates,
  setIsLoading,
  setGlobalNotification,
} from "../actions/ConciergeActions";


function mapStateToProps(state) {
  const s = state.concierge || state;
  return {
    currentUser: s.currentUser,
    currentGroupId: s.currentGroupId,
    currentGroupLogo: s.currentGroupLogo,
    availableGroups: s.availableGroups,
    conciergeGroup: s.conciergeGroup,
    corporateAccount: s.corporateAccount,
    availableAffiliates: s.availableAffiliates,
    isLoading: s.isLoading,
    globalNotification: s.globalNotification
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
    setCurrentGroupId: (currentGroupId) => dispatch(setCurrentGroupId(currentGroupId)),
    setCurrentGroupLogo: (currentGroupLogo) => dispatch(setCurrentGroupLogo(currentGroupLogo)),
    setAvailableGroups: (availableGroups) => dispatch(setAvailableGroups(availableGroups)),
    setConciergeGroup: (conciergeGroup) => dispatch(setConciergeGroup(conciergeGroup)),
    setCorporateAccount: (corporateAccount) => dispatch(setCorporateAccount(corporateAccount)),
    setAvailableAffiliates: (availableAffiliates) => dispatch(setAvailableAffiliates(availableAffiliates)),
    setIsLoading: (isLoading) => dispatch(setIsLoading(isLoading)),
    setGlobalNotification: (globalNotification) => dispatch(setGlobalNotification(globalNotification)),
  };
}

export default function createConnection() {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  );
}
