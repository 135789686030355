import React from "react";
import ConciergeContainer from "../../containers/ConciergeContainer";
import "../../styles/login-page.scss"
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {KaptynDataService} from "../../dataservices/KaptynDataService";
import {setCookies, hasActiveSession, getEmailAccordingRememberMeFlag, isRememberMeChecked} from "../../utils/cookieUtil";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as constants from "../../constants/Constants";
import Notification from "../lower-level/Notification";
import ConciergeLogo from "../../images/logos/logo-kaptyn-concierge-2-x.png";

const stringUtils = require("underscore.string");

export class DisplayLoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: getEmailAccordingRememberMeFlag(),
      password: "",
      isLoading: "determinate",
      rememberMe: (isRememberMeChecked() === 'true'),
      formHasErrors: false,
      formErrors: {},
      notification: {
        open: this.props.globalNotification.open,
        type: this.props.globalNotification.type,
        content: this.props.globalNotification.content,
        autoDismissDuration: this.props.globalNotification.autoDismissDuration
      }
    };
    this._handleChange = this._handleChange.bind(this);
    this._handleLogin = this._handleLogin.bind(this);
    this._handleRememberMeChange = this._handleRememberMeChange.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._handleError = this._handleError.bind(this);
    this._redirectoToSignup = this._redirectoToSignup.bind(this);
  }

  componentDidMount() {
    if (hasActiveSession()) {
      this.props.history.push('/');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.globalNotification !== nextProps.globalNotification) {
      const notification = {
        open: nextProps.globalNotification.open,
        type: nextProps.globalNotification.type,
        content: nextProps.globalNotification.content,
        autoDismissDuration: nextProps.globalNotification.autoDismissDuration
      };
      this.setState({ notification });
    }
  }

  _handleChange(e) {
    const { name, value } = e.target;
    const newState = {
      ...this.state,
      [name]: value
    };
    this.setState(newState);
  }

  _handleError(errorMessage) {
    const globalNotification = {
      open: true,
      type: constants.NOTIFICATION_TYPE_ERROR,
      content: errorMessage,
      autoDismissDuration: constants.DEFAULT_AUTO_DISMISS_DURATION
    };
    this.props.setGlobalNotification({ globalNotification });
  }

  _handleLogin() {
    if(!this.state.formHasErrors) {
      this.setState({ isLoading: "indeterminate" });
      const dataService = new KaptynDataService();
      dataService.login(this.state.email, this.state.password).then(async (loginResult) => {
        if (loginResult.user.email.toLowerCase() === this.state.email.toLowerCase()) {
          let lastSessionGroupDetails = null;
          if (loginResult.user.lastSessionGroup) {
            lastSessionGroupDetails = await dataService.getGroupDetails(loginResult.user.lastSessionGroup, loginResult.token);
          }
          let conciergeGroupId = lastSessionGroupDetails && lastSessionGroupDetails.account_type === 'concierge' ? lastSessionGroupDetails._id : null;
          dataService.getUserGroups(loginResult.token).then((availableGroups) => {
            const filteredAvailableGroups = availableGroups.filter((group) => { return group.account_type === 'concierge'});
            if (!conciergeGroupId && filteredAvailableGroups.length > 0) {
              conciergeGroupId = filteredAvailableGroups[0]._id;
            }
            const refreshPayload = {
              group: conciergeGroupId,
              refreshToken: loginResult.refreshToken,
              token: loginResult.token
            };
            dataService.refreshSession(refreshPayload).then((refreshResult) => {
              const userRoles = refreshResult.permissions.userRoles;
              const isAdmin = !!(refreshResult.permissions && userRoles && userRoles.length > 0 && (userRoles.includes('administrator') || userRoles.includes('superadmin') || userRoles.includes('owner')));
              setCookies(
                refreshResult.user.id,
                refreshResult.token,
                refreshResult.user.email,
                refreshResult.user.firstName,
                refreshResult.user.lastName,
                '',
                this.state.rememberMe,
                refreshResult.refreshToken,
                isAdmin.toString(),
                conciergeGroupId);

              this.setState({ isLoading: "determinate" });

              this.props.history.push('/rides/all');
            }).catch((error) => {
              this._handleError(error.message);
              this.setState({ isLoading: "determinate" });
            });
          }).catch((error) => {
            this._handleError(error.message);
            this.setState({ isLoading: "determinate" });
          });
        } else {
          this._handleError('An error occurred while logging in.');
          this.setState({ isLoading: "determinate" });
        }
      }).catch((error) => {
        this._handleError(error.message);
        this.setState({ isLoading: "determinate" });
      });
    }
  }

  _handleRememberMeChange(event) {
    this.setState({ rememberMe: event.target.checked });
  }

  _validateForm() {
    let formHasErrors = false;
    let formErrors = {};

    if (stringUtils.isBlank(this.state.email)) {
      formHasErrors = true;
      formErrors.email = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.password)) {
      formHasErrors = true;
      formErrors.password = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    this.setState({ formHasErrors, formErrors }, this._handleLogin);
  }

  _redirectoToSignup() {
    this.props.history.push('/signup');
  }

  render() {
    return (
      <div>
        <div className="concierge-logo">
          <img src={ ConciergeLogo } />
        </div>
        <Notification open={ this.state.notification.open }
                      type={ this.state.notification.type }
                      content={ this.state.notification.content }
                      autoDismissDuration={ this.state.notification.autoDismissDuration }
                      setGlobalNotification={ this.props.setGlobalNotification } />
          <Grid container>
            <Grid item xs={8} sm={7} md={5} lg={12}
              className="login-form"
            >
              <Paper
                variant="elevation"
                elevation={2}
                className="login-background"
              >
                <Grid item xs={12}>
                  <Typography component="h1" variant="h5" className="login-form-title">
                    Log in
                  </Typography>
                </Grid>
                <Grid item>
                  <form noValidate>
                    <Grid container direction="column" spacing={0}>
                      <Grid item>
                        <TextField
                          type="email"
                          placeholder="Email"
                          name="email"
                          fullWidth
                          value={this.state.email}
                          className="login-form-email-input login-form-input"
                          inputProps={{style: {fontSize: 16}}}
                          onChange={ this._handleChange }
                          error={ !!this.state.formErrors.email }
                          helperText={ this.state.formErrors.email }
                          autoFocus
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          type="password"
                          placeholder="Password"
                          fullWidth
                          name="password"
                          value={this.state.password}
                          onChange={ this._handleChange }
                          error={ !!this.state.formErrors.password }
                          helperText={ this.state.formErrors.password }
                          className="login-form-password-input login-form-input"
                        />
                      </Grid>
                      <Grid className="login-form-extra-buttons">
                        <Grid item xs={12} sm={12}  style={{padding:0,margin:0}}>
                          <FormControlLabel className="login-form-checkbox-form-control"
                                            control={
                                              <Checkbox
                                                onChange={ this._handleRememberMeChange }
                                                name="forgotPassword"
                                                color="primary"
                                                checked={this.state.rememberMe}
                                                className="login-form-remember-me-checkbox"
                                              />
                                            }
                                            label="Remember me"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Link onClick={ () => this.props.history.push('/forgot') } variant="body2" className="login-form-forgot-password-text">
                            Forgot Password?
                          </Link>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          color="primary"
                          className="button-block"
                          component="button"
                          size="large"
                          onClick={ this._validateForm }
                        >
                          Log in<CircularProgress
                          variant={this.state.isLoading}
                          size={20}
                          thickness={3}
                          className="login-form-circular-progress"
                        />
                        </Button>
                      </Grid>
                      <Grid item xs={12} className="login-signup-container">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <Typography display="inline" className="login-dont-have-account-text">Don't have an account? </Typography>
                        <Typography display="inline" className="login-form-sign-up-link" onClick={ () => this.props.history.push('/signup') }>Create an account</Typography>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        <div className="version-number-container">
          <Typography component="div" className="version-number-text">Version {process.env.VERSION}</Typography>
        </div>
      </div>
    );
  }
}

DisplayLoginPage.propTypes = {
  history: PropTypes.object,
  globalNotification: PropTypes.object.isRequired,
  setGlobalNotification: PropTypes.func.isRequired
};

const LoginPage = ConciergeContainer()(DisplayLoginPage);

export default LoginPage;
