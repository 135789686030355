import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import SquareIcon from '@material-ui/icons/Stop';
import SvgIcon from "@material-ui/core/SvgIcon";
import { VEHICLE_ICON, USER_ICON } from "../../icons/rides-list-item/svg";
import {
  PRIMARY_BLACK,
  PRIMARY_BLUE,
  PRIMARY_GRAY,
  PRIMARY_GREEN,
  PRIMARY_RED, PRIMARY_WHITE,
  RIDE_PROGRESS_BAR_ARRIVED_LABEL,
  RIDE_PROGRESS_BAR_ARRIVED_STATUS,
  RIDE_PROGRESS_BAR_ARRIVED_VALUE,
  RIDE_PROGRESS_BAR_CANCELLED_LABEL,
  RIDE_PROGRESS_BAR_CANCELLED_STATUS,
  RIDE_PROGRESS_BAR_COMPLETED_CANCELLED_VALUE,
  RIDE_PROGRESS_BAR_COMPLETED_LABEL,
  RIDE_PROGRESS_BAR_COMPLETED_STATUS,
  RIDE_PROGRESS_BAR_EN_ROUTE_LABEL,
  RIDE_PROGRESS_BAR_EN_ROUTE_STATUS,
  RIDE_PROGRESS_BAR_EN_ROUTE_VALUE, RIDE_PROGRESS_BAR_IN_PROGRESS_LABEL, RIDE_PROGRESS_BAR_IN_PROGRESS_STATUS,
  RIDE_PROGRESS_BAR_SCHEDULED_LABEL,
  RIDE_PROGRESS_BAR_SCHEDULED_STATUS,
  RIDE_PROGRESS_BAR_SCHEDULED_VALUE, SECONDARY_BLACK,
  TRANSACTIONS
} from "../../constants/Constants";
import LinearProgress from '@material-ui/core/LinearProgress';
import '../../styles/rides-list-item.scss';
import PropTypes from 'prop-types';
import {formatDateForCurrentUser} from "../../utils/dateUtil";
import EditIcon from '@material-ui/icons/Edit';
import endpoints from '../../constants/EndpointsProvider';
import {getToken} from "../../utils/cookieUtil";

export default function RidesListItem (props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 0,
      minHeight:'154px',
      backgroundColor:PRIMARY_WHITE
    },
    cardContent: {
    padding:'10px 22.5px'
    },
    cardContainer:{
      flexGrow: 1,
      borderRadius:'0',
      boxShadow:'none'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    square: {
      color: (props.rideStatus === RIDE_PROGRESS_BAR_ARRIVED_STATUS  || props.rideStatus === RIDE_PROGRESS_BAR_EN_ROUTE_STATUS || props.rideStatus === RIDE_PROGRESS_BAR_SCHEDULED_STATUS || props.rideStatus === RIDE_PROGRESS_BAR_IN_PROGRESS_STATUS) ? PRIMARY_BLUE : props.rideStatus === RIDE_PROGRESS_BAR_COMPLETED_STATUS ? PRIMARY_GREEN : props.rideStatus === RIDE_PROGRESS_BAR_CANCELLED_STATUS ? PRIMARY_RED : 'transparent'
    },
    pickUpSquare: {
      border:'3.3px solid '+ PRIMARY_BLACK,
      padding:'1px',
      width:'20px',
      height:'20px'
    },
    dropOffSquare: {
      border:'7.5px solid '+ PRIMARY_BLACK,
      width:'20px',
      height:'20px',
      color:'white'
    }
    ,
    rideStatus: {
      color: (props.rideStatus === RIDE_PROGRESS_BAR_ARRIVED_STATUS  || props.rideStatus === RIDE_PROGRESS_BAR_EN_ROUTE_STATUS || props.rideStatus === RIDE_PROGRESS_BAR_SCHEDULED_STATUS || props.rideStatus === RIDE_PROGRESS_BAR_IN_PROGRESS_STATUS) ? PRIMARY_BLUE : props.rideStatus === RIDE_PROGRESS_BAR_COMPLETED_STATUS ? PRIMARY_GREEN : props.rideStatus === RIDE_PROGRESS_BAR_CANCELLED_STATUS ? PRIMARY_RED : 'transparent',
      marginTop:'5px',
      marginLeft:'-5px'
    },
    phoneNumber: {
      paddingLeft:'8px'
    },
    driverName: {
      paddingLeft:'15px',
      fontWeight:'bold',
      color: 'rgba(0, 0, 0, 0.87)'
    },
    serviceLevelName: {
      fontWeight:'bold',
      color: 'rgba(0, 0, 0, 0.87)',
      marginLeft:'8px'
    },
    slider: {
      width:'100%',
      display:'block'
    },
    rideStatusContainer: {
      marginLeft:'0px',
      marginTop:'5px'
    },
    fromAddress: {
      marginTop:'-20px',
      paddingLeft:'35px'
    },
    rectangle: {
      color:PRIMARY_GRAY,
      margin:'3px 8px',
      width:'2px',
      height:'12px',
      backgroundColor:PRIMARY_GRAY
    },
    cancelButton: {
      borderRadius:'0',
      textTransform: 'none',
      fontSize: '15px',
      fontWeight: 525,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: '0.25px',
      border: 'solid 1.5px '+ SECONDARY_BLACK,
      marginLeft:'8px',
      marginTop:'-4.5px',
    },
    linearProgress: {
      height:'2px',
      width:'32.5%'
    },
    colorPrimary: {
      height:'2px',
      width:'40%',
      backgroundColor: PRIMARY_GRAY

    },
    barColorPrimary: {
      backgroundColor: (props.rideStatus === RIDE_PROGRESS_BAR_SCHEDULED_STATUS || props.rideStatus === RIDE_PROGRESS_BAR_ARRIVED_STATUS  || props.rideStatus === RIDE_PROGRESS_BAR_EN_ROUTE_STATUS || props.rideStatus === RIDE_PROGRESS_BAR_IN_PROGRESS_STATUS) ? PRIMARY_BLUE : props.rideStatus === RIDE_PROGRESS_BAR_COMPLETED_STATUS ? PRIMARY_GREEN : props.rideStatus === RIDE_PROGRESS_BAR_CANCELLED_STATUS ? PRIMARY_RED  : 'transparent'
    },
    fullNameRow: {
      padding:'5px 0'
    },
    userIcon: {
      marginTop:'-9px',
      marginRight:'3px'
    }
    ,
    carInfoRow: {
      marginBottom:'0px',
      marginTop:'16px'
    },
    serviceLevelAndVehicleContainer: {
      padding:'2.5px 35px'
    },
    reservationNumberContainer: {
      display: 'flex',
      borderColor:'transparent',
      borderBottom: 'solid 1px #f3f3f3',
      padding:'10px 22.5px'
    }
    ,
    reservationNumber: {
      fontSize:'10px',
      fontWeight: '700',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.6',
      letterSpacing: '1.5px',
      color: 'rgba(0, 0, 0, 0.6)'
    }
  }));
  const classes = useStyles();
  const canEdit = (rideStatus) => {
    return rideStatus === 'scheduled' || rideStatus === 'en_route';
  };
  const handleCancel = () => {
    props.onCancelRide(props.rideId, props.reservationNumber);
  };
  const viewReceipt = () => {
    const url = `${process.env.KAPTYN_API_BASE_URL}${endpoints.API.RIDES.DOWNLOAD_RECEIPT.replace('{rideId}', props.rideId)}`
    window.open(`${url}?access_token=${getToken()}&web=1`);
  };

  const _renderCancelOrReceiptButton = () => {
    if (props.rideStatus === RIDE_PROGRESS_BAR_SCHEDULED_STATUS || props.rideStatus === RIDE_PROGRESS_BAR_ARRIVED_STATUS  || props.rideStatus === RIDE_PROGRESS_BAR_EN_ROUTE_STATUS || props.rideStatus === RIDE_PROGRESS_BAR_IN_PROGRESS_STATUS) {
      return (
        <Button variant="outlined" className="rides-list-item-cancel-button" onClick={handleCancel}>Cancel Booking</Button>
      );
    } else if (props.rideStatus === RIDE_PROGRESS_BAR_COMPLETED_STATUS && props.transactionBalance > 0 && props.transactionStatus === TRANSACTIONS.COMPLETE) {
      return (
        <Button variant="outlined" className="rides-list-item-receipt-button" onClick={viewReceipt}>View Receipt</Button>
      );
    }
  }
  const _goToRide = () => {
    props.history.push(`/rides/${ props.rideId }`);
  };
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs sm={12} md={12}>
          <div className={classes.reservationNumberContainer}>
            <div style={{ flex: 1 }}>
              <Typography display="inline" color="textSecondary" className={classes.reservationNumber}>
                { props.reservationNumber ? props.reservationNumber : '' }  |  {  formatDateForCurrentUser(props.scheduledTime).toUpperCase() }
              </Typography>
            </div>
            { canEdit(props.rideStatus) ? <div style={{ flex: 1 }}><div style={{ float: 'right' }}><a onClick={ _goToRide } href="javascript:void(0)"><EditIcon fontSize="small"/></a></div></div> : null }
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.cardContainer}>
            <CardContent className="rides-list-item-first-row-content">
              <div className="rides-list-item-full-name">
                <SvgIcon className="rides-list-user-icon">
                  <path d={ USER_ICON }/>
                </SvgIcon>
                <Typography variant="h5" display="inline" component="h2" className="rides-list-item-only-name">
                  { props.passengerName } ({props.passengers})
                </Typography>
                <Typography display="inline" className="rides-list-item-passenger-phone-number" color="textSecondary">
                  { props.passengerPhone }
                </Typography>
              </div>
              <div>
                <Grid container alignItems="center" className={classes.rideStatusContainer}>
                  <Grid item xs={12}>
                    <LinearProgress classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}} value={(props.rideStatus === RIDE_PROGRESS_BAR_SCHEDULED_STATUS) ? RIDE_PROGRESS_BAR_SCHEDULED_VALUE : (props.rideStatus === RIDE_PROGRESS_BAR_EN_ROUTE_STATUS) ? RIDE_PROGRESS_BAR_EN_ROUTE_VALUE : props.rideStatus === RIDE_PROGRESS_BAR_ARRIVED_STATUS || props.rideStatus === RIDE_PROGRESS_BAR_IN_PROGRESS_STATUS ? RIDE_PROGRESS_BAR_ARRIVED_VALUE:  (props.rideStatus === RIDE_PROGRESS_BAR_COMPLETED_STATUS || props.rideStatus === RIDE_PROGRESS_BAR_CANCELLED_STATUS) ? RIDE_PROGRESS_BAR_COMPLETED_CANCELLED_VALUE : 0 } valueBuffer={100} variant="determinate"  className="rides-list-progress-bar-component"/>
                  </Grid>
                  <Grid item xs={12} className={classes.rideStatus}>
                    <SquareIcon color="inherit" className={classes.square}/>
                    <Typography component="span">{props.rideStatus === RIDE_PROGRESS_BAR_SCHEDULED_STATUS ? RIDE_PROGRESS_BAR_SCHEDULED_LABEL : props.rideStatus === RIDE_PROGRESS_BAR_EN_ROUTE_STATUS ? RIDE_PROGRESS_BAR_EN_ROUTE_LABEL : props.rideStatus === RIDE_PROGRESS_BAR_ARRIVED_STATUS ? RIDE_PROGRESS_BAR_ARRIVED_LABEL:props.rideStatus === RIDE_PROGRESS_BAR_IN_PROGRESS_STATUS ? RIDE_PROGRESS_BAR_IN_PROGRESS_LABEL:  props.rideStatus === RIDE_PROGRESS_BAR_COMPLETED_STATUS ? RIDE_PROGRESS_BAR_COMPLETED_LABEL :  props.rideStatus === RIDE_PROGRESS_BAR_CANCELLED_STATUS ? RIDE_PROGRESS_BAR_CANCELLED_LABEL : '' }</Typography>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.carInfoRow}>
                <SvgIcon className="rides-list-car-icon">
                  <path d={ VEHICLE_ICON }/>
                </SvgIcon>
                <Typography display="inline" className={classes.serviceLevelName} component="h7">
                  { props.serviceLevelName }
                </Typography>
              </div>
              <div className={classes.serviceLevelAndVehicleContainer}>
                <Typography display="inline" className="rides-list-item-driver-name" color="textSecondary">
                  { props.driverName ? props.driverName : '' }
                </Typography>
                <Typography display="inline" className="rides-list-item-driver-phone-number" color="textSecondary">
                  { props.driverPhone ? props.driverPhone : '' }
                </Typography>
                <Typography display="inline" className="rides-list-vehicle-info" color="textSecondary">
                  { props.vehicleColor ? props.vehicleColor + ' '  : '' }
                  { props.vehicleMake ? props.vehicleMake + ' ' : '' }
                  { props.vehicleModel ? props.vehicleModel + ' ' : '' }
                  { props.vehicleNumber ? ' (' + props.vehicleNumber + ')' : ''}
                </Typography>
              </div>
              <br/>
            </CardContent>
            <CardActions>

            </CardActions>
          </Card>
        </Grid>
        {/*Second row*/}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.cardContainer}>
            <CardContent className="rides-list-item-second-row-content">
              <SquareIcon className={classes.pickUpSquare}/>
              <Typography display="block" color="textSecondary" className={classes.fromAddress}>
                { props.fromAddress }
              </Typography>
              {/*TODO:the gray line that separates the squares is not responsive*/}
              <Typography className={classes.rectangle}/>
              <SquareIcon className={classes.dropOffSquare}/>
              <Typography display="block" color="textSecondary" className={classes.fromAddress}>
                { props.toAddress ? props.toAddress : 'As directed' }
              </Typography>
            </CardContent>
            <CardActions>
              { _renderCancelOrReceiptButton() }
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

RidesListItem.propTypes = {
  history: PropTypes.object,
  onCancelRide: PropTypes.func.isRequired,
  rideId: PropTypes.string.isRequired,
  passengerName: PropTypes.string.isRequired,
  passengers: PropTypes.number.isRequired,
  passengerPhone: PropTypes.string.isRequired,
  serviceLevelName: PropTypes.string.isRequired,
  rideStatus: PropTypes.string.isRequired,
  fromAddress: PropTypes.string.isRequired,
  toAddress: PropTypes.string.isRequired,
  carDetails: PropTypes.string,
  vehicleNumber: PropTypes.string.isRequired,
  vehicleModel: PropTypes.string.isRequired,
  vehicleMake: PropTypes.string.isRequired,
  vehicleColor: PropTypes.string.isRequired,
  driverPhone: PropTypes.string.isRequired,
  driverName: PropTypes.string.isRequired,
  reservationNumber: PropTypes.string.isRequired,
  scheduledTime: PropTypes.string.isRequired,
  transactionBalance: PropTypes.number,
  transactionStatus: PropTypes.number
};



