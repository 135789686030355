import browser from 'bowser';

/**
 *
 * @param options {{
 *   isConcierge: boolean
 * }}
 * @returns {Object}
 **/
export function getDeviceInfo() {
  return {
    type: 'web',
      friendlyName: browser.osname,
      osVersion: browser.osversion,
      appName: 'exec',
      browser: {
      name: browser.name,
        version: browser.version
    }
  };
}
