import { combineReducers } from 'redux';
import concierge from './ConciergeReducer';
import { connectRouter } from 'connected-react-router'

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  concierge
});

export default rootReducer;
