import {AbstractDataService} from "./AbstractDataService";


export class CMEDataService extends AbstractDataService {

  constructor() {
    super();
  }

  get apiRoot() {
    return `https://api.triadtranstech.com`;
  }

  get requestHeaders() {
    return {
      'Cache-control': 'no-cache',
      'Content-type': 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      'Accept': '*/*'
    };
  }

  getProperties() {
    const headers = {
      'AuthToken': `${ process.env.CME_API_KEY }`,
    };
    const path = '/mgm/concierge/properties';

    return this.get(path, null, headers)
  }


  checkRoom(propertyCode, room) {
    const headers = {
      'AuthToken': `${ process.env.CME_API_KEY }`,
    };

    const path = '/mgm/concierge/checkroom/';

    return this.get(path, { propertyCode: propertyCode, room: room }, headers)
  }
}
