import URLTemplate from "url-template";
import URL from "url";
import _ from "lodash";

/**
 *
 * @param urlTemplate {string}
 * @param routeParams {Object}
 * @param queryParams {Object}
 * @returns {string}
 */
export function buildUrl(urlTemplate, routeParams= {}, queryParams= {}) {
  const template = URLTemplate.parse(urlTemplate);
  routeParams = routeParams ? routeParams : {};
  const expandedUrl = template
    .expand(routeParams)
    .replace(/([^:]\/)\/+/g, "$1");

  const parsedUrl = URL.parse(expandedUrl, true);
  const requestQuery = _.assign({}, parsedUrl.query, queryParams);

  return URL.format({
    protocol: parsedUrl.protocol,
    host: parsedUrl.host,
    pathname: parsedUrl.pathname,
    query: requestQuery,
  });
}

/**
 * Formats an array to be able to add it into the URL
 * @param array {Array}
 */
export function formatArray(array) {
  if (_.isArray(array)) {
    return array.join(',');
  }

  return  array;
}
