import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {CMEDataService} from "../../dataservices/CMEDataService";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import '../../styles/charge-to-room-details.scss';
import {
  FORM_ERROR_REQUIRED_FIELD
} from "../../constants/Constants";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
let stringUtils = require("underscore.string");

const useStyles = () => ({
  root: {
    flexGrow: 1,
  },
  button: {
    borderRadius: 0,
    textTransform: 'none'
  }
});

class ChargeToRoomDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      guests: [],
      propertyCode: this.props.propertyCode || '',
      roomNumber: this.props.roomNumber || '',
      postingNumber: this.props.postingNumber || '',
      initialLoadCompleted: false,
      formHasErrors: false,
      formErrors: {}
    };
    this._getProperties = this._getProperties.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._onChange = this._onChange.bind(this);
    this._checkAndRetrieveGuests = this._checkAndRetrieveGuests.bind(this);
    this._retrieveGuests = this._retrieveGuests.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._getPropertyNameByCode = this._getPropertyNameByCode.bind(this);
    this._getGuestByPostingNumber = this._getGuestByPostingNumber.bind(this);
  }

  componentDidMount() {
    this._getProperties();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      const newState = {};
      if (this.props.errors !== newProps.errors) {
        newState.formErrors = newProps.errors;
        this.setState({ formErrors: newProps.errors });
      }
      if (newProps.postingNumber && newProps.roomNumber && newProps.propertyCode) {
        this.setState({ postingNumber: newProps.postingNumber, roomNumber: newProps.roomNumber, propertyCode: newProps.propertyCode }, this._checkAndRetrieveGuests);
      }
    }
  }

  _getProperties() {
    this.props.setIsLoading({ isLoading: true });
    const dataService = new CMEDataService();
    dataService.getProperties().then((results) => {
      this.setState({properties: results});
      this.props.setIsLoading({ isLoading: false });
    }).catch((error) => {
      this.props.onError(error.message);
      this.props.setIsLoading({ isLoading: false });
    });
  }

  _checkAndRetrieveGuests() {
    if (this.props.roomNumber && this.props.propertyCode && this.state.propertyCode && this.state.roomNumber && this.state.guests.length === 0 && !this.state.initialLoadCompleted) {
      this.setState({ initialLoadCompleted: true }, this._retrieveGuests);
    }
  }

  _retrieveGuests() {
    if (!this.state.formHasErrors) {
      this.props.setIsLoading({ isLoading: true });
      const dataService = new CMEDataService();
      dataService.checkRoom(this.state.propertyCode, this.state.roomNumber).then((result) => {
        this.setState({ guests: result.guests });
        this.props.setIsLoading({ isLoading: false });
      }).catch((error) => {
        this.props.setIsLoading({ isLoading: false });
        this.props.onError(error.message);
      });
    }
  }

  _getPropertyNameByCode(code) {
    const filteredProperties = this.state.properties.filter(function (property) {
      return property.code === code;
    });
    return filteredProperties.length > 0 ? filteredProperties[0].name : '';
  }

  _getGuestByPostingNumber(postingNumber) {
    const filteredGuests = this.state.guests.filter(function (guest) {
      return guest.postingNumber === postingNumber;
    });
    return filteredGuests.length > 0 ? filteredGuests[0] : { guestName: '', isChargeable: false };
  }

  _handleChange(e) {
    const { name, value } = e.target;
    const newState = {
      [name]: value
    };
    if (name === 'propertyCode' || name === 'roomNumber') {
      newState.guests = [];
      newState.postingNumber = '';
    }
    this.setState(newState, this._onChange);
  }

  _onChange() {
    const propertyName = this._getPropertyNameByCode(this.state.propertyCode);
    const guest = this._getGuestByPostingNumber(this.state.postingNumber);
    this.props.onChange(this.state.propertyCode, propertyName, this.state.roomNumber, this.state.postingNumber, guest.guestName, guest.isChargeable);
  }

  _validateForm() {
    let formHasErrors = false;
    let formErrors = {};

    if (stringUtils.isBlank(this.state.propertyCode)) {
      formHasErrors = true;
      formErrors.propertyCode = FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.roomNumber)) {
      formHasErrors = true;
      formErrors.roomNumber = FORM_ERROR_REQUIRED_FIELD;
    }

    this.setState({ formHasErrors, formErrors }, this._retrieveGuests);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField id="property-code"
                       name="propertyCode"
                       select
                       label="Property"
                       value={ this.state.propertyCode }
                       onChange={ this._handleChange }
                       error={ !!this.state.formErrors.propertyCode }
                       helperText={ this.state.formErrors.propertyCode }
                       fullWidth>
              {this.state.properties.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  { option.name }
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField id="room-number"
                       name="roomNumber"
                       label="Room Number"
                       value={ this.state.roomNumber }
                       onChange={ this._handleChange }
                       error={ !!this.state.formErrors.roomNumber }
                       helperText={ this.state.formErrors.roomNumber }
                       fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={ this._validateForm }
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={ this.state.guests.length > 0 }
                    className={ clsx(classes.button, 'retrieve-guests') } >
              Retrieve guests
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField id="posting-number"
                       name="postingNumber"
                       select
                       disabled={ this.state.guests.length === 0 }
                       label="Guests"
                       value={ this.state.postingNumber }
                       onChange={ this._handleChange }
                       error={ !!this.state.formErrors.postingNumber }
                       helperText={ this.state.formErrors.postingNumber }
                       fullWidth>
              {this.state.guests.map((option) => (
                <MenuItem key={option.postingNumber} value={option.postingNumber}>
                  { `${ option.guestName } (${ option.isChargeable ? 'Chargeable' : 'Not chargeable' })` }
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ChargeToRoomDetails.propTypes = {
  propertyCode: PropTypes.string,
  roomNumber: PropTypes.string,
  postingNumber: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setIsLoading: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withStyles(useStyles)(ChargeToRoomDetails);
