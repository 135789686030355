import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  ADD_USER_BTN_ADD,
  NOTIFICATION_TYPE_ERROR,
  USERS,
  DEFAULT_AUTO_DISMISS_DURATION
} from "../../constants/Constants";
import "../../styles/users-page.scss";
import Button from "@material-ui/core/Button";
import UsersList from "../lower-level/UsersList";
import { KaptynDataService } from "../../dataservices/KaptynDataService";
import "lodash";
import PropTypes from "prop-types";
import AddUserForm from "../forms/AddUserForm";

class UsersPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.groupId,
      users: [],
      openAddUserModal: false
    };

    this._retrieveUsers = this._retrieveUsers.bind(this);
    this._handleOpenAddUserModal = this._handleOpenAddUserModal.bind(this);
    this._handleCloseAddUserModal = this._handleCloseAddUserModal.bind(this);
  }

  componentDidMount() {
    this._retrieveUsers();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.groupId !== this.props.groupId) {
      this.setState({ companyId: nextProps.groupId }, this._retrieveUsers);
    }
  }

  _retrieveUsers() {
    if (this.state.companyId) {
      this.props.setIsLoading({ isLoading:true });
      const dataService = new KaptynDataService();
      let usersList = [];
      dataService.getCompanyUsers(this.state.companyId).then((users)=> {
        if (users.length > 0) {
          // TODO: change this. Map is not meant to be used this way.
          users.map((u)=> {
            usersList.push({
              userId: u.id,
              userFirstName: u.firstName ? u.firstName : '',
              userLastName: u.lastName ? u.lastName : '',
              userEmail: u.email ? u.email : '',
              userRole: u.role ? u.role : ''
            })
          });
          this.setState({users: usersList});
          this.props.setIsLoading({ isLoading:false });
        }
      }).catch((err)=> {
        this.props.setIsLoading({ isLoading:false });
        const globalNotification = {
          open: true,
          type: NOTIFICATION_TYPE_ERROR,
          content: err.message,
          autoDismissDuration: DEFAULT_AUTO_DISMISS_DURATION
        };
        this.props.setGlobalNotification({ globalNotification });
      });
    }
  }

  _handleOpenAddUserModal() {
    this.setState({ openAddUserModal:true });
  }

  _handleCloseAddUserModal() {
    this.setState({ openAddUserModal: false }, this._retrieveUsers);
  }

  render() {
    return (
      <div style={{flexGrow: 1}}>
        <Grid container>
          <Grid item xs={12} className="users-page-header">
            <Typography variant="h4" display="inline" className="users-page-h3">{ USERS }</Typography>
            <Button variant="contained" color="primary" className="users-page-add-btn" onClick={ this._handleOpenAddUserModal }>
              { ADD_USER_BTN_ADD }
            </Button>
          </Grid>
          <Grid item xs={12} className="users-list-grid-container">
            <UsersList companyId={ this.state.companyId } users={ this.state.users } setIsLoading={ this.props.setIsLoading } setGlobalNotification={ this.props.setGlobalNotification } refreshUsers={ this._retrieveUsers }/>
          </Grid>
        </Grid>
        <AddUserForm open={ this.state.openAddUserModal } companyId={ this.state.companyId } setIsLoading={ this.props.setIsLoading} setGlobalNotification={ this.props.setGlobalNotification } handleCloseAddUserModal={ this._handleCloseAddUserModal }/>
      </div>
    );
  }
}

UsersPage.propTypes = {
  groupId: PropTypes.string,
  setIsLoading: PropTypes.func.isRequired,
  setGlobalNotification: PropTypes.func.isRequired
};

export default UsersPage;
