import React from 'react';
import '../../styles/service-level-select.scss';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import DefaultCarImage from "../../images/service-level-select/cars/default-car.png";
import PremiumSedanImage from '../../images/service-level-select/cars/Premium_Sedan.png';
import PremiumSUVImage from '../../images/service-level-select/cars/Premium_SUV.png';
import LuxeSedanImage from '../../images/service-level-select/cars/Luxe_Sedan.png';
import LuxeSUVImage from '../../images/service-level-select/cars/Luxe_SUV.png';
import LuxeSUVXLImage from '../../images/service-level-select/cars/Luxe_SUV_XL.png';
import LimoSedan8Image from '../../images/service-level-select/cars/Limo_Sedan_8.png'
import LuxeVanImage from '../../images/service-level-select/cars/Luxe_Van.png';
import LimoVanImage from '../../images/service-level-select/cars/Limo_Van.png';
import LimoSUVImage from '../../images/service-level-select/cars/Limo_SUV.png';
import SpecialtyLimoPinkImage from '../../images/service-level-select/cars/Specialty_Limo_Pink.png';
import SpecialtyLimoPartyBusImage from '../../images/service-level-select/cars/Specialty_Limo_Party_Bus.png';
import ExecutiveCoachImage from '../../images/service-level-select/cars/Executive_Coach.png';
import AirportTransferImage from '../../images/service-level-select/cars/Airport_Transfer.png';
import LimoSedanImage from '../../images/service-level-select/cars/Limo_Sedan.png';
import ExecutiveSedanImage from '../../images/service-level-select/cars/Executive_Sedan.png';
import MiniCoachImage from '../../images/service-level-select/cars/Mini_Coach.png';
import MotorCoachImage from '../../images/service-level-select/cars/Motor_Coach.png';
import * as constants from "../../constants/Constants";

const INITIAL_VALUE = '';
const DEFAULT_OPTION_TEXT = '';
const DEFAULT_COLOR = 'rgba(0, 0, 0, 0.54)';
const ERROR_COLOR = '#f44336';

class ServiceLevelSelect extends React.Component {

  constructor(props) {
    super(props);
    this._handleChange = this._handleChange.bind(this);
    this._getItem = this._getItem.bind(this);
    this.state = {
      selectedValue: this.props.selectedItem || INITIAL_VALUE,
      items: this.props.items || [],
      error: this.props.error
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.error !== nextProps.error) {
      this.setState({ error: nextProps.error });
    }
    if (this.props.items !== nextProps.items) {
      this.setState({ items: nextProps.items });
    }
    if (this.props.selectedItem !== nextProps.selectedItem) {
      this.setState({ selectedValue: nextProps.selectedItem });
    }
  }

  _handleChange(event) {
    const selectedValue = event.target.value;
    this.setState({
      selectedValue
    });
    if (this.props.onChange) {
      this.props.onChange(selectedValue);
    }
  }

  _getItem(itemId) {
    const filteredItems = this.state.items.filter((item) => item.id === itemId);
    if (filteredItems.length > 0) {
      return filteredItems[0];
    }
    return null;
  }

  _getImageSource(car) {
    switch (car) {
      case constants.PREMIUM_SEDAN: return PremiumSedanImage;
      case constants.PREMIUM_SUV: return PremiumSUVImage;
      case constants.LUXE_SEDAN: return LuxeSedanImage;
      case constants.LUXE_SUV: return LuxeSUVImage;
      case constants.LUXE_SUV_XL: return LuxeSUVXLImage;
      case constants.LIMO_SEDAN_8: return LimoSedan8Image;
      case constants.LUXE_VAN: return LuxeVanImage;
      case constants.LIMO_VAN: return LimoVanImage;
      case constants.LIMO_SUV: return LimoSUVImage;
      case constants.SPECIALTY_LIMO_PINK: return SpecialtyLimoPinkImage;
      case constants.SPECIALTY_LIMO_PARTY_BUS: return SpecialtyLimoPartyBusImage;
      case constants.EXECUTIVE_COACH: return ExecutiveCoachImage;
      case constants.AIRPORT_TRANSFER: return AirportTransferImage;
      case constants.LIMO_SEDAN: return LimoSedanImage;
      case constants.EXECUTIVE_SEDAN: return ExecutiveSedanImage;
      case constants.MINI_COACH: return MiniCoachImage;
      case constants.MOTOR_COACH: return MotorCoachImage;
      default: return DefaultCarImage;
    }
  }

  _renderMenuItem(item) {
    if (!item) {
      return null;
    }
    return (
      <MenuItem value={ item.id } key={ item.id }>
        <ListItemAvatar>
          <Avatar variant="square" src={ this._getImageSource(item.imageSource) } />
        </ListItemAvatar>
        <ListItemText primary={ item.primaryText } secondary={ item.secondaryText } />
      </MenuItem>
    );
  }

  _renderSelectedValue(item) {
    if (!item) {
      return null;
    }
    return (
      <MenuItem value={ item.id } key={ item.id }>
        <ListItemText primary={ item.primaryText } />
      </MenuItem>
    );
  }

  render() {
    let options = [];
    if (this.state.items && this.state.items.length > 0) {
      this.state.items.forEach((item) => {
        let option = this._renderMenuItem(item);
        options.push(option);
      });
    }
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="service-level-select" style={{ color: this.state.error ? ERROR_COLOR : DEFAULT_COLOR }}>Vehicle Class</InputLabel>
        <Select
          className={ this.state.error ? 'MuiInput-underline-error Mui-error' : '' }
          labelId="service-level-select"
          id="service-level"
          name="serviceLevel"
          defaultValue={ this.props.selectedItem || INITIAL_VALUE }
          value={ this.state.selectedValue || INITIAL_VALUE }
          onChange={ this._handleChange }
          input={<Input id='service-level-select-input' />}
          displayEmpty={ false }
          renderValue={ () => {
            if (this.state.selectedValue === INITIAL_VALUE) {
              return DEFAULT_OPTION_TEXT;
            }
            return this._renderSelectedValue(this._getItem(this.state.selectedValue));
          } }
          fullWidth
        >
          { this.state.items.length <= 0 ? <MenuItem disabled>
            <em>We are sorry but we have no available vehicles at the requested date and time.</em>
          </MenuItem> : null }
          <MenuItem value={ INITIAL_VALUE } key="none">{ DEFAULT_OPTION_TEXT }</MenuItem>
          { options }
        </Select>
        { this.state.error && <FormHelperText style={{ color: ERROR_COLOR }}>{ this.state.error }</FormHelperText> }
      </FormControl>
    );
  }
}

ServiceLevelSelect.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func
};

export default ServiceLevelSelect;
