import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import '../../styles/new-ride-page.scss';
import "../../styles/passegers-modal.scss";
import PropTypes from "prop-types";
import * as constants from "../../constants/Constants";
import MUIPlacesAutocomplete, {geocodeBySuggestion} from 'mui-places-autocomplete';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {getCurrentDateAndTime} from "../../utils/dateUtil";
import ServiceLevelSelect from "../lower-level/ServiceLevelSelect";
import {KaptynDataService} from "../../dataservices/KaptynDataService";
import {getCurrencyFormattedNumber} from "../../utils/numberFormatUtil";
import ChargeToRoomDetails from "../lower-level/ChargeToRoomDetails";
import MapContainer from "../lower-level/Map";
import CardDetailsForm from "../forms/CardDetailsForm";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {getCountryCallingCode} from "../../utils/phoneNumberUtil";
import {hasServiceLevelExtraInputEnabled} from "../../utils/serviceLevelUtil";
import AirportDetails from "../lower-level/AirportDetails";
import {formatNumberInput} from "../../utils/rideUtil";

const AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter;
const stringUtils = require("underscore.string");
const emailValidator = require("email-validator");

const INITIAL_FLIGHT_STATE = {
  flightNumber: null,
  carrier: '',
  airline: '',
  flightStatus: null,
  flightHasErrors: false,
  flightErrors: {},
  departureAirport: '',
  arrivalAirport: '',
};
const maximumPassengers = 25;
const defaultNumberOfPassengers = 10;
const defaultPassengerOptions = 11;
// eslint-disable-next-line no-unused-vars
const useStyles = (theme) => ({
  root: {
    display: 'flex',
    background: '#f4f4f4',
    flexGrow: 1,
  },
  button: {
    borderRadius: 0,
    textTransform: 'none',
    fontSize: '15px',
    fontWeight: '500',
    padding: '12px 16px 12px 16px',
  },
  dialogButton: {
    borderRadius: 0,
    textTransform: 'none'
  }
});

export class NewRidePage extends React.Component {

  constructor(props) {
    super(props);
    this.initialDateAndTime = getCurrentDateAndTime();
    this.state = {
      passengerDetails: {
        firstName: '',
        lastName: '',
        email: '',
        countryCode: constants.DEFAULT_COUNTRY_ALPHA_CODE,
        phoneNumber: '',
        phoneType: constants.DEFAULT_PHONE_TYPE,
        passengers: 1,
        notifications: [{
          firstName: '',
          lastName: '',
          countryCode: constants.DEFAULT_COUNTRY_ALPHA_CODE,
          phoneNumber: '',
        }]
      },
      rideDetails: {
        rideType: constants.RIDE_TYPE_DESTINATION,
        rideDuration: null,
        rideDuration_m: 0,
        numberOfBags: '0',
        fromAddress: '',
        fromCoordinates: [],
        toAddress: '',
        toCoordinates: [],
        rideDate: this.initialDateAndTime.date,
        rideTime: this.initialDateAndTime.time,
        serviceLevels: [],
        selectedServiceLevel: '',
        noteForDriver: '',
        airportChecked: false,
        flight: INITIAL_FLIGHT_STATE,
        couponCode: ''
      },
      paymentDetails: {
        paymentType: constants.PAYMENT_TYPE_CREDIT,
        chargeToRoom: {
          propertyCode: '',
          propertyName: '',
          roomNumber: '',
          postingNumber: '',
          guestName: '',
          isChargeable: false,
          chargeToRoomHasErrors: false,
          chargeToRoomErrors: {}
        },
        credit: {
          cardholderName: '',
          cardNumber: '',
          expMonth: '',
          expYear: '',
          cvc: '',
          creditHasErrors: false,
          creditErrors: {}
        }
      },
      corporateAccount: this.props.corporateAccount,
      paymentTypes: [],
      formHasErrors: false,
      formErrors: {},
      discardConfirmOpen: false,
      notification: false,
      driverOnSite: false,
      guestOnBoard: false,
      guestArrived: false,
      openPassengersModal: false,
      tenantGroupId: this.props.tenantGroupId
    };
    this.dataService = new KaptynDataService();
    this._populatePaymentTypes = this._populatePaymentTypes.bind(this);
    this._isPersonalCreditCard = this._isPersonalCreditCard.bind(this);
    this._isCorporateCreditCard = this._isCorporateCreditCard.bind(this);
    this._handlePassengerDetailsChange = this._handlePassengerDetailsChange.bind(this);
    this._handlePhoneChange = this._handlePhoneChange.bind(this);
    this._handleRideDetailsChange = this._handleRideDetailsChange.bind(this);
    this._handlePickUpLocationSelected = this._handlePickUpLocationSelected.bind(this);
    this._handleDropOffLocationSelected = this._handleDropOffLocationSelected.bind(this);
    this._handleSuggestionSelected = this._handleSuggestionSelected.bind(this);
    this._getServiceLevels = this._getServiceLevels.bind(this);
    this._getMarkets = this._getMarkets.bind(this);
    this._refreshServiceLevels = this._refreshServiceLevels.bind(this);
    this._formatServiceLevels = this._formatServiceLevels.bind(this);
    this._getServiceLevelItem = this._getServiceLevelItem.bind(this);
    this._handleServiceLevelSelection = this._handleServiceLevelSelection.bind(this);
    this._handleAirportCheckboxChange = this._handleAirportCheckboxChange.bind(this);
    this._handleFlightChange = this._handleFlightChange.bind(this);
    this._validateFlight = this._validateFlight.bind(this);
    this._onDiscard = this._onDiscard.bind(this);
    this._handleDiscardCancelClick = this._handleDiscardCancelClick.bind(this);
    this._handleDiscardConfirmClick = this._handleDiscardConfirmClick.bind(this);
    this._handleCouponCodeChange = this._handleCouponCodeChange.bind(this);
    this._handlePaymentDetailsChange = this._handlePaymentDetailsChange.bind(this);
    this._handleChargeToRoomChange = this._handleChargeToRoomChange.bind(this);
    this._validateChargeToRoom = this._validateChargeToRoom.bind(this);
    this._handleCreditChange = this._handleCreditChange.bind(this);
    this._validateCredit = this._validateCredit.bind(this);
    this._validatePaymentMethod = this._validatePaymentMethod.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._handleError = this._handleError.bind(this);
    this._handleSuccess = this._handleSuccess.bind(this);
    this._formatPassengerPhone = this._formatPassengerPhone.bind(this);
    this._convertPaymentMethod = this._convertPaymentMethod.bind(this);
    this._createPaymentCard = this._createPaymentCard.bind(this);
    this._createRide = this._createRide.bind(this);
    this._onScheduleRide = this._onScheduleRide.bind(this);
    this._handleCloseCustomPassengerModal = this._handleCloseCustomPassengerModal.bind(this);
    this._submitCustomPassengerModal = this._submitCustomPassengerModal.bind(this);
    this._validateNumberOfPassengers = this._validateNumberOfPassengers.bind(this);
    this._getDefaultPassengerOptions = this._getDefaultPassengerOptions.bind(this);
    this._handleNumberOfPassengers = this._handleNumberOfPassengers.bind(this);
    this._getValidContacts = this._getValidContacts.bind(this);
  }

  componentDidMount() {
    this._populatePaymentTypes();
    this._additionalNotifications();
    this._getDefaultPassengerOptions();
  }


  // eslint-disable-next-line no-unused-vars
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.tenantGroupId !== nextProps.tenantGroupId) {
      this.setState({ tenantGroupId: nextProps.tenantGroupId });
    }
    if (this.props.corporateAccount !== nextProps.corporateAccount) {
      this.setState({ corporateAccount: nextProps.corporateAccount }, this._populatePaymentTypes);
    }
  }

  _getDefaultPassengerOptions(){
    const numberOfPassengers = constants.NUMBER_OF_PASSENGERS;
    if(numberOfPassengers.length > defaultPassengerOptions){
      const lastIem = numberOfPassengers.pop();
      numberOfPassengers[numberOfPassengers.length - 1] = lastIem;
    }
  }

  _getValidContacts(){
    return this.state.passengerDetails.notifications.filter(contact => !_.isEmpty(contact.phoneNumber));
  }

  _populatePaymentTypes() {
    if (this.state.corporateAccount) {
      let paymentTypes = [];
      const settlementMethods = this.state.corporateAccount.billing.settlementMethods;
      if (settlementMethods.card) {
        const label = this.state.corporateAccount.billing.settlementMethods.cardType === 'personal' ? 'Credit card' : 'Corporate credit card'
        paymentTypes.push({"value": constants.PAYMENT_TYPE_CREDIT, "label": label});
      }
      if (settlementMethods.cash) {
        paymentTypes.push({"value": constants.PAYMENT_TYPE_CASH, "label": "Cash"});
      }
      if (settlementMethods.account) {
        paymentTypes.push({"value": constants.PAYMENT_TYPE_ACCOUNT, "label": "Account"});
      }
      if (settlementMethods.invoice) {
        paymentTypes.push({"value": constants.PAYMENT_TYPE_INVOICE, "label": "Invoice"});
      }
      if (settlementMethods.square) {
        paymentTypes.push({"value": constants.PAYMENT_TYPE_SQUARE, "label": "Square"});
      }
      if (settlementMethods.hotelRoom) {
        paymentTypes.push({"value": constants.PAYMENT_TYPE_CHARGE_TO_ROOM, "label": "Charge to room"});
      }
      let paymentDetails = {
        ...this.state.paymentDetails,
        paymentType: paymentTypes.length > 0 ? paymentTypes[0].value : ''
      };
      this.setState({paymentTypes, paymentDetails});
    }
  }

  _additionalNotifications(){
    const maximumContacts = 3;
    const mockContact = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      countryCode: ''
    };
    const paddedContacts = [];
    _.times(maximumContacts, () => {
      const contactCopy = _.assign({}, mockContact);
      paddedContacts.push(contactCopy);
    });
    let passengerDetails = {
      ...this.state.passengerDetails,
      notifications: paddedContacts.length > 0 ? paddedContacts : ''
    };
    this.setState({ passengerDetails });
  }

  _handleCouponCodeChange(e) {
    const { name, value } = e.target;
    const rideDetails = this.state.rideDetails;
    const newCouponCode = {
      ...rideDetails,
      [name]: value
    };
    this.setState({
      rideDetails: newCouponCode
    });
  }

  _isPersonalCreditCard() {
    return this.state.paymentDetails.paymentType === constants.PAYMENT_TYPE_CREDIT && this.state.corporateAccount && this.state.corporateAccount.billing.settlementMethods.cardType === 'personal';
  }

  _isCorporateCreditCard() {
    return this.state.paymentDetails.paymentType === constants.PAYMENT_TYPE_CREDIT && this.state.corporateAccount && this.state.corporateAccount.billing.settlementMethods.cardType === 'corporate';
  }

  _handlePassengerDetailsChange(e) {
    const { name, value } = e.target;

    const currentPassengerDetails = this.state.passengerDetails;
    const newPassengerDetails = {
      ...currentPassengerDetails,
      [name]: value
    };
    this.setState({
      passengerDetails: newPassengerDetails
    }, () => {
      if (name === 'passengers') {
        this._refreshServiceLevels();
      }
    });
  }

  _handleNumberOfPassengers(e){
    const { name, value } = e.target;
    const numberOfPassengers = constants.NUMBER_OF_PASSENGERS;
    if(!value){
      this.setState({openPassengersModal: true});
    }
    if(value > defaultNumberOfPassengers && value <= maximumPassengers){
      if(numberOfPassengers.length > defaultPassengerOptions ){
        numberOfPassengers.splice(numberOfPassengers.length - 2, 1, {"value": value,"label":value});
      }else{
        const lastItem = numberOfPassengers.pop();
        numberOfPassengers.push({"value": value,"label":value});
        numberOfPassengers.push(lastItem);
      }
    }

    const currentPassengerDetails = this.state.passengerDetails;
    const newPassengerDetails = {
      ...currentPassengerDetails,
      passengers: value
    };
    this.setState({
      passengerDetails: newPassengerDetails
    }, () => {
      if (name === 'passengers') {
        this._refreshServiceLevels();
      }else{
        this._validateNumberOfPassengers();
      }
    });
  }

  _handleCloseCustomPassengerModal(){
    const currentPassengerDetails = this.state.passengerDetails;
    if(constants.NUMBER_OF_PASSENGERS.length > defaultPassengerOptions){
      constants.NUMBER_OF_PASSENGERS.splice(constants.NUMBER_OF_PASSENGERS.length - 2, 1);
    }
    const newPassengerDetails = {
      ...currentPassengerDetails,
      passengers: 1
    };
    this.setState({passengerDetails: newPassengerDetails, openPassengersModal: false, formHasErrors: false, formErrors: {} });
  }

  _submitCustomPassengerModal(){
    this._refreshServiceLevels();
    this.setState({openPassengersModal: false});
  }

  _validateNumberOfPassengers(){
    let formHasErrors = false;
    let formErrors = {};

    if (this.state.passengerDetails.passengers > maximumPassengers) {
      formHasErrors = true;
      formErrors.numberOfPassengers = constants.FORM_ERROR_NUMBER_OF_PASSENGERS;
    }
    this.setState({ formHasErrors, formErrors });
  }

  _handleNotifications = idx => e => {
    const newNotifications = this.state.passengerDetails.notifications.map((contact, index) => {
      if (idx !== index) return contact;
      if (e.target.name === 'phoneNumber'){
        const formatter = new AsYouTypeFormatter(contact.countryCode);
        let newValue = '';
        for (let i = 0; i < e.target.value.length; i++) {
          if (/\d+/.test(e.target.value.charAt(i))) {
            newValue = formatter.inputDigit(e.target.value.charAt(i));
          }
        }
        return { ...contact, [e.target.name]: newValue }
      }
      return { ...contact, [e.target.name]: e.target.value }
    });
    let passengerDetails = {
      ...this.state.passengerDetails,
      notifications: newNotifications
    };
    this.setState({ passengerDetails });
  };

  _handlePhoneChange(e) {
    const { name, value } = e.target;
    const formatter = new AsYouTypeFormatter(this.state.passengerDetails.countryCode);
    let newValue = '';
    for (let i = 0; i < value.length; i++) {
      if (/\d+/.test(value.charAt(i))) {
        newValue = formatter.inputDigit(value.charAt(i));
      }
    }
    const currentPassengerDetails = this.state.passengerDetails;
    const newPassengerDetails = {
      ...currentPassengerDetails,
      [name]: newValue
    };
    this.setState({
      passengerDetails: newPassengerDetails
    });
  }

  _handleRideDetailsChange(e) {
    const {name, value} = e.target;
    const currentRideDetails = this.state.rideDetails;
    const rideDetails = {
      ...currentRideDetails,
      [name]: value
    };
    if (name === 'rideType' && value === constants.RIDE_TYPE_HOURLY) {
      rideDetails['toAddress'] = '';
      rideDetails['toCoordinates'] = [];
    } else if (name === 'rideType' && value === constants.RIDE_TYPE_DESTINATION) {
      rideDetails['rideDuration'] = null;
    }

    this.setState({ rideDetails }, () => {
      if (name === 'rideDuration' || name === 'rideDuration_m' || name === 'rideType' || name === 'rideDate' || name === 'rideTime') {
        this._refreshServiceLevels();
      }
    });
  }

  _handlePickUpLocationSelected(suggestion) {
    this._handleSuggestionSelected(suggestion, 'pickUpLocation')
  }

  _handleDropOffLocationSelected(suggestion) {
    this._handleSuggestionSelected(suggestion, 'dropOffLocation');
  }

  _handleSuggestionSelected(suggestion, locationType) {
    this.props.setIsLoading({ isLoading: true });

    const addressField = locationType === 'pickUpLocation' ? 'fromAddress' : 'toAddress';
    const coordinatesField = locationType === 'pickUpLocation' ? 'fromCoordinates' : 'toCoordinates';

    geocodeBySuggestion(suggestion).then((results) => {
      if (results.length < 1) {
        const globalNotification = {
          open: true,
          type: constants.NOTIFICATION_TYPE_WARNING,
          content: 'Geocode request completed successfully with no results.',
          autoDismissDuration: constants.DEFAULT_AUTO_DISMISS_DURATION
        };
        this.props.setGlobalNotification({ globalNotification });
      } else {
        const { geometry, types } = results[0];
        let rideDetails = {};
        if(locationType === 'pickUpLocation'){
          rideDetails = {
            ...this.state.rideDetails,
            airportChecked: types.includes('airport'),
            [addressField]: suggestion.description,
            [coordinatesField]: [geometry.location.lat(), geometry.location.lng()]
          };
        }else{
          rideDetails = {
            ...this.state.rideDetails,
            [addressField]: suggestion.description,
            [coordinatesField]: [geometry.location.lat(), geometry.location.lng()]
          };
        }


        this.setState({ rideDetails }, this._refreshServiceLevels);
      }
      this.props.setIsLoading({ isLoading: false });
    }).catch((err) => {
      this._handleError(err.message);
      this.props.setIsLoading({ isLoading: false });
    });
  }

  _getServiceLevels(fromAddress, fromCoordinates, toAddress, toCoordinates, pickUpDate, pickUpTime, duration, airportEnabledOnly, numberOfPassengers, amountOfLuggage, market, selectedServiceLevel, isPickupDropOffDateTimeChange) {
    this.props.setIsLoading({ isLoading: true });
    this.dataService.getServiceLevels(fromAddress, fromCoordinates, toAddress, toCoordinates, pickUpDate, pickUpTime, duration, airportEnabledOnly, numberOfPassengers, amountOfLuggage, market, selectedServiceLevel, isPickupDropOffDateTimeChange).then((results) => {
      const serviceLevels = this._formatServiceLevels(results);
      const rideDetails = {
        ...this.state.rideDetails,
        serviceLevels,
        selectedServiceLevel: '',
        flight: INITIAL_FLIGHT_STATE
      };
      this.setState({ rideDetails });
      this.props.setIsLoading({ isLoading: false });
    }).catch((error) => {
      this._handleError(error.message);
      this.props.setIsLoading({ isLoading: false });
    });
  }

  _getMarkets(fromCoordinates) {
    return this.dataService.getMarkets(fromCoordinates).then((result) => {
      return result.list;
    }).catch((error) => {
      this._handleError(error.message);
    });
  }

 _refreshServiceLevels() {
    let fromAddress = this.state.rideDetails.fromAddress;
    let fromCoordinates = this.state.rideDetails.fromCoordinates;
    let toAddress = this.state.rideDetails.toAddress;
    let toCoordinates = this.state.rideDetails.toCoordinates;
    let numberOfPassengers = this.state.passengerDetails.passengers;
    let pickUpDate = this.state.rideDetails.rideDate;
    let pickUpTime = this.state.rideDetails.rideTime;
    let duration = Number(this.state.rideDetails.rideDuration) + Number(this.state.rideDetails.rideDuration_m);
    let amountOfLuggage = this.state.rideDetails.numberOfBags;
    let airportEnabledOnly = this.state.rideDetails.airportChecked;

    let needsRefresh = !!fromAddress && fromCoordinates.length > 0 && !!numberOfPassengers && !!pickUpDate && !!pickUpTime;

    if (this.state.rideDetails.rideType === constants.RIDE_TYPE_DESTINATION) {
      duration = null;
    } else {
      needsRefresh = needsRefresh && !!duration;
    }

    if (needsRefresh) {
      // TODO: optimize this in the future, so we get the markets if and only if "toCoordinates" changed.
      this._getMarkets(fromCoordinates).then((markets) => {
        const market = markets && markets.length ? markets[0]._id : null;
        const selectedServiceLevel = '';
        const isPickupDropOffDateTimeChange = false;
        this._getServiceLevels(fromAddress, fromCoordinates, toAddress, toCoordinates, pickUpDate, pickUpTime, duration, airportEnabledOnly, numberOfPassengers, amountOfLuggage, market, selectedServiceLevel, isPickupDropOffDateTimeChange);
      }).catch((error) => {
        this._handleError(error.message);
      });
    }
  }

  _formatServiceLevels(apiServiceLevels) {
    return apiServiceLevels.map((serviceLevel) => {
      return this._getServiceLevelItem(serviceLevel)
    });
  }

  _getServiceLevelItem(serviceLevel) {
    return {
      id: serviceLevel._id,
      primaryText: `${ serviceLevel.name } - ${ serviceLevel.rate ? getCurrencyFormattedNumber(serviceLevel.rate) : 'Estimated rate not available' }`,
      secondaryText: serviceLevel.description,
      imageSource: this._getServiceLevelImageSource(serviceLevel.name),
      serviceLevel,
      airport: hasServiceLevelExtraInputEnabled(serviceLevel, constants.SERVICE_LEVEL_EXTRA_INPUT_TYPE_AIRPORT_FIELD)
    }
  }

  _getServiceLevelImageSource(serviceLevelName) {
    serviceLevelName = serviceLevelName ? serviceLevelName.toLowerCase() : '';
    let imageSource = constants.DEFAULT_CAR;
    if (serviceLevelName.indexOf(constants.PREMIUM_SEDAN) > -1) {
      imageSource = constants.PREMIUM_SEDAN;
    } else if (serviceLevelName.indexOf(constants.LUXE_SEDAN) > -1) {
      imageSource = constants.LUXE_SEDAN;
    } else if (serviceLevelName.indexOf(constants.LUXE_SUV) > -1) {
      imageSource = constants.LUXE_SUV;
    } else if (serviceLevelName.indexOf(constants.LIMO_SEDAN_8) > -1) {
      imageSource = constants.LIMO_SEDAN_8;
    } else if (serviceLevelName.indexOf(constants.LUXE_VAN) > -1) {
      imageSource = constants.LUXE_VAN;
    } else if (serviceLevelName.indexOf(constants.LIMO_VAN) > -1) {
      imageSource = constants.LIMO_VAN;
    } else if (serviceLevelName.indexOf(constants.LIMO_SUV) > -1) {
      imageSource = constants.LIMO_SUV;
    } else if (serviceLevelName.indexOf(constants.SPECIALTY_LIMO_PINK) > -1) {
      imageSource = constants.SPECIALTY_LIMO_PINK;
    } else if (serviceLevelName.indexOf(constants.SPECIALTY_LIMO_PARTY_BUS) > -1) {
      imageSource = constants.SPECIALTY_LIMO_PARTY_BUS;
    } else if (serviceLevelName.indexOf(constants.EXECUTIVE_COACH) > -1) {
      imageSource = constants.EXECUTIVE_COACH;
    } else if (serviceLevelName.indexOf(constants.AIRPORT_TRANSFER) > -1) {
      imageSource = constants.AIRPORT_TRANSFER;
    } else if (serviceLevelName.indexOf(constants.LIMO_SEDAN) > -1) {
      imageSource = constants.LIMO_SEDAN;
    } else if (serviceLevelName.indexOf(constants.EXECUTIVE_SEDAN) > -1) {
      imageSource = constants.EXECUTIVE_SEDAN;
    } else if (serviceLevelName.indexOf(constants.MINI_COACH) > -1) {
      imageSource = constants.MINI_COACH;
    } else if (serviceLevelName.indexOf(constants.MOTOR_COACH) > -1) {
      imageSource = constants.MOTOR_COACH;
    }
    return imageSource;
  }

  _handleServiceLevelSelection(selectedServiceLevel) {
    const rideDetails = {
      ...this.state.rideDetails,
      selectedServiceLevel
    };
    this.setState({ rideDetails })
  }

  _handleAirportCheckboxChange(e) {
    const airportChecked = e.target.checked;
    const rideDetails = {
      ...this.state.rideDetails,
      airportChecked,
      flight: INITIAL_FLIGHT_STATE
    };
    this.setState({rideDetails});
  }

  _handleFlightChange(data) {
    const newFlight = {
      ...this.state.rideDetails.flight,
      flightNumber: data.flightNumber,
      carrier: data.carrier,
      airline: data.airline,
      flightStatus: data.flightStatus,
      departureAirport: data.departureAirport,
      arrivalAirport: data.arrivalAirport,
    };
    const newRideDetails = {
      ...this.state.rideDetails,
      flight: newFlight
    };
    this.setState({ rideDetails: newRideDetails });
  }

  _validateFlight() {
    let flightHasErrors = false;
    let flightErrors = {};

    if (this.state.rideDetails.airportChecked) {

      if (stringUtils.isBlank(this.state.rideDetails.flight.airline) && stringUtils.isBlank(this.state.rideDetails.flight.flightNumber) && stringUtils.isBlank(this.state.rideDetails.flight.flightStatus)) {
        flightHasErrors = false;
      } else{
        if(stringUtils.isBlank(this.state.rideDetails.flight.airline)){
          flightHasErrors = true;
          flightErrors.airline = constants.FORM_ERROR_REQUIRED_FIELD;
        }
        if(stringUtils.isBlank(this.state.rideDetails.flight.flightNumber)){
          flightHasErrors = true;
          flightErrors.flightNumber = constants.FORM_ERROR_REQUIRED_FIELD;
        }
        if(!stringUtils.isBlank(this.state.rideDetails.flight.flightNumber) && !stringUtils.isBlank(this.state.rideDetails.flight.airline) && stringUtils.isBlank(this.state.rideDetails.flight.flightStatus)){
          flightHasErrors = true;
          flightErrors.flightNumber = constants.FORM_ERROR_FLIGHT_NOT_SEARCHED;
        }
        if (this.state.rideDetails.flight.flightStatus === 'Error') {
          flightHasErrors = true;
          flightErrors.flightNumber = constants.FORM_ERROR_FLIGHT_NOT_FOUND;
        }
      }
    }

    const newFlight = {
      ...this.state.rideDetails.flight,
      flightHasErrors,
      flightErrors
    };

    const newRideDetails = {
      ...this.state.rideDetails,
      flight: newFlight
    };

    this.setState({ rideDetails: newRideDetails },  this._validatePaymentMethod);
  }

  _onDiscard() {
    if (!stringUtils.isBlank(this.state.passengerDetails.firstName) ||
      !stringUtils.isBlank(this.state.passengerDetails.lastName) ||
      !stringUtils.isBlank(this.state.passengerDetails.email) ||
      !stringUtils.isBlank(this.state.passengerDetails.phoneNumber) ||
      this.state.passengerDetails.countryCode !== constants.DEFAULT_COUNTRY_ALPHA_CODE ||
      this.state.passengerDetails.phoneType !== constants.DEFAULT_PHONE_TYPE ||
      !stringUtils.isBlank(this.state.rideDetails.fromAddress) ||
      !stringUtils.isBlank(this.state.rideDetails.toAddress) ||
      !stringUtils.isBlank(this.state.rideDetails.selectedServiceLevel) ||
      !stringUtils.isBlank(this.state.rideDetails.noteForDriver) ||
      this.state.rideDetails.rideType !== constants.RIDE_TYPE_DESTINATION ||
      this.state.rideDetails.rideDuration !== '1' ||
      this.state.rideDetails.numberOfBags !== '0' ||
      this.state.rideDetails.rideDate !== this.initialDateAndTime.date ||
      this.state.rideDetails.rideTime !== this.initialDateAndTime.time ||
      !stringUtils.isBlank(this.state.paymentDetails.chargeToRoom.propertyCode) ||
      !stringUtils.isBlank(this.state.paymentDetails.chargeToRoom.postingNumber) ||
      !stringUtils.isBlank(this.state.paymentDetails.chargeToRoom.roomNumber) ||
      !stringUtils.isBlank(this.state.paymentDetails.credit.cardholderName) ||
      !stringUtils.isBlank(this.state.paymentDetails.credit.cardNumber) ||
      !stringUtils.isBlank(this.state.paymentDetails.credit.expMonth) ||
      !stringUtils.isBlank(this.state.paymentDetails.credit.expYear) ||
      !stringUtils.isBlank(this.state.paymentDetails.credit.cvc)) {
      this.setState({ discardConfirmOpen: true });
    } else {
      this.props.history.push('/rides/all');
    }
  }

  _handleDiscardCancelClick() {
    this.setState({ discardConfirmOpen: false });
  }

  _handleDiscardConfirmClick() {
    this.setState({ discardConfirmOpen: false });
    this.props.history.push('/rides/all');
  }

  _renderDiscardDialog() {
    const { classes } = this.props;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={ this.state.discardConfirmOpen }
      >
        <DialogTitle id="confirmation-dialog-title">Discard ride</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to discard this ride? The changes you made will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={ this._handleDiscardCancelClick } variant="outlined" className={ classes.dialogButton }>
            Cancel
          </Button>
          <Button onClick={ this._handleDiscardConfirmClick } variant="contained" color="secondary" disableElevation className={ classes.dialogButton }>
            Yes, discard
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _handlePaymentDetailsChange(e) {
    const {name, value} = e.target;
    const currentPaymentDetails = this.state.paymentDetails;
    const paymentDetails = {
      ...currentPaymentDetails,
      [name]: value
    };
    this.setState({ paymentDetails });
  }

  _handleChargeToRoomChange(propertyCode, propertyName, roomNumber, postingNumber, guestName, isChargeable) {
    const newChargeToRoom = {
      ...this.state.paymentDetails.chargeToRoom,
      propertyCode,
      propertyName,
      roomNumber,
      postingNumber,
      guestName,
      isChargeable
    };
    const newPaymentDetails = {
      ...this.state.paymentDetails,
      chargeToRoom: newChargeToRoom
    };
    this.setState({ paymentDetails: newPaymentDetails });
  }

  _validateChargeToRoom() {
    let chargeToRoomHasErrors = false;
    let chargeToRoomErrors = {};

    if (stringUtils.isBlank(this.state.paymentDetails.chargeToRoom.propertyCode)) {
      chargeToRoomHasErrors = true;
      chargeToRoomErrors.propertyCode = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.paymentDetails.chargeToRoom.roomNumber)) {
      chargeToRoomHasErrors = true;
      chargeToRoomErrors.roomNumber = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.paymentDetails.chargeToRoom.postingNumber)) {
      chargeToRoomHasErrors = true;
      chargeToRoomErrors.postingNumber = constants.FORM_ERROR_REQUIRED_FIELD;
    } else if (!this.state.paymentDetails.chargeToRoom.isChargeable) {
      chargeToRoomHasErrors = true;
      chargeToRoomErrors.postingNumber = constants.FORM_ERROR_NOT_CHARGEABLE;
    }

    const newChargeToRoom = {
      ...this.state.paymentDetails.chargeToRoom,
      chargeToRoomHasErrors,
      chargeToRoomErrors
    };
    const newPaymentDetails = {
      ...this.state.paymentDetails,
      chargeToRoom: newChargeToRoom
    };

    this.setState({ paymentDetails: newPaymentDetails }, this._onScheduleRide);
  }

  _handleCreditChange(cardholderName, cardNumber, expMonth, expYear, cvc) {
    const newCredit = {
      ...this.state.paymentDetails.credit,
      cardholderName,
      cardNumber,
      expMonth,
      expYear,
      cvc
    };
    const newPaymentDetails = {
      ...this.state.paymentDetails,
      credit: newCredit
    };
    this.setState({ paymentDetails: newPaymentDetails });
  }

  _validateCredit() {
    let creditHasErrors = false;
    let creditErrors = {};

    if (stringUtils.isBlank(this.state.paymentDetails.credit.cardholderName)) {
      creditHasErrors = true;
      creditErrors.cardholderName = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.paymentDetails.credit.cardNumber)) {
      creditHasErrors = true;
      creditErrors.cardNumber = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.paymentDetails.credit.expMonth)) {
      creditHasErrors = true;
      creditErrors.expMonth = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.paymentDetails.credit.expYear)) {
      creditHasErrors = true;
      creditErrors.expYear = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.paymentDetails.credit.cvc)) {
      creditHasErrors = true;
      creditErrors.cvc = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    const newCredit = {
      ...this.state.paymentDetails.credit,
      creditHasErrors,
      creditErrors
    };
    const newPaymentDetails = {
      ...this.state.paymentDetails,
      credit: newCredit
    };

    this.setState({ paymentDetails: newPaymentDetails }, this._onScheduleRide);
  }

  _validatePaymentMethod() {
    if (this.state.paymentDetails.paymentType === constants.PAYMENT_TYPE_CHARGE_TO_ROOM) {
      this._validateChargeToRoom();
    } else if (this._isPersonalCreditCard()) {
      this._validateCredit();
    } else {
      this._onScheduleRide();
    }
  }

  _validateForm() {
    let formHasErrors = false;
    let formErrors = {};
    const validContacts = this._getValidContacts();

    if (stringUtils.isBlank(this.state.passengerDetails.firstName)) {
      formHasErrors = true;
      formErrors.firstName = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.passengerDetails.lastName)) {
      formHasErrors = true;
      formErrors.lastName = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.passengerDetails.email)) {
      formHasErrors = true;
      formErrors.email = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (!emailValidator.validate(this.state.passengerDetails.email)) {
      formHasErrors = true;
      formErrors.email = constants.FORM_ERROR_INVALID_EMAIL;
    }
    if (stringUtils.isBlank(this.state.passengerDetails.countryCode)) {
      formHasErrors = true;
      formErrors.countryCode = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.passengerDetails.phoneNumber)) {
      formHasErrors = true;
      formErrors.phoneNumber = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.passengerDetails.phoneType)) {
      formHasErrors = true;
      formErrors.phoneType = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.rideDetails.rideType)) {
      formHasErrors = true;
      formErrors.rideType = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (this.state.rideDetails.rideType === constants.RIDE_TYPE_HOURLY && (stringUtils.isBlank(this.state.rideDetails.rideDuration) || stringUtils.isBlank(this.state.rideDetails.rideDuration_m)) ) {
      formHasErrors = true;
      formErrors.rideDuration = constants.FORM_ERROR_REQUIRED_FIELD;
      formErrors.rideDuration_m = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.rideDetails.numberOfBags)) {
      formHasErrors = true;
      formErrors.numberOfBags = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.rideDetails.fromAddress)) {
      formHasErrors = true;
      formErrors.pickUpLocation = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (this.state.rideDetails.rideType === constants.RIDE_TYPE_DESTINATION && stringUtils.isBlank(this.state.rideDetails.toAddress)) {
      formHasErrors = true;
      formErrors.dropOffLocation = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.rideDetails.rideDate)) {
      formHasErrors = true;
      formErrors.rideDate = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.rideDetails.rideTime)) {
      formHasErrors = true;
      formErrors.rideTime = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.rideDetails.selectedServiceLevel)) {
      formHasErrors = true;
      formErrors.selectedServiceLevel = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if (this.state.passengerDetails.passengers === 0) {
      formHasErrors = true;
      formErrors.numberOfPassengers = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if(this.state.notification && validContacts.length === 0){
      formHasErrors = true;
      formErrors.notifications = constants.FORM_ERROR_REQUIRED_FIELD;
    }
    if(formHasErrors && this.state.passengerDetails.notifications.length > 0){
      this._cleanUpPhoneNumbers();
    }
    this.setState({ formHasErrors, formErrors }, this._validateFlight);
  }

  _handleError(errorMessage) {
    const globalNotification = {
      open: true,
      type: constants.NOTIFICATION_TYPE_ERROR,
      content: errorMessage,
      autoDismissDuration: constants.DEFAULT_AUTO_DISMISS_DURATION
    };
    this.props.setGlobalNotification({ globalNotification });
  }

  _handleSuccess(successMessage) {
    const globalNotification = {
      open: true,
      type: constants.NOTIFICATION_TYPE_SUCCESS,
      content: successMessage,
      autoDismissDuration: constants.DEFAULT_AUTO_DISMISS_DURATION
    };
    this.props.setGlobalNotification({ globalNotification });
  }

  _formatPassengerPhone() {
    const phoneNumber = this.state.passengerDetails.phoneNumber.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
    return `${ getCountryCallingCode(this.state.passengerDetails.countryCode) }${phoneNumber}`;
  }

  _convertPaymentMethod() {
    switch(this.state.paymentDetails.paymentType) {
      case constants.PAYMENT_TYPE_CREDIT:
        return 0;
      case constants.PAYMENT_TYPE_CASH:
        return 1;
      case constants.PAYMENT_TYPE_ACCOUNT:
        return 2;
      case constants.PAYMENT_TYPE_INVOICE:
        return 3;
      case constants.PAYMENT_TYPE_SQUARE:
        return 5;
      case constants.PAYMENT_TYPE_CHARGE_TO_ROOM:
        return 6;
      default:
        return 0;
    }
  }

  _createPaymentCard(userId) {
    // eslint-disable-next-line no-undef
    Stripe.setPublishableKey(`${ process.env.STRIPE_PUBLISHABLE_KEY }`);

    const cardPayload = {
      name: this.state.paymentDetails.credit.cardholderName,
      number: this.state.paymentDetails.credit.cardNumber,
      exp_month: this.state.paymentDetails.credit.expMonth,
      exp_year: this.state.paymentDetails.credit.expYear,
      cvc: this.state.paymentDetails.credit.cvc
    };

    // eslint-disable-next-line no-undef
    Stripe.card.createToken(cardPayload, (status, response) => {
      if (response.error) {
        this.props.setIsLoading({ isLoading: false });
        this._handleError(response.error.message);
      } else {
        // eslint-disable-next-line no-unused-vars
        this.dataService.createPaymentCard(userId, this.state.tenantGroupId, response.id).then((result) => {
          this._createRide(userId);
        }).catch((error) => {
          this.props.setIsLoading({ isLoading: false });
          this._handleError(error.message);
        })
      }
    });
  }

  _cleanUpPhoneNumbers() {
    if(this.state.passengerDetails.notifications.length > 0){
      const validContacts = this._getValidContacts();
        if(validContacts.length > 0 ){
          const phoneNumbers = validContacts.map(contact => {
            if(contact.phoneNumber){
            const newValue = contact.phoneNumber.replace(getCountryCallingCode(contact.countryCode), '');
            return { ...contact, 'phoneNumber': newValue }
          }
            return { contact }
          });
          let passengerDetails = {
            ...this.state.passengerDetails,
            notifications: phoneNumbers.length > 0 ? phoneNumbers : ''
          };
          this.setState({ passengerDetails });
        }
    }
  }

  _formatNotificationPhone() {
    if(this.state.passengerDetails.notifications.length > 0){
      const validContacts = this._getValidContacts();
      if(validContacts.length > 0 ){
        return validContacts.map((contact) => {
            if (contact.phoneNumber) {
              const phoneNumber = contact.phoneNumber.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
              const newValue = `${getCountryCallingCode(contact.countryCode)}${phoneNumber}`;
              return {...contact, 'phoneNumber': newValue}
            }
            return {contact}
          });
      } else {
        return [];
      }
    }
  }

  _createRide(passengerId) {
    this.dataService.getCurrentUserDetails().then((userDetails) => {
      let additionalPassengers = this.state.passengerDetails.passengers;
      additionalPassengers--;

      const riders = [{
        rider: passengerId,
        from: this.state.rideDetails.fromAddress,
        startLoc: this.state.rideDetails.fromCoordinates,
        to: this.state.rideDetails.toAddress,
        destLoc: this.state.rideDetails.toCoordinates,
        additionalPassengers: additionalPassengers,
        notifications: {
          contacts: this.state.notification ? this._formatNotificationPhone() : [],
          events: {
            driverArrived: this.state.driverOnSite,
            riderPickedUp: this.state.guestOnBoard,
            guestArrived: this.state.guestArrived
          }
        }
      }];
      const bookingAgent = {
        _id: userDetails.id,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phone: userDetails.phone,
        email: userDetails.email
      };

      const newRidePayload = {
        startTime: new Date(`${ this.state.rideDetails.rideDate } ${ this.state.rideDetails.rideTime }`).toISOString(),
        riders,
        passengers: this.state.passengerDetails.passengers,
        serviceLevel: this.state.rideDetails.selectedServiceLevel,
        bookingAgent,
        customerNotes: this.state.rideDetails.noteForDriver,
        paymentMethod: this._convertPaymentMethod(),
        couponCode: this.state.rideDetails.couponCode,
        group: this.state.tenantGroupId
      };

      if (!stringUtils.isBlank(this.state.rideDetails.flight.carrier) && !stringUtils.isBlank(this.state.rideDetails.flight.flightNumber)) {
        newRidePayload.extrasParsed = {
          "Airline": this.state.rideDetails.flight.carrier,
          "Flight#": this.state.rideDetails.flight.flightNumber,
          "Airport": this.state.rideDetails.flight.arrivalAirport,
        };
        newRidePayload.extras = {
          names: ['Airport', 'Airline', 'Flight#'],
          values: [this.state.rideDetails.flight.arrivalAirport, this.state.rideDetails.flight.carrier, this.state.rideDetails.flight.flightNumber]
        };
      }

      if (this.state.rideDetails.rideType === constants.RIDE_TYPE_HOURLY && (this.state.rideDetails.rideDuration || this.state.rideDetails.rideDuration_m)) {
        newRidePayload.estimatedTime =  (Number(this.state.rideDetails.rideDuration) + Number(this.state.rideDetails.rideDuration_m)) * 3600;
        newRidePayload.rideDuration = this.state.rideDetails.rideDuration;
        newRidePayload.rideDuration_m = this.state.rideDetails.rideDuration_m;
      } else {
        newRidePayload.estimatedTime = null;
        newRidePayload.rideDuration = null;
        newRidePayload.rideDuration_m = null;
      }

      if (this.state.paymentDetails.paymentType === constants.PAYMENT_TYPE_CHARGE_TO_ROOM) {
        const chargeToRoomDetails = this.state.paymentDetails.chargeToRoom;
        newRidePayload.postingNumber = chargeToRoomDetails.postingNumber;
        newRidePayload.propertyCode = chargeToRoomDetails.propertyCode;
        newRidePayload.hotelGuestName = chargeToRoomDetails.guestName;
        newRidePayload.roomNumber = chargeToRoomDetails.roomNumber;
      }

      this.dataService.createRide(newRidePayload).then((newRide) => {
        this.props.setIsLoading({ isLoading: false });
        this._handleSuccess(`Your ride ${ newRide.reservationNumber } was created successfully`);
        this.props.history.push('/rides/all');
      }).catch((error) => {
        this.props.setIsLoading({ isLoading: false });
        this._handleError(error.message);
      });
    }).catch((error) => {
      this.props.setIsLoading({ isLoading: false });
      this._handleError(error.message);
      this._cleanUpPhoneNumbers();
    });
  }

  _onScheduleRide() {
    if (!this.state.formHasErrors &&
        (this.state.paymentDetails.paymentType === constants.PAYMENT_TYPE_CASH ||
        this.state.paymentDetails.paymentType === constants.PAYMENT_TYPE_ACCOUNT ||
        this.state.paymentDetails.paymentType === constants.PAYMENT_TYPE_INVOICE ||
        this.state.paymentDetails.paymentType === constants.PAYMENT_TYPE_SQUARE ||
        (this.state.paymentDetails.paymentType === constants.PAYMENT_TYPE_CHARGE_TO_ROOM && !this.state.paymentDetails.chargeToRoom.chargeToRoomHasErrors) ||
        (this._isCorporateCreditCard() || (this._isPersonalCreditCard() && !this.state.paymentDetails.credit.creditHasErrors))) &&
       (!this.state.rideDetails.airportChecked || (this.state.rideDetails.airportChecked && !this.state.rideDetails.flight.flightHasErrors))
    ) {
      const guestUserPayload = {
        web: 1,
        firstName: this.state.passengerDetails.firstName,
        lastName: this.state.passengerDetails.lastName,
        email: this.state.passengerDetails.email,
        phone: this._formatPassengerPhone(),
        group: this.state.tenantGroupId
      };
      this.props.setIsLoading({ isLoading: true });
      this.dataService.createGuestUser(guestUserPayload).then((newUser) => {
        if (this._isPersonalCreditCard()) {
          this._createPaymentCard(newUser._id);
        } else {
          this._createRide(newUser._id);
        }
      }).catch((error) => {
        this.props.setIsLoading({ isLoading: false });
        this._handleError(error.message);
      });
    }
  }

  _createAutocompleteRequest(inputValue) {
    return {
      input: inputValue,
      types: ['geocode','establishment'],
      // eslint-disable-next-line no-undef
      location: new google.maps.LatLng(36.163218, -115.169129),
      radius: 38000
    }
  }

  render() {
    let dropOffGrid = null;
    if (this.state.rideDetails.rideType === constants.RIDE_TYPE_DESTINATION || constants.RIDE_TYPE_HOURLY) {
      dropOffGrid = <Grid item xs={12}>
        <MUIPlacesAutocomplete onSuggestionSelected={this._handleDropOffLocationSelected}
                               renderTarget={() => (<div id={'drop-off-suggestions'}/>)}
                               textFieldProps={{
                                 id: 'drop-off-location',
                                 name: 'dropOffLocation',
                                 label: 'Dropoff Address',
                                 error: !!this.state.formErrors.dropOffLocation,
                                 helperText: this.state.formErrors.dropOffLocation,
                                 fullWidth: true
                               }}
                               createAutocompleteRequest={ this._createAutocompleteRequest }
        />
      </Grid>;
    }
    let durationGrid = null;
    if (this.state.rideDetails.rideType === constants.RIDE_TYPE_HOURLY) {
      durationGrid = (<Grid item xs={12} lg={4}>
            <TextField id="ride-duration_h"
                      name="rideDuration"
                      select
                      label="Duration"
                      value={this.state.rideDetails.rideDuration}
                      onChange={this._handleRideDetailsChange}
                      error={!!this.state.formErrors.rideDuration}
                      helperText={this.state.formErrors.rideDuration}
                      fullWidth>
              {constants.RIDE_DURATIONS_H.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
      )
    }
    let paymentMethodGrid = null;
    if (this.state.paymentDetails.paymentType === constants.PAYMENT_TYPE_CHARGE_TO_ROOM) {
      paymentMethodGrid = <Grid item xs={12}>
        <ChargeToRoomDetails onChange={ this._handleChargeToRoomChange }
                             onError={ this._handleError }
                             errors={ this.state.paymentDetails.chargeToRoom.chargeToRoomErrors }
                             setIsLoading={ this.props.setIsLoading } />
      </Grid>
    } else if (this._isPersonalCreditCard()) {
      paymentMethodGrid = <Grid item xs={12}>
        <CardDetailsForm onChange={ this._handleCreditChange }
                         onError={ this._handleError }
                         errors={ this.state.paymentDetails.credit.creditErrors } />
      </Grid>
    }

    let airportDetailsGrid = null;
    if (this.state.rideDetails.airportChecked) {
      airportDetailsGrid = <Grid item xs={12}>
        <AirportDetails rideDate={ this.state.rideDetails.rideDate }
                        flightNumber={ this.state.rideDetails.flight.flightNumber }
                        carrier={ this.state.rideDetails.flight.carrier }
                        onChange={ this._handleFlightChange }
                        onError={ this._handleError }
                        errors={ this.state.rideDetails.flight.flightErrors }
                        setIsLoading={ this.props.setIsLoading }/>
      </Grid>
    }

    let notificationDetailsGrid = null;
    if(this.state.notification){
      notificationDetailsGrid =
      this.state.passengerDetails.notifications.map((contact, index)=>
        <Grid container direction='row' alignItems="stretch" key={index}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <TextField id="first-name"
                          name="firstName"
                          label="First Name"
                          value={ contact.firstName }
                          onChange={ this._handleNotifications(index) }
                          fullWidth
                          autoFocus
                          key={index} />
            </Grid>
          <Grid item xs={6} md={3}>
              <TextField id="last-name"
                          name="lastName"
                          label="Last Name"
                          value={ contact.lastName }
                          onChange={ this._handleNotifications(index) }
                          fullWidth
                          key={index} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField id="country-code"
                          name="countryCode"
                          select
                          label="Country Code"
                          value={ contact.countryCode }
                          onChange={ this._handleNotifications(index) }
                          error={ !!this.state.formErrors.notifications }
                          helperText={ this.state.formErrors.notifications }
                          fullWidth
                          key={index}>
                {constants.COUNTRY_CODES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField id="phone-number"
                          name="phoneNumber"
                          label="Phone Number"
                          value={ contact.phoneNumber }
                          onChange={ this._handleNotifications(index) }
                          error={ !!this.state.formErrors.notifications }
                          helperText={ this.state.formErrors.notifications }
                          fullWidth
                          key={index} />
          </Grid>
        </Grid>
      </Grid>
      )
    }

    let notificationCheckbox = null;
    if(this.state.notification){
      notificationCheckbox =
      <Grid container spacing={3}>
          <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.driverOnSite}
                        onChange={()=> this.setState(prevState => ({
                          driverOnSite: !prevState.driverOnSite
                        }))}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Driver on Site"
                  />
            </Grid>
          <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.guestOnBoard}
                    onChange={()=> this.setState(prevState => ({
                      guestOnBoard: !prevState.guestOnBoard
                    }))}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Guest on Board"
                />
            </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.guestArrived}
                onChange={()=> this.setState(prevState => ({
                  guestArrived: !prevState.guestArrived
                }))}
                name="checkedB"
                color="primary"
              />
            }
            label="Guest Arrived"
          />
        </Grid>
        </Grid>
    }
   // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container direction='row' alignItems="stretch">
          {/* Left panel */}
          <Grid item className={ 'left-column' } xs={12} lg={5}>
            <Grid container spacing={3}>
              {/* Title */}
              <Grid item xs={12}>
                <Typography variant="h4" className={ 'title' }>New Ride</Typography>
              </Grid>
              {/* First name */}
              <Grid item xs={12} md={6}>
                <TextField id="first-name"
                           name="firstName"
                           label="First Name"
                           value={ this.state.passengerDetails.firstName }
                           onChange={ this._handlePassengerDetailsChange }
                           error={ !!this.state.formErrors.firstName }
                           helperText={ this.state.formErrors.firstName }
                           fullWidth
                           autoFocus />
              </Grid>
              {/* Last name */}
              <Grid item xs={12} md={6}>
                <TextField id="last-name"
                           name="lastName"
                           label="Last Name"
                           value={ this.state.passengerDetails.lastName }
                           onChange={ this._handlePassengerDetailsChange }
                           error={ !!this.state.formErrors.lastName }
                           helperText={ this.state.formErrors.lastName }
                           fullWidth />
              </Grid>
              {/* Email */}
              <Grid item xs={12}>
                <TextField id="email"
                           name="email"
                           label="Email Address"
                           value={ this.state.passengerDetails.email }
                           onChange={ this._handlePassengerDetailsChange }
                           error={ !!this.state.formErrors.email }
                           helperText={ this.state.formErrors.email }
                           fullWidth />
              </Grid>
              {/* Phone number */}
              <Grid item xs={12} lg={4}>
                <TextField id="country-code"
                           name="countryCode"
                           select
                           label="Country Code"
                           value={ this.state.passengerDetails.countryCode }
                           onChange={ this._handlePassengerDetailsChange }
                           error={ !!this.state.formErrors.countryCode }
                           helperText={ this.state.formErrors.countryCode }
                           fullWidth>
                  {constants.COUNTRY_CODES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} lg={5}>
                <TextField id="phone-number"
                           name="phoneNumber"
                           label="Phone Number"
                           value={ this.state.passengerDetails.phoneNumber }
                           onChange={ this._handlePhoneChange }
                           error={ !!this.state.formErrors.phoneNumber }
                           helperText={ this.state.formErrors.phoneNumber }
                           fullWidth />
              </Grid>
              <Grid item xs={12} lg={3}>
                <TextField id="phone-type"
                           name="phoneType"
                           select
                           label="Phone Type"
                           value={ this.state.passengerDetails.phoneType }
                           onChange={ this._handlePassengerDetailsChange }
                           error={ !!this.state.formErrors.phoneType }
                           helperText={ this.state.formErrors.phoneType }
                           fullWidth>
                  {constants.PHONE_TYPES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Ride Type */}
              <Grid item xs={12} lg={4}>
                <TextField id="ride-type"
                           name="rideType"
                           select
                           label="Ride Type"
                           value={ this.state.rideDetails.rideType }
                           onChange={ this._handleRideDetailsChange }
                           error={ !!this.state.formErrors.rideType }
                           helperText={ this.state.formErrors.rideType }
                           fullWidth>
                  {constants.RIDE_TYPES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Duration */}
              { durationGrid }
              { this.state.rideDetails.rideType === constants.RIDE_TYPE_HOURLY ? <Grid item xs={12} lg={4}>
                  <TextField  id="ride-duration_m"
                              name="rideDuration_m"
                              select
                              label=" "
                              value={this.state.rideDetails.rideDuration_m}
                              onChange={this._handleRideDetailsChange}
                              error={!!this.state.formErrors.rideDuration_m}
                              helperText={this.state.formErrors.rideDuration_m}
                              fullWidth>
                    {constants.RIDE_DURATIONS_M.map( option => <MenuItem key={option.value} value={option.value}> {option.label} </MenuItem> )}
                </TextField>
              </Grid> : null
              }
              {/* Number of passengers*/}
              <Grid item xs={12} lg={4}>
                <TextField id="number-of-passengers"
                           name="passengers"
                           select
                           label="Total Passengers"
                           value={ this.state.passengerDetails.passengers }
                           onChange={ this._handleNumberOfPassengers }
                           error={ !!this.state.formErrors.numberOfPassengers }
                           helperText={ this.state.formErrors.numberOfPassengers }
                           fullWidth>
                  {constants.NUMBER_OF_PASSENGERS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Dialog open={this.state.openPassengersModal} maxWidth="xs">
                  <DialogTitle className="passenger-modal-title">Number of passengers</DialogTitle>
                  <DialogContent className="passenger-modal-content">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <TextField id="custom-passengers"
                                   name="custom-passengers"
                                   className="passenger-modal-number-of-passengers"
                                   label="Total Passengers"
                                   type="number"
                                   onKeyDown={ formatNumberInput }
                                   value={ this.state.passengerDetails.passengers }
                                   onChange={ this._handleNumberOfPassengers }
                                   error={ !!this.state.formErrors.numberOfPassengers }
                                   helperText={ this.state.formErrors.numberOfPassengers }
                                   fullWidth
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <div className="passenger-modal-actions">
                      <Button onClick={this._handleCloseCustomPassengerModal} className="passenger-modal-cancel-button">
                        Cancel
                      </Button>
                      <Button onClick={this._submitCustomPassengerModal} disabled={this.state.passengerDetails.passengers > maximumPassengers} color="primary" className="passenger-modal-submit-button">
                        Save
                      </Button>
                    </div>
                  </DialogActions>
                </Dialog>
              </Grid>
              {/* Number of bags */}
              <Grid item xs={12} lg={4}>
                <TextField id="number-of-bags"
                           name="numberOfBags"
                           select
                           label="Number of Bags"
                           value={ this.state.rideDetails.numberOfBags }
                           onChange={ this._handleRideDetailsChange }
                           error={ !!this.state.formErrors.numberOfBags }
                           helperText={ this.state.formErrors.numberOfBags }
                           fullWidth>
                  {constants.NUMBER_OF_BAGS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Pick up location */}
              <Grid item xs={12}>
                <MUIPlacesAutocomplete onSuggestionSelected={ this._handlePickUpLocationSelected }
                                       renderTarget={ () => (<div id={ 'pick-up-suggestions' } />) }
                                       textFieldProps={ {
                                         id: 'pick-up-location',
                                         name: 'pickUpLocation',
                                         label: 'Pickup Address',
                                         error: !!this.state.formErrors.pickUpLocation,
                                         helperText: this.state.formErrors.pickUpLocation,
                                         fullWidth: true
                                       } }
                                       createAutocompleteRequest={ this._createAutocompleteRequest }
                />
              </Grid>
              {/* Drop off location */}
              { dropOffGrid }
              {/* Date */}
              <Grid item xs={12} lg={6}>
                <TextField id="ride-date"
                           name="rideDate"
                           label="Date"
                           type="date"
                           defaultValue={ this.state.rideDetails.rideDate }
                           InputLabelProps={{
                             shrink: true,
                           }}
                           onChange={ this._handleRideDetailsChange }
                           error={ !!this.state.formErrors.rideDate }
                           helperText={ this.state.formErrors.rideDate }
                           fullWidth
                />
              </Grid>
              {/* Time */}
              <Grid item xs={12} lg={6}>
                <TextField id="ride-time"
                           name="rideTime"
                           label="Time"
                           type="time"
                           defaultValue={ this.state.rideDetails.rideTime }
                           InputLabelProps={{
                             shrink: true,
                           }}
                           inputProps={{
                             step: 300, // 5 minutes
                           }}
                           onChange={ this._handleRideDetailsChange }
                           error={ !!this.state.formErrors.rideTime }
                           helperText={ this.state.formErrors.rideTime }
                           fullWidth
                />
              </Grid>
              {/* Service level */}
              <Grid item xs={12}>
                <ServiceLevelSelect items={ this.state.rideDetails.airportChecked ? this.state.rideDetails.serviceLevels.filter(item => item.airport === true)
                : this.state.rideDetails.serviceLevels }
                                    onChange={ this._handleServiceLevelSelection }
                                    error={ this.state.formErrors.selectedServiceLevel }/>
              </Grid>
              {/* Airport Details */}
              { airportDetailsGrid }
              {/* Notification Details */}
              <Grid item xs={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.notification}
                      onChange={()=> this.setState(prevState => ({
                        notification: !prevState.notification
                      }))}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Notifications"
                />
              </Grid>
              {/* Notification Details */}
              {notificationDetailsGrid}
              {notificationCheckbox}
              {/* Notes for driver */}
              <Grid item xs={12}>
                <TextField id="note-for-driver"
                           name="noteForDriver"
                           label="Note for driver (optional)"
                           multiline
                           rows={4}
                           value={ this.state.rideDetails.noteForDriver }
                           onChange={ this._handleRideDetailsChange }
                           fullWidth
                />
              </Grid>
              {/* Coupon Codes */}
              <Grid item xs={12}>
                <TextField id="couponCode"
                           name="couponCode"
                           label="Coupon Code"
                           value={ this.state.rideDetails.couponCode }
                           onChange={ this._handleCouponCodeChange }
                           fullWidth />
              </Grid>
              {/* Payment Type */}
              <Grid item xs={12}>
                <TextField id="payment-type"
                           name="paymentType"
                           select
                           label="Payment Type"
                           value={ this.state.paymentDetails.paymentType }
                           onChange={ this._handlePaymentDetailsChange }
                           error={ !!this.state.formErrors.paymentType }
                           helperText={ this.state.formErrors.paymentType }
                           fullWidth>
                  {this.state.paymentTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Payment method*/ }
              { paymentMethodGrid }
              {/* Disclaimer text */}
              <Grid item xs={12}>
                <Typography variant="body2" className={ 'item-text' }>{ constants.DISCLAIMER_TEXT }</Typography>
              </Grid>
              {/* Buttons */}
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={3} style={{ flexBasis: 0 }}>
                    <Button onClick={ this._onDiscard }
                            variant="outlined"
                            className={ classes.button }>
                      Discard
                    </Button>
                  </Grid>
                  <Grid item xs={7}>
                    <Button onClick={ this._validateForm }
                            variant="contained"
                            color="primary"
                            disableElevation
                            className={ clsx(classes.button, 'schedule-ride') }
                            endIcon={ <ArrowForwardIcon /> }>
                      Schedule Ride
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Right panel */}
          <Grid item className={ 'right-column' } xs={12} lg={7}>
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <MapContainer rideDetails={ this.state.rideDetails }
                            onError={ this._handleError }/>
            </div>
          </Grid>
        </Grid>
        { this._renderDiscardDialog() }
      </div>
    );
  }
}

NewRidePage.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object,
  currentGroupId: PropTypes.string,
  tenantGroupId: PropTypes.string,
  availableAffiliates: PropTypes.array.isRequired,
  corporateAccount: PropTypes.object,
  setIsLoading: PropTypes.func.isRequired,
  setGlobalNotification: PropTypes.func.isRequired
};

export default withStyles(useStyles)(NewRidePage);
