export function setCookies(userId, token, email, firstName, lastName, phoneNumber, rememberMe, refreshToken, isAdmin, groupId) {
  _setCookie('userId', userId, 90);
  _setCookie('token', token, 90);
  _setCookie('refreshToken', refreshToken,90);
  _setCookie('email', email, 90);
  _setCookie('firstName', firstName, 90);
  _setCookie('lastName', lastName, 90);
  _setCookie('phoneNumber', phoneNumber,90);
  _setCookie('rememberMe', rememberMe,90);
  _setCookie('isAdmin', isAdmin, 90);
  if (groupId) {
    _setCookie('groupId', groupId, 90);
  }
}

export function destroyCookies(keepCredentialInf) {
  _setCookie('userId', '', 0);
  _setCookie('token','',0);
  _setCookie('refreshToken','',0);
  _setCookie('firstName','',0);
  _setCookie('lastName','',0);
  _setCookie('phoneNumber','',0);
  _setCookie('isAdmin', '', 0);
  _setCookie('groupId', '', 0);
  if (!keepCredentialInf) {
    _setCookie('rememberMe','',0);
    _setCookie('email','',0);
  }
}

export function getUserId() {
  return _getCookie('userId') || null;
}

export function getToken() {
  return _getCookie('token') || null;
}

export function getRefreshToken() {
  return _getCookie('refreshToken') || null;
}

export function getEmail() {
  return _getCookie('email') || null;
}

export function getFirstName() {
  return _getCookie('firstName') || null;
}

export function getLastName() {
  return _getCookie('lastName') || null;
}

export function getGroupId() {
  return _getCookie('groupId') || null;
}

export function setGroupId(groupId) {
  _setCookie('groupId', groupId, 90);
}

export function getFullName() {
  const firstName = _getCookie('firstName');
  const lastName = _getCookie('lastName');
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return null;
}

export function isRememberMeChecked() {
  return _getCookie('rememberMe') || null;
}

export function getPhoneNumber() {
  return _getCookie('phoneNumber') || null;
}

export function isAdmin() {
  return _getCookie('isAdmin') === 'true';
}

export function hasActiveSession() {
  return getToken() !== null;
}

export function getEmailAccordingRememberMeFlag() {
  const email = getEmail();
  if (isRememberMeChecked() !== 'true') {
    return ''
  }
  return typeof email === 'string' ? email : ''
}

// eslint-disable-next-line no-unused-vars
export function _setCookie(name, value, days) {
  const d = new Date();
  d.setTime(d.getTime() + (days*24*60*60*1000));
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function _getCookie(name) {
  if (name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  else {
    return ''
  }
}
