import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';
import PropTypes from "prop-types";

const timeFormatList = [
  { value: "12 Hours", id: 'false' },
  { value: "24 Hours", id: 'true' },
];

export default function TimeFormatSelect(props) {
  // eslint-disable-next-line no-unused-vars
  const { name, label, value, error=null, onChange, options, style, fullWidth } = props;
  let valueResult = '';
  (value === null) ? valueResult = '' : valueResult = value;
  return (
      <FormControl
      {...(error && {error:true})}>
          <InputLabel>{label}</InputLabel>
          <MuiSelect
              label={label}
              name={name}
              fullWidth
              style={style}
              value={valueResult}
              autoWidth={false}
              onChange={onChange}>
              <MenuItem value="" />
              {
                timeFormatList.map(
                  item => (<MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>)
                )
              }
          </MuiSelect>
          {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
  )
}

TimeFormatSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.object,
  fullWidth: PropTypes.bool
};
