import React from 'react';
import UsersListItem from "./UsersListItem";
import PropTypes from 'prop-types';
import Container from "@material-ui/core/Container";
import "../../styles/users-list.scss"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { NO_USERS_FOUND } from "../../constants/Constants";

class UsersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: props.users
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({ users: nextProps.users });
    }
  }

  render() {
    if (this.state.users && this.state.users.length === 0) {
      return(
        <Grid container className="users-list-main-container">
          <Grid item xs={12}>
            <Typography className="users-list-no-users-found-message" variant="h4" align="center" color="textSecondary">{ NO_USERS_FOUND }</Typography>
          </Grid>
        </Grid>
      )
    }
    return(<div className="root list-scroll">
      <Grid container className="users-list-main-container">
          <Grid item xs={12}>
            { this.state.users.map((e)=>{
              return (
                <Container key={e.userId} maxWidth="xl" className="users-list-parent">
                  <Grid>
                    <Grid item xs={12} className="users-list-item">
                      <UsersListItem companyId={ this.props.companyId } userId={ e.userId }  userFirstName={ e.userFirstName } userLastName={ e.userLastName } userEmail={ e.userEmail } userRole={ e.userRole } setIsLoading={ this.props.setIsLoading } setGlobalNotification={ this.props.setGlobalNotification } refreshUsers={ this.props.refreshUsers }/>
                    </Grid>
                  </Grid>
                </Container>
              )
            })
            }
          </Grid>
      </Grid>
    </div>);
  }
}

UsersList.propTypes = {
  companyId: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setGlobalNotification: PropTypes.func.isRequired,
  refreshUsers: PropTypes.func.isRequired
};

export default UsersList;
