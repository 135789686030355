import React from 'react';
import { Route, Redirect, Switch } from "react-router-dom";
import ConciergeContainer from '../../containers/ConciergeContainer';
import AllRidesPage from "./AllRidesPage";
import BookedRidesPage from "./BookedRidesPage";
import InProgressRidesPage from "./InProgressRidesPage";
import CancelledRidesPage from "./CancelledRidesPage";
import CompletedRidesPage from "./CompletedRidesPage";
import UsersPage from "./UsersPage";
import CompanyDetailsPage from "./CompanyDetailsPage";
// eslint-disable-next-line import/no-named-as-default
import NewRidePage  from "./NewRidePage";
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import '../../styles/concierge-page.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import MainMenu from "../lower-level/MainMenu";
import PropTypes from "prop-types";
import ProfileMenu from "../lower-level/ProfileMenu";
import { isAdmin, hasActiveSession, destroyCookies, getUserId, getRefreshToken, getToken, getGroupId, setGroupId, setCookies, _setCookie } from "../../utils/cookieUtil";
import ProgressSpinner from "../lower-level/ProgressSpinner";
import {KaptynDataService} from "../../dataservices/KaptynDataService";
import Notification from "../lower-level/Notification";
import GroupSwitcher from '../lower-level/GroupSwitcher';
import * as constants from "../../constants/Constants";
import NoLogoImage from "../../images/logos/no-logo.png";
import IconRidesDefault from '../../icons/main-menu/icon-rides-default.svg';
import IconSettingsDefault from '../../icons/main-menu/icon-settings-default.svg';
import NotFoundPage from "./NotFoundPage";
import EditRidePage from "./EditRidePage";

let stringUtils = require("underscore.string");

const RideIcon = () => <img width="24" height="24" src={ IconRidesDefault } alt="rides" />;
const SettingIcon = () => <img width="24" height="24" src={ IconSettingsDefault } alt="settings" />;

const ridesItems = {
  name: "rides",
  label: "Rides",
  path: "/rides/all",
  Icon: RideIcon,
  items: [
    { name: "all", label: "All", path: "/rides/all" },
    { name: "booked", label: "Booked", path: "/rides/booked" },
    { name: "inProgress", label: "In Progress", path: "/rides/inProgress" },
    { name: "completed", label: "Completed", path: "/rides/completed" },
    { name: "cancelled", label: "Cancelled", path: "/rides/cancelled" }
  ]
};

const settingsItems = {
  name: "settings",
  label: "Settings",
  path: "/settings/users",
  Icon: SettingIcon,
  items: [
    { name: "users", label: "Users", path: "/settings/users" },
    { name: "companyDetails", label: "Company Details", path: "/settings/company" }
  ]
};

const dividerItem = "divider";
const drawerWidth = 256;

const useStyles = (theme) => ({
  root: {
    display: 'flex',
    background:'#f4f4f4',
    height: '100vh',
    overflow: 'hidden',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: '#FFFFFF',
    color: '#000000',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRight: 0
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
  container: {
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  plusButton: {
    height:'100px',
    backgroundColor:'red'
  },
  conciergePageContainer: {
    margin:'28px'
  }
});

export class DisplayConciergePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: window.screen.width > 767,
      notification: {
        open: false,
        type: constants.NOTIFICATION_TYPE_INFO,
        content: '',
        autoDismissDuration: null
      },
      groups: [],
      currentUser: this.props.currentUser,
      currentGroupId: this.props.currentGroupId,
      corporateAccount: this.props.corporateAccount,
      conciergeGroup: this.props.conciergeGroup,
      currentGroupLogo: this.props.currentGroupLogo,
      sideBarItems: [],
      preConditionsReady: false
    };
    this._handleDrawerOpen = this._handleDrawerOpen.bind(this);
    this._handleDrawerClose = this._handleDrawerClose.bind(this);
    this._logout = this._logout.bind(this);
    this._newRide = this._newRide.bind(this);
    this._loadConciergeData = this._loadConciergeData.bind(this);
    this._handleError = this._handleError.bind(this);
    this._changeGroup = this._changeGroup.bind(this);
    this._refreshSession = this._refreshSession.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = {};
    if (this.props.globalNotification !== nextProps.globalNotification) {
      newState.notification = {
        open: nextProps.globalNotification.open,
        type: nextProps.globalNotification.type,
        content: nextProps.globalNotification.content,
        autoDismissDuration: nextProps.globalNotification.autoDismissDuration
      };
    }
    if (this.props.currentUser !== nextProps.currentUser) {
      newState.currentUser = nextProps.currentUser;
    }
    if (this.props.currentGroupId !== nextProps.currentGroupId) {
      newState.currentGroupId = nextProps.currentGroupId;
    }
    if (this.props.currentGroupLogo !== nextProps.currentGroupLogo) {
      newState.currentGroupLogo = nextProps.currentGroupLogo;
    }
    if (this.props.corporateAccount !== nextProps.corporateAccount) {
      newState.corporateAccount = nextProps.corporateAccount;
    }
    if (this.props.conciergeGroup !== nextProps.conciergeGroup) {
      newState.conciergeGroup = nextProps.conciergeGroup;
    }
    this.setState(newState);
  }

  componentDidMount() {
    if (hasActiveSession()) {
      this.setState({sideBarItems: isAdmin() ? [
          ridesItems,
          dividerItem,
          settingsItems,
          dividerItem
        ] : [
          ridesItems,
          dividerItem
        ]});
      this._loadConciergeData();
    } else {
     this.props.history.push('/login');
    }
  }

  _handleDrawerOpen() {
    this.setState({ drawerOpen: true });
  }

  _handleDrawerClose() {
    this.setState({ drawerOpen: false });
  }

  _newRide() {
    this.props.history.push('/rides/new');
  }

  _loadConciergeData() {
    const groupId = getGroupId();
    this.props.setIsLoading({ isLoading: true });
    const dataService = new KaptynDataService();
    let conciergeGroupId;
    dataService.getUserGroups().then((availableGroups) => {
      this.props.setAvailableGroups({ availableGroups: availableGroups.map((group) => {
          return { id: group._id, name: group.name, accountType: group.account_type }
        })
      });
      const conciergeGroups = availableGroups.filter(group => group.account_type === 'concierge');
      this.setState({groups : conciergeGroups});
      if (groupId) {
        conciergeGroupId = groupId;
        this.props.setCurrentGroupId({ currentGroupId: groupId });
        this.setState({ currentGroupId: groupId });
      } else {
        for (let group of availableGroups) {
          // NOTE: Set the currentGroupId as the first concierge group found (for now).
          if (group.account_type === 'concierge') {
            setGroupId(group._id);
            conciergeGroupId = group._id;
            this.props.setCurrentGroupId({ currentGroupId: group._id });
            this.setState({ currentGroupId: group._id });
            break;
          }
        }
      }

      if (conciergeGroupId) {
        dataService.getConciergeGroup(conciergeGroupId).then((conciergeGroup) => {
          this.props.setConciergeGroup({ conciergeGroup: {
              conciergeGroupId: conciergeGroup.concierge_group,
              parentGroupId: conciergeGroup.parent_group,
              corporateAccountId: conciergeGroup.corporate_account}
          });

          dataService.getConciergeAccount(conciergeGroup.corporate_account).then((corporateAccount) => {
            this.props.setCorporateAccount({ corporateAccount });
            dataService.getAffiliates().then((affiliates) => {
              this.props.setAvailableAffiliates({ availableAffiliates: affiliates.list });
              dataService.getGroupDetails(conciergeGroupId).then((groupDetails) => {
                if (groupDetails && groupDetails.settings && groupDetails.settings.email_banner && !stringUtils.isBlank(groupDetails.settings.email_banner)) {
                  this.props.setCurrentGroupLogo({ currentGroupLogo: groupDetails.settings.email_banner });
                }
                dataService.getUserDetails(getUserId()).then((userDetailResult) => {
                  this.props.setCurrentUser( { currentUser: {
                      firstName: userDetailResult.firstName,
                      lastName: userDetailResult.lastName,
                      timeZone: userDetailResult.timezone
                    }});
                  this.props.setIsLoading({ isLoading: false });
                  this.setState({ preConditionsReady: true });
                }).catch((error) => {
                  this._handleError(error.message);
                });
              }).catch((error) => {
                this._handleError(error.message);
              });
            }).catch((error) => {
              this._handleError(error.message);
              this._logout();
            });
          }).catch((error) => {
            this._handleError(error.message);
            this._logout();
          });
        }).catch((error) => {
          this._handleError(error.message);
          this._logout();
        });
      } else {
        this._handleError('No concierge group available for the current user.');
        this._logout();
      }
    }).catch((error) => {
      this.props.setIsLoading({ isLoading: false });
      if (error.message === 'Unauthorized') {
        destroyCookies();
        this.props.history.push('/login');
      } else {
        this._handleError(error.message);
      }
    });
  }

  _logout() {
    const dataService = new KaptynDataService();
    this.props.setIsLoading({ isLoading: true });
    dataService.logout().then((result) => {
      if (result && result.success) {
        destroyCookies(true);
        this.props.setIsLoading({ isLoading: false });
        this.props.history.push('/login');
      } else {
        this._handleError('An unexpected error occurred while logging out.');
        this.props.setIsLoading({ isLoading: false });
      }
    }).catch((error) => {
      this._handleError(error.message);
      this.props.setIsLoading({ isLoading: false });
    });
  }

  _handleError(errorMessage) {
    const globalNotification = {
      open: true,
      type: constants.NOTIFICATION_TYPE_ERROR,
      content: errorMessage,
      autoDismissDuration: constants.DEFAULT_AUTO_DISMISS_DURATION
    };
    this.props.setGlobalNotification({ globalNotification });
  }

  _changeGroup(groupId){
    _setCookie('groupId', groupId, 90);
    this._refreshSession().then(() => window.location.reload()).catch((error) => {
      this._handleError(error.message);
    });
  }

  _refreshSession(){
    const dataService = new KaptynDataService();
    const refreshPayload = { group: getGroupId(), refreshToken: getRefreshToken(), token: getToken() };
    return dataService.refreshSession(refreshPayload).then((refreshResult) => {
      const userRoles = refreshResult.permissions.userRoles;
      const isAdmin = !!(refreshResult.permissions && userRoles && userRoles.length > 0 && (userRoles.includes('administrator') || userRoles.includes('superadmin') || userRoles.includes('owner')));
      setCookies(
        refreshResult.user.id,
        refreshResult.token,
        refreshResult.user.email,
        refreshResult.user.firstName,
        refreshResult.user.lastName,
        '',
        false,
        refreshResult.refreshToken,
        isAdmin.toString());
      this.props.history.push('/rides/all');
    });
  }

  render() {
    const { classes } = this.props;
    let companyLogoDiv = null;
    if (!this.state.drawerOpen) {
      companyLogoDiv = <div className='company-logo'>
        <img width='100px' height='50px' src={ this.state.currentGroupLogo ? this.state.currentGroupLogo : NoLogoImage }/>
      </div>;
    }
    let mainContent = null;
    if (this.state.preConditionsReady) {
      mainContent = <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={ false } style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Grid container>
            <Switch>
              <Redirect exact from='/' to='/rides/all' />
              <Route exact path="/rides/all" render={(props) => (<AllRidesPage {...props}
                                                                               history={ this.props.history }
                                                                               setIsLoading={ this.props.setIsLoading }
                                                                               tenantGroupId={ this.state.conciergeGroup.parentGroupId }
                                                                               setGlobalNotification={ this.props.setGlobalNotification } />)}  />
              <Route exact path="/rides/booked" render={(props) => (<BookedRidesPage {...props}
                                                                                     history={ this.props.history }
                                                                                     setIsLoading={ this.props.setIsLoading }
                                                                                     tenantGroupId={ this.state.conciergeGroup.parentGroupId }
                                                                                     setGlobalNotification={ this.props.setGlobalNotification } />)}  />
              <Route exact path="/rides/inProgress" render={(props) => (<InProgressRidesPage {...props}
                                                                                             history={ this.props.history }
                                                                                             setIsLoading={ this.props.setIsLoading }
                                                                                             tenantGroupId={ this.state.conciergeGroup.parentGroupId }
                                                                                             setGlobalNotification={ this.props.setGlobalNotification } />)}  />
              <Route exact path="/rides/cancelled" render={(props) => (<CancelledRidesPage {...props}
                                                                                           history={ this.props.history }
                                                                                           setIsLoading={ this.props.setIsLoading }
                                                                                           tenantGroupId={ this.state.conciergeGroup.parentGroupId }
                                                                                           setGlobalNotification={ this.props.setGlobalNotification } />)}  />
              <Route exact path="/rides/completed" render={(props) => (<CompletedRidesPage {...props}
                                                                                           history={ this.props.history }
                                                                                           setIsLoading={ this.props.setIsLoading }
                                                                                           tenantGroupId={ this.state.conciergeGroup.parentGroupId }
                                                                                           setGlobalNotification={ this.props.setGlobalNotification } />)}  />
              <Route exact path="/rides/new" render={(props) => (<NewRidePage {...props}
                                                                              currentGroupId={ this.state.currentGroupId }
                                                                              availableAffiliates={ this.props.availableAffiliates }
                                                                              corporateAccount={ this.state.corporateAccount }
                                                                              tenantGroupId={ this.state.conciergeGroup.parentGroupId }
                                                                              setIsLoading={ this.props.setIsLoading }
                                                                              setGlobalNotification={ this.props.setGlobalNotification } />)} />
              <Route exact path="/rides/:rideId" render={(props) => (<EditRidePage {...props}
                                                                                   currentGroupId={ this.state.currentGroupId }
                                                                                   availableAffiliates={ this.props.availableAffiliates }
                                                                                   corporateAccount={ this.state.corporateAccount }
                                                                                   tenantGroupId={ this.state.conciergeGroup.parentGroupId }
                                                                                   setIsLoading={ this.props.setIsLoading }
                                                                                   setGlobalNotification={ this.props.setGlobalNotification } />)} />
              <Route exact path="/settings/company" render={(props) => (isAdmin() ? <CompanyDetailsPage {...props}
                                                                                                        groupId={ this.state.currentGroupId }
                                                                                                        setCurrentGroupLogo={ this.props.setCurrentGroupLogo }
                                                                                                        setIsLoading={ this.props.setIsLoading }
                                                                                                        setGlobalNotification={ this.props.setGlobalNotification } /> : <Redirect to="/rides/all" />)}  />
              <Route exact path="/settings/users" render={(props) => (isAdmin() ? <UsersPage {...props}
                                                                                             groupId={ this.state.currentGroupId }
                                                                                             setIsLoading={ this.props.setIsLoading }
                                                                                             setGlobalNotification={ this.props.setGlobalNotification } /> : <Redirect to="/rides/all" />)} />
              <Route component={ NotFoundPage } />
            </Switch>
          </Grid>
        </Container>
      </main>;
    }
    return (
      <div className={classes.root}>
        <Notification open={ this.state.notification.open }
                      type={ this.state.notification.type }
                      content={ this.state.notification.content }
                      autoDismissDuration={ this.state.notification.autoDismissDuration }
                      setGlobalNotification={ this.props.setGlobalNotification } />
        <ProgressSpinner open={ this.props.isLoading } />
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, this.state.drawerOpen && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={ this._handleDrawerOpen }
              className={clsx(classes.menuButton, this.state.drawerOpen && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <GroupSwitcher
              items={this.state.groups}
              selectedItem={this.state.currentGroupId}
              onChange={ this._changeGroup }
              drawerOpen={this.state.drawerOpen}
            />
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              &nbsp;
            </Typography>
            { companyLogoDiv }
            <ProfileMenu userId={ getUserId() }
                         userFullName={ (this.state.currentUser.firstName && this.state.currentUser.lastName) ? `${ this.state.currentUser.firstName } ${ this.state.currentUser.lastName }` : '...' }
                         onOpenProfile={ () => {} }
                         onLogout={ this._logout }
                         setCurrentUser={ this.props.setCurrentUser }
                         setIsLoading={ this.props.setIsLoading }
                         setGlobalNotification={ this.props.setGlobalNotification }
                         history={ this.props.history }
            />

          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !this.state.drawerOpen && classes.drawerPaperClose),
          }}
          open={ this.state.drawerOpen }
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={ this._handleDrawerClose } style={{position:'absolute',left:'17px',color:'black'}}>
              <CloseIcon />
            </IconButton>
            <div className='company-logo'>
              <img width='100px' height='50px' src={ this.state.currentGroupLogo ? this.state.currentGroupLogo : NoLogoImage }/>
            </div>
          </div>
          <MainMenu items={ this.state.sideBarItems } expanded={ this.state.drawerOpen } onNewRide={ this._newRide } />
        </Drawer>
        { mainContent }
      </div>
    );
  }
}

DisplayConciergePage.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  currentGroupId: PropTypes.string,
  currentGroupLogo: PropTypes.string,
  corporateAccount: PropTypes.object,
  conciergeGroup: PropTypes.object,
  setCurrentGroupLogo: PropTypes.func.isRequired,
  availableAffiliates: PropTypes.array,
  setCurrentGroupId: PropTypes.func.isRequired,
  setAvailableGroups: PropTypes.func,
  setConciergeGroup: PropTypes.func.isRequired,
  setCorporateAccount: PropTypes.func.isRequired,
  setAvailableAffiliates: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  globalNotification: PropTypes.object.isRequired,
  setGlobalNotification: PropTypes.func.isRequired
};

const ConciergePage = ConciergeContainer()(DisplayConciergePage);

export default withStyles(useStyles)(ConciergePage);
