import React from "react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import '../../styles/main-menu.scss';
import ConciergeLogo from '../../images/logos/kaptyn-concierge-drk.png';
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {ADD_ICON, EXPAND_ICON, QUESTION_ICON} from "../../icons/main-menu/svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';

function SidebarItem({ depthStep = 10, depth = 0, expanded, item, ...rest }) {
  const [collapsed, setCollapsed] = React.useState(true);
  const { label, items, Icon, path } = item;

  function toggleCollapse() {
    setCollapsed(prevValue => !prevValue);
  }

  function onClick() {
    if (Array.isArray(items) && expanded) {
      toggleCollapse();
    }
  }

  let expandIcon;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed ? (
      <ExpandLessIcon
        className={
          "sidebar-item-expand-arrow sidebar-item-expand-arrow-expanded"
        }
      />
    ) : (
      <ExpandMoreIcon className="sidebar-item-expand-arrow" />
    );
  }

  return (
    <div className="module">
      <ListItem
        className="sidebar-item"
        onClick={ onClick }
        button
        component={ Link }
        to={ path }
        title={label}
        dense
        {...rest}
      >
        <div
          style={{ paddingLeft: depth * depthStep }}
          className={ expanded ? "sidebar-item-content" : "sidebar-item-content sidebar-item-closed-content" }
        >
          {Icon && <Icon className="sidebar-item-icon" fontSize="small" />}
          <div className="sidebar-item-text"><span>{ label }</span></div>
        </div>
        {expandIcon}
      </ListItem>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem, index) => (
              <React.Fragment key={`${subItem.name}${index}`}>
                {subItem === "divider" ? (
                  <Divider className="sidebar-divider" />
                ) : (
                  <SidebarItem
                    depth={depth + 1}
                    depthStep={depthStep}
                    item={subItem}
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </div>
  );
}

SidebarItem.propTypes = {
  expanded: PropTypes.bool,
  depth: PropTypes.number,
  depthStep: PropTypes.number,
  item: PropTypes.object
};

function MainMenu({ items, depthStep, depth, expanded, onNewRide }) {

  function _openHyperLink() {
    window.open("https://www.kaptyn.com");
  }
  function _openHelpCenter() {
    window.open("https://www.kaptyn.com");
  }
  function _handleNewRideClick() {
    onNewRide();
  }
  return (
    <div className="sidebar">
      {expanded ? <Button disableRipple
        startIcon={<SvgIcon className="add-icon"><path d={ ADD_ICON }/></SvgIcon>}
        fullWidth={true}
        className="new-ride-btn"
        onClick={ _handleNewRideClick }
      >
        <span>New Ride</span>
      </Button> :
        <IconButton title="New Ride" color="primary" aria-label="new ride" onClick={ _handleNewRideClick } className="new-ride-closed-btn" component="span">
          <SvgIcon className="add-icon-closed"><path d={ ADD_ICON }/></SvgIcon>
        </IconButton>
      }

      <List disablePadding dense>
        {items.map((sidebarItem, index) => (
          <React.Fragment key={`${sidebarItem.name}${index}`}>
            {sidebarItem === "divider" ? (
              <Divider className="side-bar-divider" />
            ) : (
              <SidebarItem
                depthStep={depthStep}
                depth={depth}
                expanded={expanded}
                item={sidebarItem}
              />
            )}
          </React.Fragment>
        ))}
      </List>
      {expanded ? <div className="sidebar-footer-parent">
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 10,}}>
          <img width="162" src={ ConciergeLogo }  alt="concierge-logo" />
          <Typography component="div" className="version-number-text">Version {process.env.VERSION}</Typography>
        </div>
        <Container className="sidebar-footer">
          <IconButton className="sidebar-question-icon-button" color="primary" onClick={ _openHelpCenter } aria-label="help"  component="span">
            <SvgIcon className="sidebar-svg-icon-size"><path d={ QUESTION_ICON }/></SvgIcon>
          </IconButton>
          <Typography component="span" className="sidebar-help-center-text"> Help Center</Typography>
          <IconButton className="sidebar-expand-icon-button" color="primary" onClick={ _openHyperLink } aria-label="help" component="span">
            <SvgIcon className="sidebar-svg-icon-size"><path d={ EXPAND_ICON }/></SvgIcon>
          </IconButton>
        </Container>
      </div>
      : <div className="sidebar-footer-parent-closed">
          <Container className="sidebar-footer-closed">
            <br/>
            <IconButton title="Help Center" className="sidebar-question-icon-button-closed" onClick={ _openHelpCenter } color="primary" aria-label="help"  component="span">
              <SvgIcon className="sidebar-svg-icon-size"><path d={ QUESTION_ICON }/></SvgIcon>
            </IconButton>
          </Container>
        </div>
      }
    </div>
  );
}

MainMenu.propTypes = {
  items: PropTypes.array.isRequired,
  expanded: PropTypes.bool.isRequired,
  depth: PropTypes.number,
  depthStep: PropTypes.number,
  item: PropTypes.object,
  onNewRide: PropTypes.func.isRequired
};

export default MainMenu;
