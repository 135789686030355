import React from 'react';
import {
  COMPANY_DETAILS,
  DEFAULT_AUTO_DISMISS_DURATION,
  FORM_ERROR_REQUIRED_FIELD,
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../constants/Constants";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import '../../styles/company-details.scss';
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {KaptynDataService} from "../../dataservices/KaptynDataService";
import Button from "@material-ui/core/Button";
import endpoints from '../../constants/EndpointsProvider';
import {getToken} from '../../utils/cookieUtil';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
const _ = require('lodash');
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Controls from "../custom-controls/Controls";
import * as stringUtils from "underscore.string";
import clsx from "clsx";

const useStyles = () => ({
  root: {
    flexGrow: 1,
  },
  button: {
    borderRadius: 0,
    textTransform: 'none',
    fontSize: '15px',
    fontWeight: '500',
    padding: '12px 16px 12px 16px',
  },
  input: {
    display: 'none',
    textTransform: 'none'
  }
});

class CompanyDetailsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      companyName: '',
      companyTimeZone: '',
      companyIcon: '',
      groupId: this.props.groupId,
      formHasErrors: false,
      formErrors: {}
    };
    this._handleChange = this._handleChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._updateCurrentGroupDetails = this._updateCurrentGroupDetails.bind(this);
    this._getGroupDetails = this._getGroupDetails.bind(this);
    this._handleUpload = this._handleUpload.bind(this);
    this._updateCompanyPhoto = this._updateCompanyPhoto.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._handleError = this._handleError.bind(this);
  }

  componentDidMount() {
    this._getGroupDetails();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.groupId !== this.props.groupId) {
      this.setState({ groupId: nextProps.groupId }, this._getGroupDetails);
    }
  }

  _handleChange(e) {
    this.setState({
      companyName: e.target.value
    });
  }

  _onSelectChange(e) {
    this.setState({
      companyTimeZone: e.target.value
    });
  }

  _updateCurrentGroupDetails() {
    if (!this.state.formHasErrors) {
      const service = new KaptynDataService();
      const payload = {
        companyName: this.state.companyName,
        timeZone: this.state.companyTimeZone,
        groupId: this.state.groupId
      };
      this.props.setIsLoading({isLoading: true});
      service.updateCurrentGroupDetails(payload).then((result) => {
        if (result) {
          const globalNotification = {
            open: true,
            type: NOTIFICATION_TYPE_SUCCESS,
            content: 'The company details were updated successfully.',
            autoDismissDuration: DEFAULT_AUTO_DISMISS_DURATION
          };
          this.props.setGlobalNotification({globalNotification});
          this.props.setIsLoading({isLoading: false});
          return result;
        } else {
          this._handleError('An unexpected error occurred updating the company details.');
          this.props.setIsLoading({isLoading: false});
        }
      }).catch((error) => {
        this._handleError(error.message);
        this.props.setIsLoading({isLoading: false});
      });
    }
  }

  _getGroupDetails() {
    if (this.state.groupId) {
      this.props.setIsLoading({isLoading: true});
      const service = new KaptynDataService();
      service.getGroupDetails(this.state.groupId).then((result) => {
        this.setState({
          companyName: result.name,
          companyTimeZone: result.settings.timezone,
          companyIcon: result.settings.email_banner
        });
        this.props.setIsLoading({isLoading: false});
      }).catch((error) => {
        this._handleError(error.message);
        this.props.setIsLoading({isLoading: false});
      });
    }
  }

  _handleUpload(e) {
    this.setState({
      selectedImage: e.target.files[0]
    }, function () {
      this._updateCompanyPhoto(this.state.groupId, this.state.selectedImage);
    });
  }


  _updateCompanyPhoto(groupId, file) {
    /**
     * TODO: Refactor to make use of a KaptynDataService action
     The problem the KaptynDataService has on handling actions sending files, is that the "multipart/formdata" content-type header should be handled automatically by the fetch method, depending on the body contents.
     It automatically sends on the body a string and that makes the browser to default to text/plain, when the content-type header is not specified.
     To solve this and make the fetch method, refactoring the AbstractDataService _fetch method is necessary, to be able to send a FormData object and not a string, but that may imply changing also the unit tests.
     * @type {KaptynDataService}
     */
    if (file) {
      this.props.setIsLoading({isLoading: true});
      const service = new KaptynDataService();
      const path = {
        urlTemplate: endpoints.API.GROUPS.LOGO,
        routeParams: {groupId: groupId},
        queryParams: {}
      };

      const headers = _.assign({}, service.requestHeaders);
      delete headers['Content-type'];
      _.set(headers, 'Authorization', `Bearer ${getToken()}`);

      const requestMode = service.requestMode;

      const url = service.resolveUrl(path);
      const formData = new FormData();
      formData.append('image', file, file.name)
      fetch(url, {
        // content-type header should not be specified!
        method: 'PUT',
        body: formData,
        headers: headers,
        mode: requestMode,
      })
        .then(response => response.json())
        .then(result => {
          this.setState({
            companyIcon: result.settings.email_banner
          });
          if (result && result.settings && result.settings.email_banner && !stringUtils.isBlank(result.settings.email_banner)) {
            this.props.setCurrentGroupLogo({currentGroupLogo: result.settings.email_banner});
          }
          this.props.setIsLoading({isLoading: false});
        })
        .catch( (error) => {
          this._handleError(error);
          this.props.setIsLoading({isLoading: false});
        });
    }
  }

  _validateForm() {
    let formHasErrors = false;
    let formErrors = {};

    if (stringUtils.isBlank(this.state.companyName)) {
      formHasErrors = true;
      formErrors.companyName = FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.companyTimeZone)) {
      formHasErrors = true;
      formErrors.companyTimeZone = FORM_ERROR_REQUIRED_FIELD;
    }

    this.setState({formHasErrors, formErrors}, this._updateCurrentGroupDetails);
  }

  _handleError(errorMessage) {
    const globalNotification = {
      open: true,
      type: NOTIFICATION_TYPE_ERROR,
      content: errorMessage,
      autoDismissDuration: DEFAULT_AUTO_DISMISS_DURATION
    };
    this.props.setGlobalNotification({globalNotification});
  }

  render() {
    const {classes} = this.props;
    if (this.state.companyIcon) {
      return (
        <div style={{flexGrow: 1}}>
          <Grid container>
            <Grid item xs={12} className="company-details-header">
              <Typography variant="h4">{COMPANY_DETAILS}</Typography>
            </Grid>
            <Grid container item xs={12} className="company-details-grid-container">
              <Grid item xs={12} lg={2} className="photo-container">
                <Grid item xs={12} className="photo-uploader-image has-image"
                      style={{'background-image': 'url(' + this.state.companyIcon + ')'}}/>
                <Button variant="contained" component="label"
                        style={{textTransform: 'none', backgroundColor: 'transparent', disableElevation: 'true'}}
                        className="upload-photo">
                  Upload Photo
                  <input type="file" className={classes.input} onChange={this._handleUpload}/>
                </Button>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Paper className="company-details-paper">
                  <TextField id="companyName"
                             name="companyName"
                             label="Company Name"
                             className="company-details-input"
                             value={this.state.companyName}
                             onChange={this._handleChange}
                             error={!!this.state.formErrors.companyName}
                             helperText={this.state.formErrors.companyName}
                             fullWidth/>
                  <Controls.TimeZoneSelect
                    id="timeZone"
                    name="timeZone"
                    label={"Timezone"}
                    fullWidth
                    value={this.state.companyTimeZone}
                    onChange={this._onSelectChange}
                    className="company-details-input"
                    error={!!this.state.formErrors.companyTimeZone}
                  />
                  <Grid item xs={12} className="submit-container">
                    <Button onClick={this._validateForm}
                            variant="contained"
                            color="primary"
                            disableElevation
                            className={clsx(classes.button, 'update-company-details')}>
                      Save
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div style={{flexGrow: 1}}>
          <Grid container>
            <Grid item xs={12} className="company-details-header">
              <Typography variant="h4">{COMPANY_DETAILS}</Typography>
            </Grid>
            <Grid container item xs={12} className="company-details-grid-container">
              <Grid item xs={12} lg={2} className="photo-container">
                <Grid item xs={12} className="photo-uploader-image no-image">
                  <CameraAltIcon className="camera-icon"/>
                </Grid>
                <Button variant="contained" component="label"
                        style={{textTransform: 'none', backgroundColor: 'transparent', disableElevation: 'true'}}
                        className="upload-photo">
                  Upload Photo
                  <input type="file" className={classes.input} onChange={this._handleUpload}/>
                </Button>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Paper className="company-details-paper">
                  <TextField id="companyName"
                             name="companyName"
                             label="Company Name"
                             className="company-details-input"
                             value={this.state.companyName}
                             onChange={this._handleChange}
                             error={!!this.state.formErrors.companyName}
                             helperText={this.state.formErrors.companyName}
                             fullWidth/>

                  <Grid item xs={12}>
                    <Controls.TimeZoneSelect
                      id="timeZone"
                      name="timeZone"
                      label={"Timezone"}
                      fullWidth
                      value={this.state.companyTimeZone}
                      onChange={this._onSelectChange}
                      className="company-details-input"
                      error={!!this.state.formErrors.companyTimeZone}
                    />
                  </Grid>
                  <Grid item xs={12} className="submit-container">
                    <Button onClick={this._validateForm}
                            variant="contained"
                            color="primary"
                            disableElevation
                            className={clsx(classes.button, 'update-company-details')}>
                      Save
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

CompanyDetailsPage.propTypes = {
  classes: PropTypes.object,
  groupId: PropTypes.string,
  setCurrentGroupLogo: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setGlobalNotification: PropTypes.func.isRequired
};

export default withStyles(useStyles)(CompanyDetailsPage);
