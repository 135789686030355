import React from 'react';
import _ from 'lodash';
import {
  BOOKED, DEFAULT_AUTO_DISMISS_DURATION,
  LAST_SEVEN_DAYS,
  LAST_SEVEN_DAYS_DATE,
  LAST_THIRTY_DAYS,
  LAST_THIRTY_DAYS_DATE,
  LAST_THREE_DAYS,
  LAST_THREE_DAYS_DATE,
  NEXT_SEVEN_DAYS,
  NEXT_SEVEN_DAYS_DATE,
  NEXT_SIX_MONTHS,
  NEXT_SIX_MONTHS_DATE,
  NEXT_THIRTY_DAYS,
  NEXT_THIRTY_DAYS_DATE,
  NEXT_THREE_DAYS,
  NEXT_THREE_DAYS_DATE,
  NEXT_THREE_MONTHS,
  NEXT_THREE_MONTHS_DATE,
  NOTIFICATION_TYPE_ERROR,
  RIDE_PROGRESS_BAR_ASSIGNED_STATUS,
  RIDE_PROGRESS_BAR_UNASSIGNED_STATUS,
  TODAY,
  TODAY_DATE,
  TOMORROW,
  TOMORROW_DATE,
  YESTERDAY,
  YESTERDAY_DATE,
  getRideDateFromRideDateValue, CUSTOM_DATE
} from "../../constants/Constants";
import "../../styles/booked-in-progress-completed-canceled-pages.scss";
import { KaptynDataService } from "../../dataservices/KaptynDataService";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RidesListActionBar from "../lower-level/RidesListActionBar";
import RidesList from "../lower-level/RidesList";
import { convertLocalToUTC, getDateRange } from "../../utils/dateUtil";
import PropTypes from "prop-types";
import { getRideItem } from "../../utils/rideUtil";
import {getRidesListDateRange} from "../../utils/localStorageUtil";

class BookedRidesPage extends React.Component {

  constructor(props) {
    super(props);
    const dateRange = getRidesListDateRange();
    this.state = {
      rides : [],
      searchedValue:'',
      selectedRideStatus: BOOKED,
      selectedDate: dateRange,
      selectedTime: getRideDateFromRideDateValue(dateRange),
      beginTime: null,
      endTime: null,
      tenantGroupId: this.props.tenantGroupId
    };
    this._retrieveRides = this._retrieveRides.bind(this);
    this._onSearchChange = this._onSearchChange.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this._onDownloadCSV = this._onDownloadCSV.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.tenantGroupId !== nextProps.tenantGroupId) {
      this.setState({ tenantGroupId: nextProps.tenantGroupId });
    }
  }

  _onSearchChange(searchedValue) {
    this.setState({ searchedValue }, this._retrieveRides);
  }

  _onStatusChange(event) {
    const selectedRideStatus = event.target.value ? event.target.value : '';
    this.setState({ selectedRideStatus }, this._retrieveRides);
  }

  _onDateChange(event) {
    const selectedDate = _.get(event, 'target.value')  || _.get(event,'dateRange') || '';
    const newState = {
      selectedDate
    };

    if (selectedDate === CUSTOM_DATE) {
      newState.beginTime = _.get(event, 'beginTime');
      newState.endTime = _.get(event, 'endTime');
    }

    this.setState(newState, this._retrieveRides);
  }

  _onDownloadCSV() {
    console.log('_onDownloadCSV: implement this method');
  }

  _retrieveRides() {
    let rides = [];
    let statusList = [RIDE_PROGRESS_BAR_ASSIGNED_STATUS,RIDE_PROGRESS_BAR_UNASSIGNED_STATUS];
    const populate = ['rider','riders','serviceLevel','acceptedDriver.driver','acceptedDriver.vehicle','offers.driver','affiliateOffers.serviceLevel'];

    let options = {
      populate,
      status: statusList,
      //scheduledTime: moment().utc().format("YYYY-MM-DD") + 'T23:59:59.000Z',
      perPage: 50
    };

    if (this.state.selectedDate) {
      let beginTime = '';
      let endTime = '';
      switch (this.state.selectedDate) {
        case YESTERDAY:
          beginTime = convertLocalToUTC(YESTERDAY_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(YESTERDAY_DATE + "T23:59:59.999");
          this.setState({selectedTime:YESTERDAY_DATE});
          break;

        case TODAY:
          beginTime = convertLocalToUTC(TODAY_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(TODAY_DATE + "T23:59:59.999");
          this.setState({selectedTime:TODAY_DATE});
          break;

        case TOMORROW:
          beginTime = convertLocalToUTC(TOMORROW_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(TOMORROW_DATE + "T23:59:59.999");
          this.setState({selectedTime:TOMORROW_DATE});
          break;

        case LAST_THREE_DAYS:
          beginTime = convertLocalToUTC(LAST_THREE_DAYS_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(TODAY_DATE + "T23:59:59.999");
          this.setState({selectedTime:LAST_THREE_DAYS_DATE});
          break;

        case LAST_SEVEN_DAYS:
          beginTime = convertLocalToUTC(LAST_SEVEN_DAYS_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(TODAY_DATE + "T23:59:59.999");
          this.setState({selectedTime:LAST_SEVEN_DAYS_DATE});
          break;

        case LAST_THIRTY_DAYS:
          beginTime = convertLocalToUTC(LAST_THIRTY_DAYS_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(TODAY_DATE + "T23:59:59.999");
          this.setState({selectedTime:LAST_THIRTY_DAYS_DATE});
          break;

        case NEXT_THREE_DAYS:
          beginTime = convertLocalToUTC(TODAY_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(NEXT_THREE_DAYS_DATE + "T23:59:59.999");
          this.setState({selectedTime:NEXT_THREE_DAYS_DATE});
          break;

        case NEXT_SEVEN_DAYS:
          beginTime = convertLocalToUTC(TODAY_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(NEXT_SEVEN_DAYS_DATE + "T23:59:59.999");
          this.setState({selectedTime:NEXT_SEVEN_DAYS_DATE});
          break;

        case NEXT_THIRTY_DAYS:
          beginTime = convertLocalToUTC(TODAY_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(NEXT_THIRTY_DAYS_DATE + "T23:59:59.999");
          this.setState({selectedTime:NEXT_THIRTY_DAYS_DATE});
          break;

        case NEXT_THREE_MONTHS:
          beginTime = convertLocalToUTC(TODAY_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(NEXT_THREE_MONTHS_DATE + "T23:59:59.999");
          this.setState({selectedTime:NEXT_THREE_MONTHS_DATE});
          break;

        case NEXT_SIX_MONTHS:
          beginTime = convertLocalToUTC(TODAY_DATE + "T00:00:00.000");
          endTime = convertLocalToUTC(NEXT_SIX_MONTHS_DATE + "T23:59:59.999");
          this.setState({selectedTime:NEXT_SIX_MONTHS_DATE});
          break;
        case CUSTOM_DATE:
          beginTime = convertLocalToUTC(this.state.beginTime);
          endTime = convertLocalToUTC(this.state.endTime);
          break;
      }

      options.beginTime = beginTime;
      options.endTime= endTime;
      options.sortOrder = 1;
    }

    if (this.state.searchedValue) {
      options.searchQuery = this.state.searchedValue;
    }

    const dataService = new KaptynDataService();

    this.props.setIsLoading({ isLoading:true });

    dataService.listRides(options).then((response)=>{
      if (response.list && response.list.length > 0) {
        response.list.forEach((ride)=>{
          rides.push(getRideItem(ride))
        });
      }
      this.setState({ rides });
      this.props.setIsLoading({isLoading: false });
    }).catch((err)=>{
      this.props.setIsLoading({ isLoading:false });
      const globalNotification = {
        open: true,
        type: NOTIFICATION_TYPE_ERROR,
        content: err.message,
        autoDismissDuration: DEFAULT_AUTO_DISMISS_DURATION
      };
      this.props.setGlobalNotification({ globalNotification });
    });
  }

  componentDidMount() {
    this._retrieveRides();
  }

  render() {
    return (
      <div style={{flexGrow: 1}}>
        <Grid container>
          <Grid item xs={12} className="child-page-header">
            <Typography variant="h4">{ BOOKED }</Typography>
          </Grid>
          <Grid item xs={12} className="child-page-action-bar">
            <RidesListActionBar showStatusDropDown={false}
                                onSearchChange={ this._onSearchChange }
                                onStatusChange={ this._onStatusChange }
                                onDateChange={this._onDateChange}
                                onDownloadCSV={this._onDownloadCSV}/>
          </Grid>
          <Grid item xs={12} className="ride-list-grid-container-child">
            <RidesList rides={this.state.rides}
                       groupRides={false}
                       date={ getDateRange(this.state.selectedDate) }
                       onUpdateRides={ this._retrieveRides }
                       history={ this.props.history }
                       setIsLoading={ this.props.setIsLoading }
                       setGlobalNotification={ this.props.setGlobalNotification }
                       tenantGroupId={ this.state.tenantGroupId } />
          </Grid>
        </Grid>
      </div>
    );
  }
}

BookedRidesPage.propTypes = {
  history: PropTypes.object,
  setIsLoading: PropTypes.func.isRequired,
  setGlobalNotification: PropTypes.func.isRequired,
  tenantGroupId: PropTypes.string
};

export default BookedRidesPage;
