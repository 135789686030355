import _ from "lodash";

/**
 * determines if a service level supports the airport option
 * @param serviceLevel
 * @return {*|boolean|boolean}
 */
export function hasServiceLevelExtraInputEnabled(serviceLevel, inputType) {
  return (serviceLevel.inputFields && serviceLevel.inputFields.length > 0 && _.some(serviceLevel.inputFields, {"inputType": inputType}));
}

export function getServiceLevelExtraInput(serviceLevel, inputType) {
  let result = null;
  if (serviceLevel.inputFields && serviceLevel.inputFields.length > 0) {
    return serviceLevel.inputFields.find((inputField) => inputField.inputType === inputType);
  }
  return result;
}
