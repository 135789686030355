import * as constants from "../constants/Constants";

export function getCountryCallingCode(countryAlphaCode) {
  const filteredCountryCodes = constants.COUNTRY_CODES.filter((cc) => { return cc.value === countryAlphaCode; } );
  return filteredCountryCodes.length > 0 ? filteredCountryCodes[0].label : constants.DEFAULT_COUNTRY_CALLING_CODE;
}

export function getValidCountryAlphaCode(knownCountryAlphaCode) {
  const filteredCountryCodes = constants.COUNTRY_CODES.filter((cc) => { return cc.value === knownCountryAlphaCode; } );
  return filteredCountryCodes.length > 0 ? filteredCountryCodes[0].value : constants.DEFAULT_COUNTRY_ALPHA_CODE;
}
