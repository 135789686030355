/* eslint-disable import/no-named-as-default */

import { Route, Switch } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ConciergePage from "./pages/ConciergePage";
import NotFoundPage from "./pages/NotFoundPage";
import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";
import SignUpPage from "./pages/SignUpPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";

class App extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/signup" component={ SignUpPage } />
          <Route exact path="/login" component={ LoginPage } />
          <Route exact path="/forgot" component={ ForgotPasswordPage } />
          <Route path="/" component={ ConciergePage } />
          <Route component={ NotFoundPage } />
        </Switch>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
