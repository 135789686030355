import * as constants from '../constants/Constants';

const initialState = {
  currentUser: {
    firstName: null,
    lastName: null,
    timeZone: null
  },
  currentGroupId: null,
  currentGroupLogo: null,
  availableGroups: [],
  conciergeGroup: {
    conciergeGroupId: null,
    parentGroupId: null,
    corporateAccountId: null
  },
  corporateAccount: null,
  availableAffiliates: [],
  isLoading: false,
  globalNotification: {
    open: false,
    type: constants.NOTIFICATION_TYPE_INFO,
    content: '',
    autoDismissDuration: null
  }
};

export default initialState;
