import React from "react";
import ConciergeContainer from "../../containers/ConciergeContainer";
import "../../styles/forgot-password-page.scss"
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {KaptynDataService} from "../../dataservices/KaptynDataService";
import {hasActiveSession} from "../../utils/cookieUtil";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as constants from "../../constants/Constants";
import Notification from "../lower-level/Notification";
import ConciergeLogo from "../../images/logos/logo-kaptyn-concierge-2-x.png";

const stringUtils = require("underscore.string");

export class DisplayForgotPasswordPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isLoading: "determinate",
      rememberMe: false,
      formHasErrors: false,
      formErrors: {},
      notification: {
        open: this.props.globalNotification.open,
        type: this.props.globalNotification.type,
        content: this.props.globalNotification.content,
        autoDismissDuration: this.props.globalNotification.autoDismissDuration
      }
    };
    this._handleChange = this._handleChange.bind(this);
    this._handleForgotPassword = this._handleForgotPassword.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._handleError = this._handleError.bind(this);
    this._handleSuccess = this._handleSuccess.bind(this);
  }

  componentDidMount() {
    if (hasActiveSession()) {
      this.props.history.push('/');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.globalNotification !== nextProps.globalNotification) {
      const notification = {
        open: nextProps.globalNotification.open,
        type: nextProps.globalNotification.type,
        content: nextProps.globalNotification.content,
        autoDismissDuration: nextProps.globalNotification.autoDismissDuration
      };
      this.setState({ notification });
    }
  }

  _handleChange(e) {
    const { name, value } = e.target;
    const newState = {
      ...this.state,
      [name]: value
    };
    this.setState(newState);
  }

  _handleError(errorMessage) {
    const globalNotification = {
      open: true,
      type: constants.NOTIFICATION_TYPE_ERROR,
      content: errorMessage,
      autoDismissDuration: constants.DEFAULT_AUTO_DISMISS_DURATION
    };
    this.props.setGlobalNotification({ globalNotification });
  }

  _handleSuccess(successMessage) {
    const globalNotification = {
      open: true,
      type: constants.NOTIFICATION_TYPE_SUCCESS,
      content: successMessage,
      autoDismissDuration: constants.DEFAULT_AUTO_DISMISS_DURATION
    };
    this.props.setGlobalNotification({ globalNotification });
  }

  _handleForgotPassword() {
    if(!this.state.formHasErrors) {
      this.setState({ isLoading: "indeterminate" });
      const dataService = new KaptynDataService();
      // eslint-disable-next-line no-unused-vars
      dataService.forgotPassword(this.state.email, process.env.FORGOT_PASSWORD_REDIRECT_URL).then((forgotPasswordResult) => {
        this._handleSuccess('Check your email for a link to reset your password.');
        this.props.history.push('/login');
      }).catch((error) => {
        this._handleError(error.message);
        this.setState({ isLoading: "determinate" });
      });
    }
  }

  _validateForm() {
    let formHasErrors = false;
    let formErrors = {};

    if (stringUtils.isBlank(this.state.email)) {
      formHasErrors = true;
      formErrors.email = constants.FORM_ERROR_REQUIRED_FIELD;
    }

    this.setState({ formHasErrors, formErrors }, this._handleForgotPassword);
  }

  render() {
    return (
      <div>
        <div className="concierge-logo">
          <img src={ ConciergeLogo } />
        </div>
        <Notification open={ this.state.notification.open }
                      type={ this.state.notification.type }
                      content={ this.state.notification.content }
                      autoDismissDuration={ this.state.notification.autoDismissDuration }
                      setGlobalNotification={ this.props.setGlobalNotification } />
        <Grid container>
          <Grid item xs={8} sm={7} md={5} lg={12}
                className="forgot-password-form"
          >
            <Paper
              variant="elevation"
              elevation={2}
              className="forgot-password-background"
            >
              <Grid item xs={12}>
                <Typography component="h1" variant="h5" className="forgot-password-form-title">
                  Password Recovery
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={0}>
                  <Grid item>
                    <TextField
                      type="email"
                      placeholder="Email"
                      name="email"
                      fullWidth
                      value={this.state.email}
                      className="forgot-password-form-email-input forgot-password-form-input"
                      inputProps={{style: {fontSize: 16}}}
                      onChange={ this._handleChange }
                      error={ !!this.state.formErrors.email }
                      helperText={ this.state.formErrors.email }
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="text"
                      color="primary"
                      className="button-block"
                      component="button"
                      size="large"
                      onClick={ this._validateForm }
                    >
                      Send link<CircularProgress
                      variant={this.state.isLoading}
                      size={20}
                      thickness={3}
                      className="forgot-password-form-circular-progress"
                    />
                    </Button>
                  </Grid>
                  <Grid item xs={12} className="forgot-password-login-container">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <Typography display="inline" className="forgot-password-form-login-link" onClick={ () => this.props.history.push('/login') }>Login</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <div className="version-number-container">
          <Typography component="div" className="version-number-text">Version {process.env.VERSION}</Typography>
        </div>
      </div>
    );
  }
}

DisplayForgotPasswordPage.propTypes = {
  history: PropTypes.object,
  globalNotification: PropTypes.object.isRequired,
  setGlobalNotification: PropTypes.func.isRequired
};

const ForgotPasswordPage = ConciergeContainer()(DisplayForgotPasswordPage);

export default ForgotPasswordPage;
