import {
  LAST_SEVEN_DAYS,
  LAST_SEVEN_DAYS_DATE,
  LAST_THIRTY_DAYS,
  LAST_THIRTY_DAYS_DATE,
  LAST_THREE_DAYS,
  LAST_THREE_DAYS_DATE,
  NEXT_SEVEN_DAYS,
  NEXT_SEVEN_DAYS_DATE, NEXT_SIX_MONTHS, NEXT_SIX_MONTHS_DATE, NEXT_THIRTY_DAYS,
  NEXT_THIRTY_DAYS_DATE,
  NEXT_THREE_DAYS,
  NEXT_THREE_DAYS_DATE, NEXT_THREE_MONTHS, NEXT_THREE_MONTHS_DATE,
  TODAY,
  TODAY_DATE,
  TOMORROW,
  TOMORROW_DATE,
  YESTERDAY,
  YESTERDAY_DATE
} from "../constants/Constants";

import { storeRegistry } from '../store/storeRegistry';

const moment = require('moment');
require('moment-timezone');

export function combineDateAndTime(date, time) {
  return moment(date + ' ' + time);
}

export function formatDate(date, separator=null) {
  let newDate = date.toISOString().slice(0, 19);
  if (separator) {
    newDate = newDate.replace(/-/g, separator);
  }
  return newDate.replace("T", " ");
}

export function formatTime(date) {
  const amPm = date.getHours() > 11 ? 'pm' : 'am';
  let hours = date.getHours();
  if (hours > 12) {
    hours -= 12;
  }
  const minutes = date.getMinutes();
  return `${appendLeadingZeroes(hours)}:${appendLeadingZeroes(minutes)} ${amPm}`;
}

export function combineAndAdd(date, time, seconds) {
  return moment(combineDateAndTime(date, time)).add(seconds, 'seconds');
}

export function getCurrentDateAndTime() {
  const dateAndTime = new Date();

  return {
    date: `${ dateAndTime.getFullYear() }-${ appendLeadingZeroes(dateAndTime.getMonth() + 1)}-${ appendLeadingZeroes( dateAndTime.getDate() )}`,
    time: `${ appendLeadingZeroes(dateAndTime.getHours()) }:${ appendLeadingZeroes(dateAndTime.getMinutes()) }`
  };
}

export function normalDateToBookingConfirmedDate(pickUpDate) {
  if(pickUpDate) {
    const weekDay = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    const yearMonth = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    return weekDay[pickUpDate.getDay()]+', '+yearMonth[pickUpDate.getMonth()]+' '+pickUpDate.getDate()+', '+pickUpDate.getFullYear();
  }
  return '';
}

export function normalDateToRidesListItemDate(selectedDate) {
  const _selectedDate = moment(selectedDate);
  if (selectedDate) {
    const weekDay = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const yearMonth = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return yearMonth[_selectedDate.month()] + ' ' +_selectedDate.date() + ', ' + _selectedDate.year() + ': ' + weekDay[_selectedDate.day()];
  }
}

function appendLeadingZeroes(n) {
  if(n <= 9) {
    return "0" + n;
  }
  return n;
}

export function convertLocalToUTC(date) {
  const FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
  return moment(date, FORMAT).utc().format(FORMAT);
}

export function formatDateForCurrentUser(date, outputFormat) {
  let conciergeState = null;
  if (storeRegistry && storeRegistry.getStore() && storeRegistry.getStore().getState() && storeRegistry.getStore().getState().concierge) {
    conciergeState = storeRegistry.getStore().getState().concierge;
  }
  let timeZone = null;
  if (conciergeState && conciergeState.currentUser && conciergeState.currentUser.timeZone) {
    timeZone = conciergeState.currentUser.timeZone;
  }
   if (!outputFormat) {
     outputFormat = 'dddd MMM D, YYYY - hh:mm a';
   }
  if (timeZone) {
    return moment(date).tz(timeZone).format(outputFormat);
  }
  return moment(date).format(outputFormat);
}

export function getDateRange(labelDate) {
  const divider = ' - ';
  if (labelDate) {
    switch(labelDate) {
      case TODAY:
        return normalDateToRidesListItemDate(TODAY_DATE);

      case YESTERDAY:
        return normalDateToRidesListItemDate(YESTERDAY_DATE);

      case TOMORROW:
        return normalDateToRidesListItemDate(TOMORROW_DATE);

      case LAST_THREE_DAYS:
        return normalDateToRidesListItemDate(LAST_THREE_DAYS_DATE) + divider + normalDateToRidesListItemDate(TODAY_DATE);

      case LAST_SEVEN_DAYS:
        return normalDateToRidesListItemDate(LAST_SEVEN_DAYS_DATE) + divider + normalDateToRidesListItemDate(TODAY_DATE);

      case LAST_THIRTY_DAYS:
        return normalDateToRidesListItemDate(LAST_THIRTY_DAYS_DATE) + divider + normalDateToRidesListItemDate(TODAY_DATE);

      case NEXT_THREE_DAYS:
        return normalDateToRidesListItemDate(TODAY_DATE) + divider + normalDateToRidesListItemDate(NEXT_THREE_DAYS_DATE);

      case NEXT_SEVEN_DAYS:
        return normalDateToRidesListItemDate(TODAY_DATE) + divider + normalDateToRidesListItemDate(NEXT_SEVEN_DAYS_DATE);

      case NEXT_THIRTY_DAYS:
        return normalDateToRidesListItemDate(TODAY_DATE) + divider + normalDateToRidesListItemDate(NEXT_THIRTY_DAYS_DATE);

      case NEXT_THREE_MONTHS:
        return normalDateToRidesListItemDate(TODAY_DATE) + divider + normalDateToRidesListItemDate(NEXT_THREE_MONTHS_DATE);

      case NEXT_SIX_MONTHS:
        return normalDateToRidesListItemDate(TODAY_DATE) + divider + normalDateToRidesListItemDate(NEXT_SIX_MONTHS_DATE);
    }
  }
}

export function splitDateAndTime(dateTime) {
  const dateTimeStr = formatDateForCurrentUser(dateTime, 'YYYY-MM-DD,HH:mm');
  const dateTimeArray = dateTimeStr.split(',');
  return { date: dateTimeArray[0], time: dateTimeArray[1] };
}

/**
 * returns the month of the current year
 * @param queryDate
 * @return {number}
 */
export function getMonthOfYear(queryDate){
  return moment(queryDate).month() + 1;
}

/**
 * returns the day of the current month
 * @param queryDate
 * @return {number}
 */
export function getDayOfMonth(queryDate){
  return moment(queryDate).date();
}

/**
 * returns the current year
 * @param queryDate
 * @return {number}
 */
export function getYear(queryDate){
  return moment(queryDate).year();
}

/**
 * returns a specific time format. ex: February 16, 2021 10:24 AM
 * @param indicatedTime
 * @return {string}
 */
export function getAirportDateAndTimeFormat(indicatedTime = null) {
  const date = indicatedTime ? moment(indicatedTime) : moment();
  const time = date.format("hh:mm A");
  const yearMonth = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  return yearMonth[date.month()] + ' ' +date.date() + ', ' + date.year() + ' ' + time;
}

/**
 * returns a specific time format.ex: Monday, February 15
 * @param flightDate
 * @return {string}
 */
export function getFlightDateFormatted(flightDate) {
  const date = moment(flightDate, "YYYY-MM-DD");

  if (flightDate) {
    const weekDay = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const yearMonth = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return weekDay[date.day()] + ', ' +yearMonth[date.month()] + ' ' + date.date();
  }
}

export function getDayEnd() {
  const FORMAT = 'HH:mm';
  return moment().endOf('day').format(FORMAT);
}

export function getDayStart() {
  const FORMAT = 'HH:mm';
  return moment().startOf('day').format(FORMAT);
}
