import {NOT_AVAILABLE} from '../constants/Constants';

export function getRideItem(ride) {
  const rider = _getRider(ride);
  let additionalPassengers = _getAdditionalPassengers(ride);
  return {
    reservationNumber: ride.reservationNumber,
    passengerName: rider && rider.firstName && rider.lastName  ? rider.firstName + ' ' + rider.lastName : NOT_AVAILABLE,
    passengerPhone: rider && rider.phoneNumbers.length > 0 ? rider.phoneNumbers.filter((pn) => { return pn.primary } )[0].phoneNumber : NOT_AVAILABLE,
    driverName: ride.acceptedDriver.driver && ride.acceptedDriver.driver.firstName && ride.acceptedDriver.driver.lastName ? ride.acceptedDriver.driver.firstName + ' ' + ride.acceptedDriver.driver.lastName : '',
    driverPhone: ride.acceptedDriver.driver && ride.acceptedDriver.driver.phone ? ride.acceptedDriver.driver.phone : '',
    rideId: ride.id,
    serviceLevelName: ride.serviceLevel.name ? ride.serviceLevel.name : '',
    vehicleColor: ride.acceptedDriver.driver && ride.acceptedDriver.driver.vehicle && ride.acceptedDriver.driver.vehicle.color ? ride.acceptedDriver.driver.vehicle.color : '',
    vehicleMake: ride.acceptedDriver.driver && ride.acceptedDriver.driver.vehicle && ride.acceptedDriver.driver.vehicle.make ? ride.acceptedDriver.driver.vehicle.make : '',
    vehicleModel: ride.acceptedDriver.driver && ride.acceptedDriver.driver.vehicle && ride.acceptedDriver.driver.vehicle.model ? ride.acceptedDriver.driver.vehicle.model : '',
    vehicleNumber: ride.acceptedDriver.driver && ride.acceptedDriver.driver.vehicle && ride.acceptedDriver.driver.vehicle.vehicleNumber ? ride.acceptedDriver.driver.vehicle.vehicleNumber : '',
    rideStatus: ride.status,
    fromAddress: _getLocationProperty(ride, 'from') || NOT_AVAILABLE,
    toAddress: _getLocationProperty(ride, 'to') || NOT_AVAILABLE,
    scheduledTime: ride.scheduledTime,
    passengers: additionalPassengers > 1 ? additionalPassengers + 1 : 1,
    balance: ride.transaction ? ride.transaction.balance.paid : 0,
    transaction_status: ride.transaction ? ride.transaction.status : 0,
  };
}

export function formatNumberInput(e){
  let checkIfNum = e.key !== undefined && e.keyCode !== 8 ? isNaN(e.key) : null;
  return  checkIfNum && e.preventDefault();
}

function _getRider(ride) {
  let rider = ride.rider;
  if (!rider) {
    rider = ride.riders[0].rider;
  }
  return rider;
}

function _getLocationProperty(ride, locationProperty) {
  let rider = ride.rider;
  if (!rider) {
    return ride.riders[0][locationProperty];
  } else {
    return rider[locationProperty];
  }
}

function _getAdditionalPassengers(ride) {
  let rider = ride.rider;
  if (!rider) {
    rider = ride.riders[0];
    if (rider.additionalPassengers) {
      return rider.additionalPassengers;
    }
    return 0;
  } else {
    return 0;
  }
}
