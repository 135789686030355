import * as actionTypes from '../constants/ActionTypes';
import initialState from './InitialState';

export default function concierge(state = initialState, action) {

  switch (action.type) {
    case actionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload.currentUser
      };

    case actionTypes.SET_CURRENT_GROUP_ID:
      return {
        ...state,
        currentGroupId: action.payload.currentGroupId
      };

    case actionTypes.SET_CURRENT_GROUP_LOGO:
      return {
        ...state,
        currentGroupLogo: action.payload.currentGroupLogo
      };

    case actionTypes.SET_AVAILABLE_GROUPS:
      return {
        ...state,
        availableGroups: action.payload.availableGroups
      };

    case actionTypes.SET_CONCIERGE_GROUP:
      return {
        ...state,
        conciergeGroup: action.payload.conciergeGroup
      };

    case actionTypes.SET_CORPORATE_ACCOUNT:
      return {
        ...state,
        corporateAccount: action.payload.corporateAccount
      };

    case actionTypes.SET_AVAILABLE_AFFILIATES:
      return {
        ...state,
        availableAffiliates: action.payload.availableAffiliates
      };

    case actionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };

    case actionTypes.SET_GLOBAL_NOTIFICATION:
      return {
        ...state,
        globalNotification: action.payload.globalNotification
      };

    default:
      return state;
  }
}
