import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';
import PropTypes from "prop-types";

const timezoneList = [
  { value: "(GMT-11:00) Pago Pago", id: "Pacific/Pago_Pago" },
  { value: "(GMT-10:00) Hawaii Time", id: "Pacific/Honolulu" },
  { value: "(GMT-08:00) Pacific Time", id: "America/Los_Angeles" },
  { value: "(GMT-08:00) Pacific Time - Tijuana", id: "America/Tijuana" },
  { value: "(GMT-07:00) Mountain Time", id: "America/Denver" },
  { value: "(GMT-07:00) Mountain Time - Arizona", id: "America/Phoenix" },
  { value: "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan", id: "America/Mazatlan" },
  { value: "(GMT-06:00) Central Time", id: "America/Chicago" },
  { value: "(GMT-06:00) Central Time - Mexico City", id: "America/Mexico_City" },
  { value: "(GMT-06:00) Central Time - Regina", id: "America/Regina" },
  { value: "(GMT-06:00) Guatemala", id: "America/Guatemala" },
  { value: "(GMT-05:00) Bogota", id: "America/Bogota" },
  { value: "(GMT-05:00) Eastern Time", id: "America/New_York" },
  { value: "(GMT-05:00) Lima", id: "America/Lima" },
  { value: "(GMT-04:30) Caracas", id: "America/Caracas" },
  { value: "(GMT-04:00) Atlantic Time - Halifax", id: "America/Halifax" },
  { value: "(GMT-04:00) Guyana", id: "America/Guyana" },
  { value:  "(GMT-04:00) La Paz", id: "America/La_Paz" },
  { value: "(GMT-03:00) Buenos Aires", id: "America/Argentina/Buenos_Aires" },
  { value: "(GMT-03:00) Godthab", id: "America/Godthab" },
  { value: "(GMT-03:00) Montevideo", id: "America/Montevideo" },
  { value: "(GMT-03:30) Newfoundland Time - St. Johns", id: "America/St_Johns" },
  { value: "(GMT-03:00) Santiago", id: "America/Santiago" },
  { value: "(GMT-02:00) Sao Paulo", id: "America/Sao_Paulo" },
  { value: "(GMT-02:00) South Georgia", id: "Atlantic/South_Georgia" },
  { value: "(GMT-01:00) Azores", id: "Atlantic/Azores" },
  { value: "(GMT-01:00) Cape Verde", id: "Atlantic/Cape_Verde" },
  { value: "(GMT+00:00) Casablanca", id: "Africa/Casablanca" },
  { value: "(GMT+00:00) Dublin", id: "Europe/Dublin" },
  { value: "(GMT+00:00) Lisbon", id: "Europe/Lisbon" },
  { value: "(GMT+00:00) London", id: "Europe/London" },
  { value: "(GMT+00:00) Monrovia", id: "Africa/Monrovia" },
  { value: "(GMT+01:00) Algiers", id: "Africa/Algiers" },
  { value: "(GMT+01:00) Amsterdam", id: "Europe/Amsterdam" },
  { value: "(GMT+01:00) Berlin", id: "Europe/Berlin" },
  { value: "(GMT+01:00) Brussels", id: "Europe/Brussels" },
  { value: "(GMT+01:00) Budapest", id: "Europe/Budapest" },
  { value: "(GMT+01:00) Central European Time - Belgrade", id: "Europe/Belgrade" },
  { value: "(GMT+01:00) Central European Time - Prague", id: "Europe/Prague" },
  { value: "(GMT+01:00) Copenhagen", id: "Europe/Copenhagen" },
  { value: "(GMT+01:00) Madrid", id: "Europe/Madrid" },
  { value: "(GMT+01:00) Paris", id: "Europe/Paris" },
  { value: "(GMT+01:00) Rome", id: "Europe/Rome" },
  { value: "(GMT+01:00) Stockholm", id: "Europe/Stockholm" },
  { value: "(GMT+01:00) Vienna", id: "Europe/Vienna" },
  { value: "(GMT+01:00) Warsaw", id: "Europe/Warsaw" },
  { value: "(GMT+02:00) Athens", id: "Europe/Athens" },
  { value: "(GMT+02:00) Bucharest", id: "Europe/Bucharest" },
  { value: "(GMT+02:00) Cairo", id: "Africa/Cairo" },
  { value: "(GMT+02:00) Jerusalem", id: "Asia/Jerusalem" },
  { value: "(GMT+02:00) Johannesburg", id: "Africa/Johannesburg" },
  { value: "(GMT+02:00) Helsinki", id: "Europe/Helsinki" },
  { value: "(GMT+02:00) Kiev", id: "Europe/Kiev" },
  { value: "(GMT+02:00) Moscow-01 - Kaliningrad", id: "Europe/Kaliningrad" },
  { value: "(GMT+02:00) Riga", id: "Europe/Riga" },
  { value: "(GMT+02:00) Sofia", id: "Europe/Sofia" },
  { value: "(GMT+02:00) Tallinn", id: "Europe/Tallinn" },
  { value: "(GMT+02:00) Vilnius", id: "Europe/Vilnius" },
  { value: "(GMT+03:00) Istanbul", id: "Europe/Istanbul" },
  { value: "(GMT+03:00) Baghdad", id: "Asia/Baghdad" },
  { value: "(GMT+03:00) Nairobi", id: "Africa/Nairobi" },
  { value: "(GMT+03:00) Minsk", id: "Europe/Minsk" },
  { value: "(GMT+03:00) Riyadh", id: "Asia/Riyadh" },
  { value: "(GMT+03:00) Moscow+00 - Moscow", id: "Europe/Moscow" },
  { value: "(GMT+03:30) Tehran", id: "Asia/Tehran" },
  { value: "(GMT+04:00) Baku", id: "Asia/Baku" },
  { value: "(GMT+04:00) Moscow+01 - Samara", id: "Europe/Samara" },
  { value: "(GMT+04:00) Tbilisi", id: "Asia/Tbilisi" },
  { value: "(GMT+04:00) Yerevan", id: "Asia/Yerevan" },
  { value: "(GMT+04:30) Kabul", id: "Asia/Kabul" },
  { value: "(GMT+05:00) Karachi", id: "Asia/Karachi" },
  { value: "(GMT+05:00) Moscow+02 - Yekaterinburg", id: "Asia/Yekaterinburg" },
  { value: "(GMT+05:00) Tashkent", id: "Asia/Tashkent" },
  { value: "(GMT+05:30) Colombo", id: "Asia/Colombo" },
  { value: "(GMT+06:00) Almaty", id: "Asia/Almaty" },
  { value: "(GMT+06:00) Dhaka", id: "Asia/Dhaka" },
  { value: "(GMT+06:30) Rangoon", id: "Asia/Rangoon" },
  { value: "(GMT+07:00) Bangkok", id: "Asia/Bangkok" },
  { value: "(GMT+07:00) Jakarta", id: "Asia/Jakarta" },
  { value: "(GMT+07:00) Moscow+04 - Krasnoyarsk", id: "Asia/Krasnoyarsk" },
  { value: "(GMT+08:00) China Time - Beijing", id: "Asia/Shanghai" },
  { value: "(GMT+08:00) Hong Kong", id: "Asia/Hong_Kong" },
  { value: "(GMT+08:00) Kuala Lumpur", id: "Asia/Kuala_Lumpur" },
  { value: "(GMT+08:00) Moscow+05 - Irkutsk", id: "Asia/Irkutsk" },
  { value: "(GMT+08:00) Singapore", id: "Asia/Singapore" },
  { value: "(GMT+08:00) Taipei", id: "Asia/Taipei" },
  { value: "(GMT+08:00) Ulaanbaatar", id: "Asia/Ulaanbaatar" },
  { value: "(GMT+08:00) Western Time - Perth", id: "Australia/Perth" },
  { value: "(GMT+09:00) Moscow+06 - Yakutsk", id: "Asia/Yakutsk" },
  { value: "(GMT+09:00) Seoul", id: "Asia/Seoul" },
  { value: "(GMT+09:00) Tokyo", id: "Asia/Tokyo" },
  { value: "(GMT+09:30) Central Time - Darwin", id: "Australia/Darwin" },
  { value: "(GMT+10:00) Eastern Time - Brisbane", id: "Australia/Brisbane" },
  { value: "(GMT+10:00) Guam", id: "Pacific/Guam" },
  { value: "(GMT+10:00) Moscow+07 - Magadan", id: "Asia/Magadan" },
  { value: "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk", id: "Asia/Vladivostok" },
  { value: "(GMT+10:00) Port Moresby", id: "Pacific/Port_Moresby" },
  { value: "(GMT+10:30) Central Time - Adelaide", id: "Australia/Adelaide" },
  { value: "(GMT+11:00) Eastern Time - Hobart", id: "Australia/Hobart" },
  { value: "(GMT+11:00) Eastern Time - Melbourne, Sydney", id: "Australia/Sydney" },
  { value: "(GMT+11:00) Guadalcanal", id: "Pacific/Guadalcanal" },
  { value: "(GMT+11:00) Noumea", id: "Pacific/Noumea" },
  { value: "(GMT+12:00) Majuro", id: "Pacific/Majuro" },
  { value: "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy", id: "Asia/Kamchatka" },
  { value: "(GMT+13:00) Auckland", id: "Pacific/Auckland" },
  { value: "(GMT+13:00) Fakaofo", id: "Pacific/Fakaofo" },
  { value: "(GMT+13:00) Fiji", id: "Pacific/Fiji" },
  { value: "(GMT+13:00) Tongatapu", id: "Pacific/Tongatapu" },
  { value: "(GMT+14:00) Apia", id: "Pacific/Apia" }
];

export default function TimeZoneSelect(props) {
  // eslint-disable-next-line no-unused-vars,react/prop-types
  const { name, label, value,error=null, onChange, options, style, className, fullWidth } = props;
  let valueResult = '';
  (value === null) ? valueResult = '' : valueResult = value;
  return (
      <FormControl className={className}
      {...(error && {error:true})}>
          <InputLabel>{label}</InputLabel>
          <MuiSelect
              label={label}
              name={name}
              fullWidth
              value={valueResult}
              style={style}
              onChange={onChange}>
              autoWidth={false}
              <MenuItem value=""></MenuItem>
              {
                timezoneList.map(
                  item => (<MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>)
                )
              }
          </MuiSelect>
          {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
  )
}

TimeZoneSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.object,
  fullWidth: PropTypes.bool
};

