import * as actionTypes from '../constants/ActionTypes';

export function setCurrentUser(currentUserPayload) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_CURRENT_USER, payload: currentUserPayload });
  };
}

export function setCurrentGroupId(currentGroupIdPayload) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_CURRENT_GROUP_ID, payload: currentGroupIdPayload });
  };
}

export function setCurrentGroupLogo(currentGroupLogoPayload) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_CURRENT_GROUP_LOGO, payload: currentGroupLogoPayload });
  };
}

export function setAvailableGroups(availableGroupsPayload) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_AVAILABLE_GROUPS, payload: availableGroupsPayload });
  };
}

export function setConciergeGroup(conciergeGroupPayload) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_CONCIERGE_GROUP, payload: conciergeGroupPayload });
  };
}

export function setCorporateAccount(corporateAccountPayload) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_CORPORATE_ACCOUNT, payload: corporateAccountPayload });
  };
}

export function setAvailableAffiliates(availableAffiliatesPayload) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_AVAILABLE_AFFILIATES, payload: availableAffiliatesPayload });
  };
}

export function setIsLoading(isLoadingPayload) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_IS_LOADING, payload: isLoadingPayload });
  };
}

export function setGlobalNotification(notificationPayload) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_GLOBAL_NOTIFICATION, payload: notificationPayload });
  };
}
