import React from 'react';
import {
  ADD_USER_BTN_CANCEL,
  ADMINISTRATOR_ROLE_LABEL,
  ADMINISTRATOR_ROLE_VALUE,
  CONCIERGE_ROLE_LABEL,
  CONCIERGE_ROLE_VALUE,
  DEFAULT_AUTO_DISMISS_DURATION,
  FIRST_NAME,
  FORM_ERROR_INVALID_EMAIL,
  FORM_ERROR_REQUIRED_FIELD,
  LAST_NAME,
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
  SAVE_USER_BTN,
  USER_EMAIL,
  USER_UPDATED_SUCCESSFULLY
} from "../../constants/Constants";
import "../../styles/edit-user-modal.scss";
import * as stringUtils from "underscore.string";
import {KaptynDataService} from "../../dataservices/KaptynDataService";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";

const emailValidator = require("email-validator");

class EditUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.user.id,
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      email: props.user.email,
      role: props.user.role,
      open: props.open,
      formHasErrors: false,
      formErrors: {},
      close: props.handleCloseEditUserModal
    };

    this._handleCloseEditUserModal = this._handleCloseEditUserModal.bind(this);
    this._handleOnChangeValue = this._handleOnChangeValue.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._handleEditUser = this._handleEditUser.bind(this);
    this._editUser = this._editUser.bind(this);
    this._resetForm = this._resetForm.bind(this);
  }

  _handleCloseEditUserModal() {
    this.state.close();
  }

  _validateForm() {
    let formHasErrors = false;
    let formErrors = {};

    if (stringUtils.isBlank(this.state.firstName)) {
      formHasErrors = true;
      formErrors.firstName = FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.lastName)) {
      formHasErrors = true;
      formErrors.lastName = FORM_ERROR_REQUIRED_FIELD;
    }
    if (stringUtils.isBlank(this.state.email)) {
      formHasErrors = true;
      formErrors.email = FORM_ERROR_REQUIRED_FIELD;
    }
    if (!emailValidator.validate(this.state.email)) {
      formHasErrors = true;
      formErrors.email = FORM_ERROR_INVALID_EMAIL;
    }

    if (stringUtils.isBlank(this.state.role)) {
      formHasErrors = true;
      formErrors.role = FORM_ERROR_REQUIRED_FIELD;
    }
    this.setState({ formHasErrors, formErrors }, this._handleEditUser);
  }

  _handleOnChangeValue(e) {
    const { name, value } = e.target;
    this.setState({ [name] : value });
  }


  _editUser(payload) {
    this.props.setIsLoading({ isLoading:true });
    const dataService = new KaptynDataService();
    dataService.updateCompanyUser(payload).then((response)=> {
      this.props.setIsLoading({ isLoading:false });
      const globalNotification = {
        open: true,
        type: NOTIFICATION_TYPE_SUCCESS,
        content: response.message && response.message === 'OK' ? USER_UPDATED_SUCCESSFULLY : '',
        autoDismissDuration: DEFAULT_AUTO_DISMISS_DURATION
      };
      this.props.setGlobalNotification({ globalNotification });
      this._handleCloseEditUserModal();
      this.props.refreshUsers();
    }).catch((err)=> {
      this.props.setIsLoading({ isLoading:false });
      const globalNotification = {
        open: true,
        type: NOTIFICATION_TYPE_ERROR,
        content: err.message,
        autoDismissDuration: DEFAULT_AUTO_DISMISS_DURATION
      };
      this.props.setGlobalNotification({ globalNotification });
    });
  }

  render() {
    return(
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="edit-user-modal-title"
          aria-describedby="alert-dialog-description"
          open={ this.state.open }
          onClose={ this._handleCloseEditUserModal }
          id="add-user-popup"
          maxWidth="xs"
        >
          <DialogTitle className="edit-user-modal-title" id="edit-user-modal-title">Edit User
            <IconButton aria-label="close" className="edit-user-modal-close-button" onClick={ this._handleCloseEditUserModal }>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="edit-user-modal-content">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField id="first-name"
                       name="firstName"
                       className="edit-user-modal-first-name"
                       label={ FIRST_NAME }
                       value={ this.state.firstName }
                       onChange={ this._handleOnChangeValue }
                       error={ !!this.state.formErrors.firstName }
                       helperText={ this.state.formErrors.firstName }
                       fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField id="last-name"
                       name="lastName"
                       className="edit-user-modal-last-name"
                       label={ LAST_NAME }
                       value={ this.state.lastName }
                       onChange={ this._handleOnChangeValue }
                       error={ !!this.state.formErrors.lastName }
                       helperText={ this.state.formErrors.lastName }
                       fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField id="email"
                       name="email"
                       className="edit-user-modal-email"
                       label={ USER_EMAIL }
                       value={ this.state.email }
                       onChange={ this._handleOnChangeValue }
                       error={ !!this.state.formErrors.email }
                       helperText={ this.state.formErrors.email }
                       fullWidth
                       disabled/>
              </Grid>
              <Grid item xs={12}>
                <FormControl className="edit-user-modal-role-form-control" disabled>
                  <InputLabel id="edit-user-modal-label">Role</InputLabel>
                  <Select
                    labelId="edit-user-modal-label"
                    id="role"
                    name="role"
                    value={ this.state.role }
                    className="edit-user-modal-role"
                    onChange={ this._handleOnChangeValue }
                    >
                    <MenuItem value={ ADMINISTRATOR_ROLE_VALUE }>{ ADMINISTRATOR_ROLE_LABEL }</MenuItem>
                    <MenuItem value={ CONCIERGE_ROLE_VALUE }>{ CONCIERGE_ROLE_LABEL }</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className="edit-user-modal-actions">
              <Button onClick={this._handleCloseEditUserModal} className="edit-user-modal-cancel-button">
                { ADD_USER_BTN_CANCEL }
              </Button>
              <Button onClick={ this._validateForm } color="primary" className="edit-user-modal-submit-button">
                { SAVE_USER_BTN }
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>);
  }

  _resetForm() {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role:'',
      open: false,
      formHasErrors: false,
      formErrors: {}
    })
  }

  _handleEditUser() {
    if (!this.state.formHasErrors) {
      this._editUser({
        id: this.state.id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        companyId: this.props.companyId
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props !== nextProps) {
      this.setState({open:nextProps.open});
    }
  }
}

EditUserForm.propTypes = {
  companyId: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setGlobalNotification: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseEditUserModal: PropTypes.func.isRequired,
  refreshUsers: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default EditUserForm;
