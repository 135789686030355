import React from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(2),
    maxWidth: "500px",
  },
  dialogTitle: {
    paddingRight: '0px'
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  eliminateBorderLine : {
    borderTop: 0,
    borderBottom: 0
  },
}));

export default function Popup(props) {
  const { title, children, openDialog, onCloseProfile } = props;
  const classes = useStyles();
  return (
    <Dialog open={openDialog} classes={{ paper: classes.dialogWrapper }}>
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: 'flex' }}>
          <Typography variant="h5" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton aria-label="Close" className={classes.closeButton} onClick={()=>{onCloseProfile()}}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.eliminateBorderLine}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

Popup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  onCloseProfile: PropTypes.func.isRequired
};
