import React from 'react';
import PropTypes from 'prop-types';
import { Map as GoogleMap, GoogleApiWrapper } from 'google-maps-react';
import '../../styles/map-component.scss';
import { ESTIMATED_DROPOFF, RIDE_TYPE_DESTINATION, RIDE_TYPE_HOURLY } from "../../constants/Constants";
import hourlyMarkerIcon from '../../icons/map/hourly-icon-small.png';
import startMarkerIcon from '../../icons/map/start-marker-small.png';
import endMarkerIcon from '../../icons/map/end-marker-small.png';

const containerStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
};

const INITIAL_ZOOM = 13;
const INITIAL_LOCATION = { lat: 36.1699412, lng: -115.1398296 };

class MapContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data : [
        {
          lat: this.props.rideDetails.fromCoordinates[0],
          lng: this.props.rideDetails.fromCoordinates[1]
        },
        {
          lat: this.props.rideDetails.toCoordinates[0],
          lng: this.props.rideDetails.toCoordinates[1]
        }
      ],
      map: null,
      markers: []
    };
    // eslint-disable-next-line no-undef
    this.directionsService = new google.maps.DirectionsService();
    // eslint-disable-next-line no-undef
    this.directionsDisplay = new google.maps.DirectionsRenderer();
    this._handleMapReady = this._handleMapReady.bind(this);
    this._calculateAndDisplayRoute = this._calculateAndDisplayRoute.bind(this);
    this._makeMarker = this._makeMarker.bind(this);
    this._clearMarkers = this._clearMarkers.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      if (this.props.rideDetails.rideType !== newProps.rideDetails.rideType || this.props.rideDetails.fromAddress !== newProps.rideDetails.fromAddress || this.props.rideDetails.toAddress !== newProps.rideDetails.toAddress) {
        this._clearMarkers();
        this.directionsDisplay.set('directions', null);
        let newState = {
          ...this.state
        };
        newState.data[0].lat = newProps.rideDetails.fromCoordinates[0];
        newState.data[0].lng = newProps.rideDetails.fromCoordinates[1];
        newState.data[1].lat = newProps.rideDetails.toCoordinates[0];
        newState.data[1].lng = newProps.rideDetails.toCoordinates[1];
        this.setState(newState, this._calculateAndDisplayRoute);
      }
    }
  }

  _handleMapReady(mapProps, map) {
    this.setState({ map }, this._calculateAndDisplayRoute);
  }

  _calculateAndDisplayRoute() {

    if (this.state.map) {

      this.directionsDisplay.setMap(this.state.map);

      const waypoints = this.state.data.map(item => {
        return {
          location: { lat: item.lat, lng: item.lng },
          stopover: false
        }
      });

      const origin = waypoints.shift().location;
      let destination = waypoints.pop().location;

      const displayRoute = this.props.rideDetails.rideType === RIDE_TYPE_DESTINATION && origin.lat && origin.lng && destination.lat && destination.lng;

      if (this.props.rideDetails.rideType === RIDE_TYPE_HOURLY) {
        destination = origin;
      }

      if (displayRoute) {
        this.directionsService.route({
          origin,
          destination,
          waypoints,
          travelMode: 'DRIVING'
        }, (response, status) => {
          if (status === 'OK') {
            const startAddress = response.routes[0].legs[0].start_address;
            const endAddress = response.routes[0].legs[0].end_address;

            const startAddressString = this._getStartAddressForInfoWindow(startAddress);
            const endAddressString = this._getEndAddressForInfoWindow(endAddress, response.routes[0].legs[0].duration.text);

            const leg = response.routes[0].legs[0];
            if (this.props.rideDetails.rideType === RIDE_TYPE_DESTINATION) {
              response.routes[0].legs[0].start_address = startAddressString;
              response.routes[0].legs[0].end_address = endAddressString;
              this._makeMarker(leg.start_location, startMarkerIcon, startAddressString, false);
              this._makeMarker(leg.end_location, endMarkerIcon,  endAddressString, false);
            }
            else if (this.props.rideDetails.rideType === RIDE_TYPE_HOURLY) {
              response.routes[0].legs[0].start_address = startAddressString;
              this._makeMarker(leg.start_location, hourlyMarkerIcon, startAddressString, true);
            }

            this.directionsDisplay.setOptions({
              draggable: false,
              suppressInfoWindows: false,
              suppressMarkers: true,
              polylineOptions: {
                strokeWeight: 4,
                strokeOpacity: 2,
                strokeColor:  'black',
                geodesic: true
              }
            });

            this.directionsDisplay.setDirections(response);
          } else {
            this.props.onError('An error occurred calculating the route. Please try again.');
          }
        });
      }  else {
        if (this.props.rideDetails.rideType === RIDE_TYPE_DESTINATION) {
          if (this.state.data[0].lat && this.state.data[0].lng && this.props.rideDetails.fromAddress !== '') {
            this._makeMarker(this.state.data[0], startMarkerIcon, this._getStartAddressForInfoWindow(this.props.rideDetails.fromAddress), true);
          }
          if (this.state.data[1].lat && this.state.data[1].lng && this.props.rideDetails.toAddress !== '') {
            this._makeMarker(this.state.data[1], endMarkerIcon, this._getStartAddressForInfoWindow(this.props.rideDetails.toAddress), true);
          }
        }
        else if (this.props.rideDetails.rideType === RIDE_TYPE_HOURLY) {
          this._makeMarker(this.state.data[0], hourlyMarkerIcon, this._getStartAddressForInfoWindow(this.props.rideDetails.fromAddress), true);
        }
      }
    }
  }

  _clearMarkers() {
    for (let marker of this.state.markers) {
      marker.setMap(null);
    }
    this.setState({ markers: [] });
  }

  _makeMarker(position, icon, address, shouldCenter) {
    // eslint-disable-next-line no-undef
    const marker = new google.maps.Marker({
      position,
      map: this.state.map,
      icon,
    });

    // eslint-disable-next-line no-undef
    const infoWindow = new google.maps.InfoWindow({
      content: address,
      maxWidth: 600,
      maxHeight: 600
    });
    infoWindow.open(this.state.map, marker);

    if (shouldCenter){
      this.state.map.setCenter(position);
      this.state.map.setZoom(INITIAL_ZOOM);
    }

    const markers = this.state.markers;
    markers.push(marker);
    this.setState({ markers });
  }

  _getStartAddressForInfoWindow(address) {
    return `<div id="content"><p class="place-name">${ address }</p></div>`;
  }

  _getEndAddressForInfoWindow(address, duration) {
    return `<div id="content"><p class="estimated-pickup">${ ESTIMATED_DROPOFF }: ${ duration }</p><p class="place-name">${ address }</p></div>`;
  }

  render() {
    return (
      <GoogleMap google={window.google || this.props.google}
                 zoom={ INITIAL_ZOOM }
                 initialCenter={ INITIAL_LOCATION }
                 onReady={ this._handleMapReady }
                 containerStyle={ containerStyle } />
    );
  }
}

MapContainer.propTypes = {
  google: PropTypes.object,
  rideDetails: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired
};

export default process.env.TEST ? GoogleApiWrapper({
  apiKey: `${ process.env.GOOGLE_MAPS_API_KEY }`
})(MapContainer) : MapContainer;
