import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import * as constants from "../../constants/Constants";

class Notification extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      type: props.type,
      content: props.content,
      autoDismissDuration: props.autoDismissDuration,
      transition: this.TransitionRight
    };
    this._handleAutoDismiss = this._handleAutoDismiss.bind(this);
    this._handleClose = this._handleClose.bind(this);
  }

  componentDidMount() {
    this._handleAutoDismiss();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        open: nextProps.open,
        type: nextProps.type,
        content: nextProps.content,
        autoDismissDuration: nextProps.autoDismissDuration
      }, this._handleAutoDismiss);
    }
  }

  _handleAutoDismiss() {
    if (this.state.autoDismissDuration) {
      setTimeout(this._handleClose, this.state.autoDismissDuration);
    }
  }

  _handleClose() {
    this.setState({
      open: false
    }, () => {
      const globalNotification = {
        open: false,
        type: constants.NOTIFICATION_TYPE_INFO,
        content: '',
        autoDismissDuration: null
      };
      this.props.setGlobalNotification({ globalNotification });
    });
  }

  TransitionRight(props) {
    return <Slide {...props} direction="right" />;
  }

  render() {
    return (
      <Snackbar open={ this.state.open }
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                TransitionComponent={ this.state.transition }>
        <Alert onClose={ this._handleClose } severity={ this.state.type }>
          <AlertTitle>{ this.state.type[0].toUpperCase() + this.state.type.slice(1) }</AlertTitle>
          { this.state.content }
        </Alert>
      </Snackbar>
    );
  }
}

Notification.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  autoDismissDuration: PropTypes.number,
  setGlobalNotification: PropTypes.func.isRequired
};

export default Notification;
