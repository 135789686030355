export default {
  API: {
    AFFILIATE: {
      BASE: '/v1.1/groups/affiliates',
      SERVICE_LEVELS: '/v1.1/groups/affiliates/{affiliateId}/services/{serviceLevelId}',
    },
    CORPORATE: {
      CONCIERGE: '/v1.1/corporate/{accountId}',
      GROUP_CONCIERGE: '/v1.1/groups/{groupId}/concierge',
      SERVICE_LEVELS: '/v1.1/services/corporate/{accountId}/{groupId}',
    },
    VEHICLE_AVAILABILITY: '/v1.1/services/{serviceLevelId}/status',
    SERVICE_LEVEL_AVAILABILITY:  '/v1.1/services/{serviceLevelId}/getAvailability',
    FORGOT_PASSWORD: '/v1.1/dispatch/forgot',
    GROUPS: {
      DETAILS: '/v1.1/company/details',
      BASE: '/v1.1/groups/{groupId}',
      LOGO: '/v1.1/groups/{groupId}/logo',
      USERS: '/v1.1/groups/{groupId}/users/staff',
      WHITELIST: '/v1.1/groups/{groupId}/users/whitelist'
    },
    MARKETS: {
      BASE: '/v1.1/markets',
      FOR_LOCATION: '/v1.1/markets/for_location',
    },
    LOGIN: '/v1.1/login',
    LOGOUT: '/v1.1/logout',
    PROFILE: '/v1.1/user/profile',
    REFRESH_SESSION: '/v1.1/session/refresh',
    REPORTING: '/v1.1/reporting',
    RIDES: {
      FARM_OUT: '/v1.1/ride/{rideId}/farmOut',
      IN_HOUSE: '/v1.1/ride/{rideId}/inHouse',
      LIST: '/v1.1/rides/list',
      LISTVIEW: '/v1.1/rides/listview',
      SINGLE: '/v1.1/ride/{rideId}',
      CREATE: '/v1.1/ride/create',
      UPDATE: '/v1.1/ride/{rideId}/dispatch',
      RECEIPT: '/v1.1/billing/rides/{rideId}/receipt',
      DOWNLOAD_RECEIPT: '/v1.1/billing/rides/{rideId}/receipt/download',
      CANCEL: '/v1.1/ride/{rideId}/cancel',
      REPORT: '/v1.1/reports/rides/csv', // Currently deprecated, as the response does not return the blobId
      QUOTE: '/V1.1/ride/quote',
    },
    SIGNUP: '/v1.1/signup',
    USER: {
      BASE: '/v1.1/user/{userId}',
      PROFILE: '/v1.1/user/{userId}/profile',
      ACCOUNTS: '/v1.1/user/{userId}/accounts',
      CREATE_GUEST: '/v1.1/user',
      UPDATE_GUEST: '/v1.1/groups/{groupId}/users/{userId}',
      UPDATE: '/v1.1/groups/{groupId}/users/staff/{userId}',
      CREDIT_CARDS: '/v1.1/billing/user/{userId}/cards/{cardId}',
      PRIMARY_CARD: '/v1.1/billing/user/{userId}/cards/{cardId}/primary',
      CHANGE_PASSWORD: '/v1.1/user/password/change',
      GROUPS: '/v1.1/user/groups'
    },
    FLIGHT: {
      STATUS: '/v1.1/flight-stats/flight',
      AIRLINES: '/v1.1/flight-stats/airlines'
    }
  },
};
