import React from 'react';
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from "@material-ui/core/styles";
import {PROFILE_MENU, MENU_LOGOUT} from '../../constants/Constants';
import { ExpandLess, ExpandMore, PersonOutline } from '@material-ui/icons';
import { destroyCookies } from "../../utils/cookieUtil";
import ProfileForm from "../forms/ProfileForm";
import Popup from "../modals/Popup";

const styles = () => ({
  root: {
    display: 'flex'
  },
  paper: {
    boxShadow: 'none',
    minWidth: 200,
    color: '#676770'
  },
  menuButton: {
    minWidth: 200,
    textTransform: 'none',
    padding: '15px',
    fontSize: '1rem',
    borderLeft: '1px solid #F3F3F3',
    borderRadius: 0,
    color: '#676770'
  },
});

class ProfileMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      iconExpand: <ExpandMore color="action"/>,
      iconStart: <PersonOutline color="action"/>,
      recordForEdit: null,
      setRecordForEdit: null,
      openDialog: false,
      setRecords: [],
      userFullName: this.props.userFullName
    };
    this._handleProfileClose = this._handleProfileClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.userFullName !== nextProps.userFullName) {
      this.setState({ userFullName: nextProps.userFullName });
    }
  }

  _handleToggle = () => {
    const newOpenState = !this.state.open;
    const newIconExpand = newOpenState ? <ExpandLess color="action"/> : <ExpandMore color="action"/>;
    this.setState({ open: newOpenState, iconExpand: newIconExpand });
  };

  _handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      destroyCookies(true);
    }
    this.setState({ open: false, iconExpand: <ExpandMore color="action"/> });
  };

  _handleProfileClose = () => {
    this.setState({ openDialog: false});
  };
  _handleOpenProfile = () => {
    this.setState({ openDialog: true});
    this.props.onOpenProfile(this.props.userId);
  };

  _handleLogout = () => {
    this.props.onLogout(this.props.userId);
  };

  render() {
    const { classes } = this.props;
    const { open, iconExpand, iconStart } = this.state;

    return (
      <div className={classes.root}>
        <Popup
          title="Account Settings"
          openDialog={this.state.openDialog}
          onCloseProfile={ this._handleProfileClose }
        >
          <ProfileForm
            userId={this.props.userId}
            onCloseProfile={ this._handleProfileClose }
            setIsLoading={ this.props.setIsLoading }
            setCurrentUser={ this.props.setCurrentUser }
            setGlobalNotification={ this.props.setGlobalNotification }
            onLogout={ this.props.onLogout }
            history={ this.props.history }/>
        </Popup>
        <div>
          <Button buttonRef={node => {
            this.anchorEl = node;
          }} aria-owns={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={this._handleToggle} endIcon={ iconExpand } startIcon={ iconStart } className={ classes.menuButton}>
            { this.state.userFullName }
          </Button>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
            {({ TransitionProps, placement }) => <Grow {...TransitionProps} id="menu-list-grow" style={{ transformOrigin: placement === 'bottom' ? 'bottom-start' : 'top-start'}}>
              <Paper className={ classes.paper }>
                <ClickAwayListener onClickAway={this._handleClose}>
                  <MenuList>
                    <MenuItem onClick={ this._handleOpenProfile }>{PROFILE_MENU}</MenuItem>
                    <MenuItem onClick={ this._handleLogout }>{MENU_LOGOUT}</MenuItem>
                    {/*<MenuItem onClick={this.handleProfileClick}>{PROFILE_MENU}</MenuItem>*/}
                    {/*<MenuItem onClick={this.handleClose}>{MENU_LOGOUT}</MenuItem>*/}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>}
          </Popper>
        </div>
      </div>
    );
  }
}

ProfileMenu.propTypes = {
  classes: PropTypes.object,
  userId: PropTypes.string.isRequired,
  userFullName: PropTypes.string.isRequired,
  onOpenProfile: PropTypes.func,
  onLogout: PropTypes.func,
  setCurrentUser: PropTypes.func,
  setIsLoading: PropTypes.func.isRequired,
  setGlobalNotification: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default withStyles(styles)(ProfileMenu);
